const qs = require("qs");

class Forms {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("forms", params);
  }

  get(formId) {
    return this.client.get(`forms/${formId}`);
  }

  create(form) {
    delete form.id;
    return this.client.post("forms/new", form);
  }

  edit(formId, newForm) {
    this.client.patch(`forms/${formId}/edit`, newForm);
  }

  editOrCreate(formId, newForm) {
    this.client.put(`forms/${formId}/edit`, newForm);
  }

  delete(formId) {
    this.client.delete(`forms/${formId}/delete`);
  }

  deleteFormFields(formId, params = {}) {
    return this.client.delete(
      `forms/${formId}/fields/delete?${qs.stringify(params)}`
    );
  }

  deleteFormActions(formId, params = {}) {
    return this.client.delete(
      `forms/${formId}/actions/delete?${qs.stringify(params)}`
    );
  }

  getFormSubmissions(formId) {
    return this.client.get(`forms/${formId}/submissions`);
  }

  getFormSubmissionForContact(formId, contactId) {
    return this.client.get(`forms/${formId}/submissions/contact/${contactId}`);
  }

  getFormSubmission(formId, submissionId) {
    return this.client.get(`forms/${formId}/submissions/${submissionId}`);
  }
}

export default Forms;
