import React from "react";
import connector from "../../../connector";
import { connect } from "react-redux";
import { retrieveStandardType } from "../../../actions/typesActions";
import AsyncSelect from "react-select/async";
import AddModal from "../../../reusableComponents/Modals/AddModal";
import { useNavigate } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Moment from "moment";
import Select from "react-select";
import { FaTimes } from "react-icons/fa";
import { type } from "../../../data";
import { retrieveUsers } from "../../../actions/usersAction";

import Dropzone from "react-dropzone";
import { isMobile } from "react-device-detect";

class AddActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daysToCheck: 21 * 24 * 60 * 60 * 1000,
      modifiedData: {
        note: null,
        activity: null,
        location: null,
        reference: null,
        type: null,
        nextActivity: null,
        owner: null,
        leads: [],
        nextUser: null,
        dueDateActivity: null,
        meetingTime: null,
        documents: [],
      },
      proplead: null,
      loading: false,
      errors: false,
      contacts: [],
      tempData: [],
      noRequired: false,
      usersList: []
    };
  }

  handleNextActivity = (value) => {
    if (value === null) {
      this.state.modifiedData.nextActivity = null
    } else {
      this.state.modifiedData.nextActivity = value.id
    }
  };

  handleActivity = (value) => {
    if (value === null) {
      this.state.modifiedData.activity = null;
    } else {
      this.state.modifiedData.activity = value.id
    }
  };

  handleTypeChange = (value) => {
    if (value === null) {
      this.state.modifiedData.type = null
    } else {
      this.state.modifiedData.type = value.value;
    }
  };

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prev) => ({
      ...prev,
      modifiedData: {
        ...prev.modifiedData,
        [name]: value,
      },
    }));
  };

  handleAssignedTo = (e) => {

    if (e === null) {
      this.state.modifiedData.nextUser = null;
    } else {
      this.state.modifiedData.nextUser = e;
    }

    this.setState({ modifiedData: this.state.modifiedData });
  };

  handleOnDrop = async (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      alert(rejectedFiles[0].errors.map((x) => x.code));
    }

    if (files && files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("documents[]", files[i]);
      }
      await connector.activities
        .upload(formData)
        .then((response) => {
          this.state.modifiedData.documents =
            this.state.modifiedData.documents.concat(response.data);
          this.setState({ modifiedData: this.state.modifiedData });
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
  };

  removeDoc = (doc) => {
    this.state.modifiedData.documents.splice(doc);
    this.setState({ modifiedData: this.state.modifiedData });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });

    if (
      this.state.modifiedData.reference === null ||
      this.state.modifiedData.reference === "" ||
      this.state.modifiedData.activity === null ||
      this.state.modifiedData.activity === ""
    ) {
      this.setState({
        loading: false,
        errors: true,
        noRequired: true,
      });
    } else if (
      this.state.modifiedData.dueDateActivity <
      this.state.modifiedData.meetingTime ||
      this.state.modifiedData.meetingTime < Moment(
        new Date(Date.now() - this.state.daysToCheck)
      ).format("YYYY-MM-DD HH:mm")
    ) {
      this.setState({ loading: false, errors: true });
    } else {
      if (this.state.modifiedData.nextUser) {
        this.state.modifiedData.nextUser = this.state.modifiedData.nextUser.id
      }
      this.state.modifiedData.leads = this.state.modifiedData.leads.map((x) => x.id)

      let response = await connector.activities.create(this.state.modifiedData);

      if (response.status === 201 || response.status === 200) {
        this.setState({
          loading: false,
          errors: false,
          tempData: response.data,
        });
      } else {
        this.setState({ loading: false, errors: true });
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  objectToFormData = (obj, rootName, ignoreList) => {
    var formData = new FormData();
    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || "";
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + "[" + i + "]");
          }
        } else if (typeof data === "object" && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === "") {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + "." + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== "undefined") {
            formData.append(root, data);
          }
        }
      }
    }

    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    }

    appendFormData(obj, rootName);
    return formData;
  };

  handleSelectChange = (value) => {
    if (value === null) {
      this.state.modifiedData.leads = [];
    } else {
      this.state.modifiedData.leads = value;
    }

    this.setState({ modifiedData: this.state.modifiedData });
  };

  componentDidMount = async () => {
    const user = JSON.parse(localStorage.getItem("user-info"));
    this.props.retrieveStandardType();
    // this.props.retrieveUsers();
    this.setState({ usersList: JSON.parse(localStorage.getItem("usersList")) })

    let response = await connector.contacts.list(
      encodeURI(`&where[0][col]=date_identified&where[0][expr]=isNotNull`)
    );
    this.setState({
      contacts: Object.entries(response.data.contacts).map((x) => x[1]),
    });
    if (this.props.addActivity && this.props.addActivityFromContacts) {
      this.state.modifiedData.leads = this.props.addActivity.id;
      this.state.proplead = this.props.addActivity;
      this.setState({ modifiedData: this.state.modifiedData });
    }
    this.state.modifiedData.owner = user.id;
    this.state.modifiedData.meetingTime = Moment().format(
      "YYYY-MM-DD HH:mm:00"
    );
  };

  redirect = () => {
    const navigate = this.props.navigate;
    navigate("/aktivitaten");
  };

  loadOptions = async (inputValue) => {
    return await connector.contacts
      .list(
        encodeURI(
          `&where[0][col]=date_identified&where[0][expr]=isNotNull&where[1][col]=email&where[1][expr]=isNotNull&search=${inputValue}`
        )
      )
      .then((res) => Object.entries(res.data.contacts).map((x) => x[1]));
  };

  render() {
    const { types, users } = this.props;
    const { usersList } = this.state
    const filteredUsers = usersList.map((x) => x[1]);
    const { error, modifiedData } = this.state;
    const loadtypes = types.map((x) => x[1]);

    let inputProps = {
      placeholder: Moment().format("YYYY-MM-DD HH:mm"),
    };

    if (error) {
      return <div>An error occured: {error.message}</div>;
    }

    return (
      <div className="main-section" style={{
        left: !isMobile ? "15rem" : "0",
        width: !isMobile ? "calc(100vw - 12rem)" : "100%",
        padding: !isMobile ? "0 60px 50px 60px" : "0",
      }}>
        <div className="w-[100%] pt-[20px]">
          <div className="flex w-100 justify-end relative mobile-tablet-none">
            <AddModal
              loading={this.state.loading}
              submit={this.handleSubmit}
              redirect={this.redirect}
              errors={this.state.errors}
            />
          </div>
          <div className="flex justify-start items-center">
            <div
              className="w-full flex flex-col"
            >
              <div
                className={`p-[20px] lg:p-[0 30px 30px 30px] w-full lg:w-full block mx-auto relative bg-transparent rounded-md`}
              >
                <div className="flex flex-wrap justify-between items-center pb-[20px]">
                  <div
                    className={`flex flex-wrap items-center w-full justify-between lg:justify-between border-b-[1px] border-[#004f9f] pb-2`}
                  >
                    <div
                      className={`flex lg:flex-nowrap gap-5  flex-1 justify-between w-full lg:gap-10`}

                    >
                      <div
                        className={`flex flex-wrap lg:flex-nowrap flex-row justify-center lg:justify-between gap-7 w-full lg:w-[60%]`}
                      >
                        <div
                          className={`flex flex-col w-[46%] lg:w-full lg:w-[85%]`}
                        >
                          <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[3px]">
                            Zeit
                          </h2>
                          <Datetime
                            inputProps={inputProps}
                            input={true}
                            selected={new Date()}
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm"
                            onChange={(e) => {
                              (modifiedData.meetingTime = Moment(e._d).format(
                                "YYYY-MM-DD HH:mm"
                              )); this.setState({ modifiedData: this.state.modifiedData })
                            }
                            }
                            className="placeholder:text-gray-400 placeholder:text-[10px] text-[13px] lg:placeholder:text-[13px] border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:outline-none block w-full px-[8px] py-1.5 lg:py-[8px] input-with-image"
                          />
                          {this.state.modifiedData.meetingTime <
                            Moment(
                              new Date(Date.now() - this.state.daysToCheck)
                            ).format("YYYY-MM-DD HH:mm") ? (
                            <p className="text-[#aa1124] text-[12px] max-w-[250px] break-all">
                              Sie können Aktivitäten nur bis zu drei Wochen in der
                              Vergangenheit speichern
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={`flex flex-col w-[46%] lg:w-[85%]`}
                        >
                          <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[3px]">
                            Betreff
                          </h2>
                          <input
                            type="text"
                            name="reference"
                            onChange={this.handleInputChange}
                            className={`placeholder:text-gray-400 placeholder:text-[13px] border boder-gray-400 lg:border-0 shadow bg-transparent ${this.state.noRequired
                              ? "border-2 border-red-400"
                              : "border-0"
                              }  text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[4px] lg:py-[8px] searchinput`}
                            placeholder="Betreff"
                          />
                        </div>
                        <div
                          className={`flex flex-col w-full lg:w-full`}
                        >
                          <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                            Aktivität
                          </h2>
                          <Select
                            classNamePrefix="transparent-bg"
                            isClearable
                            isSearchable={true}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            name="activity"
                            options={loadtypes}
                            onChange={this.handleActivity}
                            className={`w-full text-[12px] lg-text[14px] ${this.state.noRequired
                              ? "border-2 border-red-400"
                              : "border-0"
                              }
                           }`}
                            placeholder="Auswählen..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-between items-start pb-[20px]">
                  <div className="flex flex-wrap items-center w-full lg:w-[47%] justify-between lg:justify-between">
                    <div className="flex flex-wrap lg:flex-wrap justify-between flex-1 gap-5 lg:gap-[50px] w-[100%]">
                      <div className="w-full lg:w-full flex items-center justify-between flex-auto lg:flex-auto gap-5 lg:gap-[50px]">
                        <div className="grid gap-[35px] flex-1 lg:w-[25%]">
                          <div
                            className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start w-full`}
                          >
                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                              Ort
                            </h2>
                            <input
                              type="text"
                              name="location"
                              onChange={this.handleInputChange}
                              className={`placeholder:text-gray-400 placeholder:text-[13px] border boder-gray-400 lg:border-0 shadow bg-transparent ${this.state.noRequired
                                ? "border-2 border-red-400"
                                : "border-0"
                                }  text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[8px] searchinput`}
                              placeholder="Ort"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-[35px] lg:pl-0 flex-1 lg:w-[30%] relative">
                          <div
                            className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start w-full`}
                          >
                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                              Art
                            </h2>
                            <Select
                              isClearable
                              classNamePrefix="transparent-bg"
                              className="w-full text-[12px] lg-text[14px]"
                              isSearchable={true}
                              getOptionLabel={(e) => `${e.label}`}
                              getOptionValue={(e) => e.value}
                              name="type"
                              options={type}
                              onChange={this.handleTypeChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-full flex-wrap flex items-center justify-between flex-auto lg:flex-auto gap-5 lg:gap-[50px]">
                        <div className="flex flex-col gap-[35px] lg:pl-0 flex-1 lg:w-[30%] relative">
                          <div
                            className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start w-full`}
                          >
                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                              Folgeaktivität
                            </h2>
                            <Select
                              isClearable
                              classNamePrefix="transparent-bg"
                              isSearchable={true}
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.id}
                              name="nextActivity"
                              options={loadtypes}
                              onChange={this.handleNextActivity}
                              className="w-full text-[12px] lg-text[14px]"
                              placeholder="Auswählen..."
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-[35px] lg:pl-0 flex-1 lg:w-[30%] relative">
                          <div
                            className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start w-full`}
                          >
                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                              Fälligkeit Folgeaktivität
                            </h2>
                            <Datetime
                              inputProps={inputProps}
                              input={true}
                              dateFormat="YYYY-MM-DD"
                              timeFormat="HH:mm"
                              selected={modifiedData.dueDateActivity}
                              onChange={(e) => {
                                (modifiedData.dueDateActivity = Moment(e._d).format(
                                  "YYYY-MM-DD HH:mm"
                                ));
                                this.setState({
                                  modifiedData: this.state.modifiedData,
                                })
                              }}
                              className="placeholder:text-gray-400 placeholder:text-[10px] text-[13px] lg:placeholder:text-[13px] border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:outline-none block w-full px-[8px] py-[8px] input-with-image"
                            />
                            {this.state.modifiedData.dueDateActivity <
                              this.state.modifiedData.meetingTime ? (
                              <p className="text-[#aa1124] text-[12px] max-w-[250px] break-all">
                                Sie können nicht einstellen Folgeaktivitäts-Datum vor
                                dem Aktivitätsdatum
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="w-full flex items-center gap-5 lg:gap-[50px]">
                          <div className="flex flex-col gap-[35px] lg:pl-0 flex-auto lg:w-full relative">
                            <div
                              className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start w-full`}
                            >
                              <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                                Assigned To
                              </h2>
                              <Select
                                isSearchable={true}
                                isClearable
                                classNamePrefix="transparent-bg"
                                getOptionLabel={(e) => `${e.label ? e.label : ""} ${e.firstName ? e.firstName : ""
                                  } ${e.lastName ? e.lastName : ""} (${e.email ? e.email : ""
                                  })`
                                }
                                getOptionValue={(e) => e.id}
                                name="activity"
                                options={filteredUsers}
                                onChange={this.handleAssignedTo}
                                defaultValue={modifiedData.nextUser}
                                className={`w-full border-0 text-[12px] lg-text[14px]`}
                                placeholder="Auswählen..."
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-[35px] lg:pl-0 flex-auto lg:w-full relative">
                            <div
                              className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start w-full`}
                            >
                              <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                                Kontakt
                              </h2>
                              <AsyncSelect
                                className="text-[12px] lg-text[14px]"
                                isClearable
                                classNamePrefix="transparent-bg"
                                isMulti
                                isSearchable={true}
                                getOptionLabel={(e) =>
                                  `${e.label ? e.label : ""} ${e.fields && e.fields.all.firstname
                                    ? e.fields.all.firstname
                                    : ""
                                  } ${e.fields && e.fields.all.lastname
                                    ? e.fields.all.lastname
                                    : ""
                                  }${e.fields && e.fields.all.email
                                    ? " (" + e.fields.all.email + ")"
                                    : ""
                                  }`
                                }
                                getOptionValue={(e) => e.id}
                                name="nextActivity"
                                noOptionsMessage={(e) => "No contacts found, keep searching..."}
                                loadOptions={this.loadOptions}
                                onChange={this.handleSelectChange}
                                placeholder="Auswählen..."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex flex-col flex-wrap gap-[35px] lg:gap-[50px] w-full lg:w-[47%] mt-[30px] lg:mt-0`}
                  >
                    <div
                      className={`flex flex-col lg:gap-[10px] pt-5 flex-1 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                        Bemerkung
                      </h2>
                      <textarea
                        rows="8"
                        cols="60"
                        name="note"
                        onChange={this.handleInputChange}
                        className=" placeholder:text-gray-400 placeholder:text-[13px] border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-[8px] searchinput"
                        placeholder="Bemerkung"
                      />
                    </div>

                    <div className="flex justify-start gap-[50px] w-full ">
                      <div className="w-full">
                        <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                          Dokumente
                        </h2>
                        <Dropzone
                          maxSize={10000000}
                          multiple={true}
                          onDrop={this.handleOnDrop}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="flex flex-col">
                              <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <p className="text-[14px]">
                                  Ziehen Sie Dateien hierher oder klicken Sie zum
                                  Auswählen
                                </p>
                                <p className="text-[12px] mt-[10px]">max 10MB</p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {modifiedData.documents.length > 0 ? (
                          <>
                            {modifiedData.documents.map((x, i) => (
                              <div className="pb-[10px]">
                                <p className="leading-6 flex items-center gap-[10px] max-w-[299px]">
                                  {x.name}
                                  <button onClick={() => this.removeDoc(i)}>
                                    <FaTimes size={14} />
                                  </button>
                                </p>
                              </div>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="flex w-100 justify-center relative desktop-none">
                      <AddModal
                        loading={this.state.loading}
                        submit={this.handleSubmit}
                        redirect={this.redirect}
                        errors={this.state.errors}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.types,
    users: state.users,
  };
};

export default connect(mapStateToProps, {
  retrieveStandardType,
  retrieveUsers,
})((props) => <AddActivity {...props} navigate={useNavigate()} />);

