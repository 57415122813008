export const type = [
  { value: "Persönlich", label: "Persönlich" },
  { value: "Telefonisch", label: "Telefonisch" },
  { value: "E-Mail", label: "E-Mail" },
  { value: "Brief", label: "Brief" },
];

export const kategorie = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
];

export const position = [
  { value: "Einkauf", label: "Einkauf" },
  { value: "Wassermeister", label: "Wassermeister" },
  { value: "Sensorik", label: "Sensorik" },
  { value: "Planer", label: "Planer" },
  { value: "Verkäufer", label: "Verkäufer" },
  { value: "Geschäftsführer", label: "Geschäftsführer" },
  { value: "Techniker", label: "Techniker" },
  { value: "Consultant", label: "Consultant" },
  { value: "Projektleiter", label: "Projektleiter" },
  { value: "Bauleiter", label: "Bauleiter" },
  { value: "Ingenieur", label: "Ingenieur" },
  { value: "Datenmanager / IT", label: "Datenmanager / IT" },
  { value: "Qualitätsmanager", label: "Qualitätsmanager" },
  { value: "Leckorter", label: "Leckorter" },
  { value: "Mitarbeiter", label: "Mitarbeiter" },
];

export const status = [
  { value: "Inaktiv", label: "Inaktiv" },
  { value: "Interessent", label: "Interessent" },
  { value: "Kunde", label: "Kunde" },
];

export const doi = [
  { value: 1, label: "Bestätigt" },
  { value: 0, label: "Nicht bestätigt" },
];

export const options = [
  { value: null, label: "Löschen..." },
  { value: "Dr.", label: "Dr." },
  { value: "Prof", label: "Prof" },
  { value: "Prof. Dr.", label: "Prof. Dr." },
  { value: "Firma", label: "Firma" },
  { value: "Direktor", label: "Direktor" },
  { value: "Ing.", label: "Ing." },
  { value: "Magister", label: "Magister" },
];

export const countries = [
  { label: "Afghanistan", value: "AF" },
  { label: "land Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "AndorrA", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: 'Cote D"Ivoire', value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: 'Korea, Democratic People"S Republic of', value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: 'Lao People"S Democratic Republic', value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "RWANDA", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Surilabel", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

export const countriesLocale = [
  { value: "af", label: "Afrikaans" },
  { value: "af_NA", label: "Afrikaans (Namibia)" },
  { value: "af_ZA", label: "Afrikaans (S\u00fcdafrika)" },
  { value: "ak", label: "Akan" },
  { value: "ak_GH", label: "Akan (Ghana)" },
  { value: "am", label: "Amharisch" },
  { value: "am_ET", label: "Amharisch (\u00c4thiopien)" },
  { value: "ar", label: "Arabisch" },
  { value: "ar_AE", label: "Arabisch (Vereinigte Arabische Emirate)" },
  { value: "ar_BH", label: "Arabisch (Bahrain)" },
  { value: "ar_DJ", label: "Arabisch (Dschibuti)" },
  { value: "ar_DZ", label: "Arabisch (Algerien)" },
  { value: "ar_EG", label: "Arabisch (\u00c4gypten)" },
  { value: "ar_EH", label: "Arabisch (Westsahara)" },
  { value: "ar_ER", label: "Arabisch (Eritrea)" },
  { value: "ar_IL", label: "Arabisch (Israel)" },
  { value: "ar_IQ", label: "Arabisch (Irak)" },
  { value: "ar_JO", label: "Arabisch (Jordanien)" },
  { value: "ar_KM", label: "Arabisch (Komoren)" },
  { value: "ar_KW", label: "Arabisch (Kuwait)" },
  { value: "ar_LB", label: "Arabisch (Libanon)" },
  { value: "ar_LY", label: "Arabisch (Libyen)" },
  { value: "ar_MA", label: "Arabisch (Marokko)" },
  { value: "ar_MR", label: "Arabisch (Mauretanien)" },
  { value: "ar_OM", label: "Arabisch (Oman)" },
  {
    value: "ar_PS",
    label: "Arabisch (Pal\u00e4stinensische Autonomiegebiete)",
  },
  { value: "ar_QA", label: "Arabisch (Katar)" },
  { value: "ar_SA", label: "Arabisch (Saudi-Arabien)" },
  { value: "ar_SD", label: "Arabisch (Sudan)" },
  { value: "ar_SO", label: "Arabisch (Somalia)" },
  { value: "ar_SS", label: "Arabisch (S\u00fcdsudan)" },
  { value: "ar_SY", label: "Arabisch (Syrien)" },
  { value: "ar_TD", label: "Arabisch (Tschad)" },
  { value: "ar_TN", label: "Arabisch (Tunesien)" },
  { value: "ar_YE", label: "Arabisch (Jemen)" },
  { value: "as", label: "Assamesisch" },
  { value: "as_IN", label: "Assamesisch (Indien)" },
  { value: "az", label: "Aserbaidschanisch" },
  { value: "az_AZ", label: "Aserbaidschanisch (Aserbaidschan)" },
  { value: "az_Cyrl", label: "Aserbaidschanisch (Kyrillisch)" },
  {
    value: "az_Cyrl_AZ",
    label: "Aserbaidschanisch (Kyrillisch, Aserbaidschan)",
  },
  { value: "az_Latn", label: "Aserbaidschanisch (Lateinisch)" },
  {
    value: "az_Latn_AZ",
    label: "Aserbaidschanisch (Lateinisch, Aserbaidschan)",
  },
  { value: "be", label: "Wei\u00dfrussisch" },
  { value: "be_BY", label: "Wei\u00dfrussisch (Belarus)" },
  { value: "bg", label: "Bulgarisch" },
  { value: "bg_BG", label: "Bulgarisch (Bulgarien)" },
  { value: "bm", label: "Bambara" },
  { value: "bm_ML", label: "Bambara (Mali)" },
  { value: "bn", label: "Bengalisch" },
  { value: "bn_BD", label: "Bengalisch (Bangladesch)" },
  { value: "bn_IN", label: "Bengalisch (Indien)" },
  { value: "bo", label: "Tibetisch" },
  { value: "bo_CN", label: "Tibetisch (China)" },
  { value: "bo_IN", label: "Tibetisch (Indien)" },
  { value: "br", label: "Bretonisch" },
  { value: "br_FR", label: "Bretonisch (Frankreich)" },
  { value: "bs", label: "Bosnisch" },
  { value: "bs_BA", label: "Bosnisch (Bosnien und Herzegowina)" },
  { value: "bs_Cyrl", label: "Bosnisch (Kyrillisch)" },
  {
    value: "bs_Cyrl_BA",
    label: "Bosnisch (Kyrillisch, Bosnien und Herzegowina)",
  },
  { value: "bs_Latn", label: "Bosnisch (Lateinisch)" },
  {
    value: "bs_Latn_BA",
    label: "Bosnisch (Lateinisch, Bosnien und Herzegowina)",
  },
  { value: "ca", label: "Katalanisch" },
  { value: "ca_AD", label: "Katalanisch (Andorra)" },
  { value: "ca_ES", label: "Katalanisch (Spanien)" },
  { value: "ca_FR", label: "Katalanisch (Frankreich)" },
  { value: "ca_IT", label: "Katalanisch (Italien)" },
  { value: "ce", label: "Tschetschenisch" },
  { value: "ce_RU", label: "Tschetschenisch (Russland)" },
  { value: "cs", label: "Tschechisch" },
  { value: "cs_CZ", label: "Tschechisch (Tschechien)" },
  { value: "cy", label: "Walisisch" },
  { value: "cy_GB", label: "Walisisch (Vereinigtes K\u00f6nigreich)" },
  { value: "da", label: "D\u00e4nisch" },
  { value: "da_DK", label: "D\u00e4nisch (D\u00e4nemark)" },
  { value: "da_GL", label: "D\u00e4nisch (Gr\u00f6nland)" },
  { value: "de", label: "Deutsch" },
  { value: "de_AT", label: "Deutsch (\u00d6sterreich)" },
  { value: "de_BE", label: "Deutsch (Belgien)" },
  { value: "de_CH", label: "Deutsch (Schweiz)" },
  { value: "de_DE", label: "Deutsch (Deutschland)" },
  { value: "de_IT", label: "Deutsch (Italien)" },
  { value: "de_LI", label: "Deutsch (Liechtenstein)" },
  { value: "de_LU", label: "Deutsch (Luxemburg)" },
  { value: "dz", label: "Dzongkha" },
  { value: "dz_BT", label: "Dzongkha (Bhutan)" },
  { value: "ee", label: "Ewe" },
  { value: "ee_GH", label: "Ewe (Ghana)" },
  { value: "ee_TG", label: "Ewe (Togo)" },
  { value: "el", label: "Griechisch" },
  { value: "el_CY", label: "Griechisch (Zypern)" },
  { value: "el_GR", label: "Griechisch (Griechenland)" },
  { value: "en", label: "Englisch" },
  { value: "en_AG", label: "Englisch (Antigua und Barbuda)" },
  { value: "en_AI", label: "Englisch (Anguilla)" },
  { value: "en_AS", label: "Englisch (Amerikanisch-Samoa)" },
  { value: "en_AT", label: "Englisch (\u00d6sterreich)" },
  { value: "en_AU", label: "Englisch (Australien)" },
  { value: "en_BB", label: "Englisch (Barbados)" },
  { value: "en_BE", label: "Englisch (Belgien)" },
  { value: "en_BI", label: "Englisch (Burundi)" },
  { value: "en_BM", label: "Englisch (Bermuda)" },
  { value: "en_BS", label: "Englisch (Bahamas)" },
  { value: "en_BW", label: "Englisch (Botsuana)" },
  { value: "en_BZ", label: "Englisch (Belize)" },
  { value: "en_CA", label: "Englisch (Kanada)" },
  { value: "en_CC", label: "Englisch (Kokosinseln)" },
  { value: "en_CH", label: "Englisch (Schweiz)" },
  { value: "en_CK", label: "Englisch (Cookinseln)" },
  { value: "en_CM", label: "Englisch (Kamerun)" },
  { value: "en_CX", label: "Englisch (Weihnachtsinsel)" },
  { value: "en_CY", label: "Englisch (Zypern)" },
  { value: "en_DE", label: "Englisch (Deutschland)" },
  { value: "en_DG", label: "Englisch (Diego Garcia)" },
  { value: "en_DK", label: "Englisch (D\u00e4nemark)" },
  { value: "en_DM", label: "Englisch (Dominica)" },
  { value: "en_ER", label: "Englisch (Eritrea)" },
  { value: "en_FI", label: "Englisch (Finnland)" },
  { value: "en_FJ", label: "Englisch (Fidschi)" },
  { value: "en_FK", label: "Englisch (Falklandinseln)" },
  { value: "en_FM", label: "Englisch (Mikronesien)" },
  { value: "en_GB", label: "Englisch (Vereinigtes K\u00f6nigreich)" },
  { value: "en_GD", label: "Englisch (Grenada)" },
  { value: "en_GG", label: "Englisch (Guernsey)" },
  { value: "en_GH", label: "Englisch (Ghana)" },
  { value: "en_GI", label: "Englisch (Gibraltar)" },
  { value: "en_GM", label: "Englisch (Gambia)" },
  { value: "en_GU", label: "Englisch (Guam)" },
  { value: "en_GY", label: "Englisch (Guyana)" },
  { value: "en_HK", label: "Englisch (Sonderverwaltungsregion Hongkong)" },
  { value: "en_IE", label: "Englisch (Irland)" },
  { value: "en_IL", label: "Englisch (Israel)" },
  { value: "en_IM", label: "Englisch (Isle of Man)" },
  { value: "en_IN", label: "Englisch (Indien)" },
  {
    value: "en_IO",
    label: "Englisch (Britisches Territorium im Indischen Ozean)",
  },
  { value: "en_JE", label: "Englisch (Jersey)" },
  { value: "en_JM", label: "Englisch (Jamaika)" },
  { value: "en_KE", label: "Englisch (Kenia)" },
  { value: "en_KI", label: "Englisch (Kiribati)" },
  { value: "en_KN", label: "Englisch (St. Kitts und Nevis)" },
  { value: "en_KY", label: "Englisch (Kaimaninseln)" },
  { value: "en_LC", label: "Englisch (St. Lucia)" },
  { value: "en_LR", label: "Englisch (Liberia)" },
  { value: "en_LS", label: "Englisch (Lesotho)" },
  { value: "en_MG", label: "Englisch (Madagaskar)" },
  { value: "en_MH", label: "Englisch (Marshallinseln)" },
  { value: "en_MO", label: "Englisch (Sonderverwaltungsregion Macau)" },
  { value: "en_MP", label: "Englisch (N\u00f6rdliche Marianen)" },
  { value: "en_MS", label: "Englisch (Montserrat)" },
  { value: "en_MT", label: "Englisch (Malta)" },
  { value: "en_MU", label: "Englisch (Mauritius)" },
  { value: "en_MW", label: "Englisch (Malawi)" },
  { value: "en_MY", label: "Englisch (Malaysia)" },
  { value: "en_NA", label: "Englisch (Namibia)" },
  { value: "en_NF", label: "Englisch (Norfolkinsel)" },
  { value: "en_NG", label: "Englisch (Nigeria)" },
  { value: "en_NL", label: "Englisch (Niederlande)" },
  { value: "en_NR", label: "Englisch (Nauru)" },
  { value: "en_NU", label: "Englisch (Niue)" },
  { value: "en_NZ", label: "Englisch (Neuseeland)" },
  { value: "en_PG", label: "Englisch (Papua-Neuguinea)" },
  { value: "en_PH", label: "Englisch (Philippinen)" },
  { value: "en_PK", label: "Englisch (Pakistan)" },
  { value: "en_PN", label: "Englisch (Pitcairninseln)" },
  { value: "en_PR", label: "Englisch (Puerto Rico)" },
  { value: "en_PW", label: "Englisch (Palau)" },
  { value: "en_RW", label: "Englisch (Ruanda)" },
  { value: "en_SB", label: "Englisch (Salomonen)" },
  { value: "en_SC", label: "Englisch (Seychellen)" },
  { value: "en_SD", label: "Englisch (Sudan)" },
  { value: "en_SE", label: "Englisch (Schweden)" },
  { value: "en_SG", label: "Englisch (Singapur)" },
  { value: "en_SH", label: "Englisch (St. Helena)" },
  { value: "en_SI", label: "Englisch (Slowenien)" },
  { value: "en_SL", label: "Englisch (Sierra Leone)" },
  { value: "en_SS", label: "Englisch (S\u00fcdsudan)" },
  { value: "en_SX", label: "Englisch (Sint Maarten)" },
  { value: "en_SZ", label: "Englisch (Swasiland)" },
  { value: "en_TC", label: "Englisch (Turks- und Caicosinseln)" },
  { value: "en_TK", label: "Englisch (Tokelau)" },
  { value: "en_TO", label: "Englisch (Tonga)" },
  { value: "en_TT", label: "Englisch (Trinidad und Tobago)" },
  { value: "en_TV", label: "Englisch (Tuvalu)" },
  { value: "en_TZ", label: "Englisch (Tansania)" },
  { value: "en_UG", label: "Englisch (Uganda)" },
  { value: "en_UM", label: "Englisch (Amerikanische \u00dcberseeinseln)" },
  { value: "en_US", label: "Englisch (Vereinigte Staaten)" },
  { value: "en_VC", label: "Englisch (St. Vincent und die Grenadinen)" },
  { value: "en_VG", label: "Englisch (Britische Jungferninseln)" },
  { value: "en_VI", label: "Englisch (Amerikanische Jungferninseln)" },
  { value: "en_VU", label: "Englisch (Vanuatu)" },
  { value: "en_WS", label: "Englisch (Samoa)" },
  { value: "en_ZA", label: "Englisch (S\u00fcdafrika)" },
  { value: "en_ZM", label: "Englisch (Sambia)" },
  { value: "en_ZW", label: "Englisch (Simbabwe)" },
  { value: "eo", label: "Esperanto" },
  { value: "es", label: "Spanisch" },
  { value: "es_AR", label: "Spanisch (Argentinien)" },
  { value: "es_BO", label: "Spanisch (Bolivien)" },
  { value: "es_BR", label: "Spanisch (Brasilien)" },
  { value: "es_BZ", label: "Spanisch (Belize)" },
  { value: "es_CL", label: "Spanisch (Chile)" },
  { value: "es_CO", label: "Spanisch (Kolumbien)" },
  { value: "es_CR", label: "Spanisch (Costa Rica)" },
  { value: "es_CU", label: "Spanisch (Kuba)" },
  { value: "es_DO", label: "Spanisch (Dominikanische Republik)" },
  { value: "es_EA", label: "Spanisch (Ceuta und Melilla)" },
  { value: "es_EC", label: "Spanisch (Ecuador)" },
  { value: "es_ES", label: "Spanisch (Spanien)" },
  { value: "es_GQ", label: "Spanisch (\u00c4quatorialguinea)" },
  { value: "es_GT", label: "Spanisch (Guatemala)" },
  { value: "es_HN", label: "Spanisch (Honduras)" },
  { value: "es_IC", label: "Spanisch (Kanarische Inseln)" },
  { value: "es_MX", label: "Spanisch (Mexiko)" },
  { value: "es_NI", label: "Spanisch (Nicaragua)" },
  { value: "es_PA", label: "Spanisch (Panama)" },
  { value: "es_PE", label: "Spanisch (Peru)" },
  { value: "es_PH", label: "Spanisch (Philippinen)" },
  { value: "es_PR", label: "Spanisch (Puerto Rico)" },
  { value: "es_PY", label: "Spanisch (Paraguay)" },
  { value: "es_SV", label: "Spanisch (El Salvador)" },
  { value: "es_US", label: "Spanisch (Vereinigte Staaten)" },
  { value: "es_UY", label: "Spanisch (Uruguay)" },
  { value: "es_VE", label: "Spanisch (Venezuela)" },
  { value: "et", label: "Estnisch" },
  { value: "et_EE", label: "Estnisch (Estland)" },
  { value: "eu", label: "Baskisch" },
  { value: "eu_ES", label: "Baskisch (Spanien)" },
  { value: "fa", label: "Persisch" },
  { value: "fa_AF", label: "Persisch (Afghanistan)" },
  { value: "fa_IR", label: "Persisch (Iran)" },
  { value: "ff", label: "Ful" },
  { value: "ff_CM", label: "Ful (Kamerun)" },
  { value: "ff_GN", label: "Ful (Guinea)" },
  { value: "ff_MR", label: "Ful (Mauretanien)" },
  { value: "ff_SN", label: "Ful (Senegal)" },
  { value: "fi", label: "Finnisch" },
  { value: "fi_FI", label: "Finnisch (Finnland)" },
  { value: "fo", label: "F\u00e4r\u00f6isch" },
  { value: "fo_DK", label: "F\u00e4r\u00f6isch (D\u00e4nemark)" },
  { value: "fo_FO", label: "F\u00e4r\u00f6isch (F\u00e4r\u00f6er)" },
  { value: "fr", label: "Franz\u00f6sisch" },
  { value: "fr_BE", label: "Franz\u00f6sisch (Belgien)" },
  { value: "fr_BF", label: "Franz\u00f6sisch (Burkina Faso)" },
  { value: "fr_BI", label: "Franz\u00f6sisch (Burundi)" },
  { value: "fr_BJ", label: "Franz\u00f6sisch (Benin)" },
  { value: "fr_BL", label: "Franz\u00f6sisch (St. Barth\u00e9lemy)" },
  { value: "fr_CA", label: "Franz\u00f6sisch (Kanada)" },
  { value: "fr_CD", label: "Franz\u00f6sisch (Kongo-Kinshasa)" },
  {
    value: "fr_CF",
    label: "Franz\u00f6sisch (Zentralafrikanische Republik)",
  },
  { value: "fr_CG", label: "Franz\u00f6sisch (Kongo-Brazzaville)" },
  { value: "fr_CH", label: "Franz\u00f6sisch (Schweiz)" },
  { value: "fr_CI", label: "Franz\u00f6sisch (C\u00f4te d\u2019Ivoire)" },
  { value: "fr_CM", label: "Franz\u00f6sisch (Kamerun)" },
  { value: "fr_DJ", label: "Franz\u00f6sisch (Dschibuti)" },
  { value: "fr_DZ", label: "Franz\u00f6sisch (Algerien)" },
  { value: "fr_FR", label: "Franz\u00f6sisch (Frankreich)" },
  { value: "fr_GA", label: "Franz\u00f6sisch (Gabun)" },
  { value: "fr_GF", label: "Franz\u00f6sisch (Franz\u00f6sisch-Guayana)" },
  { value: "fr_GN", label: "Franz\u00f6sisch (Guinea)" },
  { value: "fr_GP", label: "Franz\u00f6sisch (Guadeloupe)" },
  { value: "fr_GQ", label: "Franz\u00f6sisch (\u00c4quatorialguinea)" },
  { value: "fr_HT", label: "Franz\u00f6sisch (Haiti)" },
  { value: "fr_KM", label: "Franz\u00f6sisch (Komoren)" },
  { value: "fr_LU", label: "Franz\u00f6sisch (Luxemburg)" },
  { value: "fr_MA", label: "Franz\u00f6sisch (Marokko)" },
  { value: "fr_MC", label: "Franz\u00f6sisch (Monaco)" },
  { value: "fr_MF", label: "Franz\u00f6sisch (St. Martin)" },
  { value: "fr_MG", label: "Franz\u00f6sisch (Madagaskar)" },
  { value: "fr_ML", label: "Franz\u00f6sisch (Mali)" },
  { value: "fr_MQ", label: "Franz\u00f6sisch (Martinique)" },
  { value: "fr_MR", label: "Franz\u00f6sisch (Mauretanien)" },
  { value: "fr_MU", label: "Franz\u00f6sisch (Mauritius)" },
  { value: "fr_NC", label: "Franz\u00f6sisch (Neukaledonien)" },
  { value: "fr_NE", label: "Franz\u00f6sisch (Niger)" },
  {
    value: "fr_PF",
    label: "Franz\u00f6sisch (Franz\u00f6sisch-Polynesien)",
  },
  { value: "fr_PM", label: "Franz\u00f6sisch (St. Pierre und Miquelon)" },
  { value: "fr_RE", label: "Franz\u00f6sisch (R\u00e9union)" },
  { value: "fr_RW", label: "Franz\u00f6sisch (Ruanda)" },
  { value: "fr_SC", label: "Franz\u00f6sisch (Seychellen)" },
  { value: "fr_SN", label: "Franz\u00f6sisch (Senegal)" },
  { value: "fr_SY", label: "Franz\u00f6sisch (Syrien)" },
  { value: "fr_TD", label: "Franz\u00f6sisch (Tschad)" },
  { value: "fr_TG", label: "Franz\u00f6sisch (Togo)" },
  { value: "fr_TN", label: "Franz\u00f6sisch (Tunesien)" },
  { value: "fr_VU", label: "Franz\u00f6sisch (Vanuatu)" },
  { value: "fr_WF", label: "Franz\u00f6sisch (Wallis und Futuna)" },
  { value: "fr_YT", label: "Franz\u00f6sisch (Mayotte)" },
  { value: "fy", label: "Westfriesisch" },
  { value: "fy_NL", label: "Westfriesisch (Niederlande)" },
  { value: "ga", label: "Irisch" },
  { value: "ga_IE", label: "Irisch (Irland)" },
  { value: "gd", label: "Schottisches G\u00e4lisch" },
  {
    value: "gd_GB",
    label: "Schottisches G\u00e4lisch (Vereinigtes K\u00f6nigreich)",
  },
  { value: "gl", label: "Galicisch" },
  { value: "gl_ES", label: "Galicisch (Spanien)" },
  { value: "gu", label: "Gujarati" },
  { value: "gu_IN", label: "Gujarati (Indien)" },
  { value: "gv", label: "Manx" },
  { value: "gv_IM", label: "Manx (Isle of Man)" },
  { value: "ha", label: "Haussa" },
  { value: "ha_GH", label: "Haussa (Ghana)" },
  { value: "ha_NE", label: "Haussa (Niger)" },
  { value: "ha_NG", label: "Haussa (Nigeria)" },
  { value: "he", label: "Hebr\u00e4isch" },
  { value: "he_IL", label: "Hebr\u00e4isch (Israel)" },
  { value: "hi", label: "Hindi" },
  { value: "hi_IN", label: "Hindi (Indien)" },
  { value: "hr", label: "Kroatisch" },
  { value: "hr_BA", label: "Kroatisch (Bosnien und Herzegowina)" },
  { value: "hr_HR", label: "Kroatisch (Kroatien)" },
  { value: "hu", label: "Ungarisch" },
  { value: "hu_HU", label: "Ungarisch (Ungarn)" },
  { value: "hy", label: "Armenisch" },
  { value: "hy_AM", label: "Armenisch (Armenien)" },
  { value: "id", label: "Indonesisch" },
  { value: "id_ID", label: "Indonesisch (Indonesien)" },
  { value: "ig", label: "Igbo" },
  { value: "ig_NG", label: "Igbo (Nigeria)" },
  { value: "ii", label: "Yi" },
  { value: "ii_CN", label: "Yi (China)" },
  { value: "is", label: "Isl\u00e4ndisch" },
  { value: "is_IS", label: "Isl\u00e4ndisch (Island)" },
  { value: "it", label: "Italienisch" },
  { value: "it_CH", label: "Italienisch (Schweiz)" },
  { value: "it_IT", label: "Italienisch (Italien)" },
  { value: "it_SM", label: "Italienisch (San Marino)" },
  { value: "it_VA", label: "Italienisch (Vatikanstadt)" },
  { value: "ja", label: "Japanisch" },
  { value: "ja_JP", label: "Japanisch (Japan)" },
  { value: "ka", label: "Georgisch" },
  { value: "ka_GE", label: "Georgisch (Georgien)" },
  { value: "ki", label: "Kikuyu" },
  { value: "ki_KE", label: "Kikuyu (Kenia)" },
  { value: "kk", label: "Kasachisch" },
  { value: "kk_KZ", label: "Kasachisch (Kasachstan)" },
  { value: "kl", label: "Gr\u00f6nl\u00e4ndisch" },
  { value: "kl_GL", label: "Gr\u00f6nl\u00e4ndisch (Gr\u00f6nland)" },
  { value: "km", label: "Khmer" },
  { value: "km_KH", label: "Khmer (Kambodscha)" },
  { value: "kn", label: "Kannada" },
  { value: "kn_IN", label: "Kannada (Indien)" },
  { value: "ko", label: "Koreanisch" },
  { value: "ko_KP", label: "Koreanisch (Nordkorea)" },
  { value: "ko_KR", label: "Koreanisch (S\u00fcdkorea)" },
  { value: "ks", label: "Kaschmiri" },
  { value: "ks_IN", label: "Kaschmiri (Indien)" },
  { value: "kw", label: "Kornisch" },
  { value: "kw_GB", label: "Kornisch (Vereinigtes K\u00f6nigreich)" },
  { value: "ky", label: "Kirgisisch" },
  { value: "ky_KG", label: "Kirgisisch (Kirgisistan)" },
  { value: "lb", label: "Luxemburgisch" },
  { value: "lb_LU", label: "Luxemburgisch (Luxemburg)" },
  { value: "lg", label: "Ganda" },
  { value: "lg_UG", label: "Ganda (Uganda)" },
  { value: "ln", label: "Lingala" },
  { value: "ln_AO", label: "Lingala (Angola)" },
  { value: "ln_CD", label: "Lingala (Kongo-Kinshasa)" },
  { value: "ln_CF", label: "Lingala (Zentralafrikanische Republik)" },
  { value: "ln_CG", label: "Lingala (Kongo-Brazzaville)" },
  { value: "lo", label: "Laotisch" },
  { value: "lo_LA", label: "Laotisch (Laos)" },
  { value: "lt", label: "Litauisch" },
  { value: "lt_LT", label: "Litauisch (Litauen)" },
  { value: "lu", label: "Luba-Katanga" },
  { value: "lu_CD", label: "Luba-Katanga (Kongo-Kinshasa)" },
  { value: "lv", label: "Lettisch" },
  { value: "lv_LV", label: "Lettisch (Lettland)" },
  { value: "mg", label: "Madagassisch" },
  { value: "mg_MG", label: "Madagassisch (Madagaskar)" },
  { value: "mk", label: "Mazedonisch" },
  { value: "mk_MK", label: "Mazedonisch (Mazedonien)" },
  { value: "ml", label: "Malayalam" },
  { value: "ml_IN", label: "Malayalam (Indien)" },
  { value: "mn", label: "Mongolisch" },
  { value: "mn_MN", label: "Mongolisch (Mongolei)" },
  { value: "mr", label: "Marathi" },
  { value: "mr_IN", label: "Marathi (Indien)" },
  { value: "ms", label: "Malaiisch" },
  { value: "ms_BN", label: "Malaiisch (Brunei Darussalam)" },
  { value: "ms_MY", label: "Malaiisch (Malaysia)" },
  { value: "ms_SG", label: "Malaiisch (Singapur)" },
  { value: "mt", label: "Maltesisch" },
  { value: "mt_MT", label: "Maltesisch (Malta)" },
  { value: "my", label: "Birmanisch" },
  { value: "my_MM", label: "Birmanisch (Myanmar)" },
  { value: "nb", label: "Norwegisch Bokm\u00e5l" },
  { value: "nb_NO", label: "Norwegisch Bokm\u00e5l (Norwegen)" },
  {
    value: "nb_SJ",
    label: "Norwegisch Bokm\u00e5l (Spitzbergen und Jan Mayen)",
  },
  { value: "nd", label: "Nord-Ndebele" },
  { value: "nd_ZW", label: "Nord-Ndebele (Simbabwe)" },
  { value: "ne", label: "Nepalesisch" },
  { value: "ne_IN", label: "Nepalesisch (Indien)" },
  { value: "ne_NP", label: "Nepalesisch (Nepal)" },
  { value: "nl", label: "Niederl\u00e4ndisch" },
  { value: "nl_AW", label: "Niederl\u00e4ndisch (Aruba)" },
  { value: "nl_BE", label: "Niederl\u00e4ndisch (Belgien)" },
  {
    value: "nl_BQ",
    label: "Niederl\u00e4ndisch (Bonaire, Sint Eustatius und Saba)",
  },
  { value: "nl_CW", label: "Niederl\u00e4ndisch (Cura\u00e7ao)" },
  { value: "nl_NL", label: "Niederl\u00e4ndisch (Niederlande)" },
  { value: "nl_SR", label: "Niederl\u00e4ndisch (Suriname)" },
  { value: "nl_SX", label: "Niederl\u00e4ndisch (Sint Maarten)" },
  { value: "nn", label: "Norwegisch Nynorsk" },
  { value: "nn_NO", label: "Norwegisch Nynorsk (Norwegen)" },
  { value: "no", label: "Norwegisch" },
  { value: "no_NO", label: "Norwegisch (Norwegen)" },
  { value: "om", label: "Oromo" },
  { value: "om_ET", label: "Oromo (\u00c4thiopien)" },
  { value: "om_KE", label: "Oromo (Kenia)" },
  { value: "or", label: "Oriya" },
  { value: "or_IN", label: "Oriya (Indien)" },
  { value: "os", label: "Ossetisch" },
  { value: "os_GE", label: "Ossetisch (Georgien)" },
  { value: "os_RU", label: "Ossetisch (Russland)" },
  { value: "pa", label: "Punjabi" },
  { value: "pa_Arab", label: "Punjabi (Arabisch)" },
  { value: "pa_Arab_PK", label: "Punjabi (Arabisch, Pakistan)" },
  { value: "pa_Guru", label: "Punjabi (Gurmukhi)" },
  { value: "pa_Guru_IN", label: "Punjabi (Gurmukhi, Indien)" },
  { value: "pa_IN", label: "Punjabi (Indien)" },
  { value: "pa_PK", label: "Punjabi (Pakistan)" },
  { value: "pl", label: "Polnisch" },
  { value: "pl_PL", label: "Polnisch (Polen)" },
  { value: "ps", label: "Paschtu" },
  { value: "ps_AF", label: "Paschtu (Afghanistan)" },
  { value: "pt", label: "Portugiesisch" },
  { value: "pt_AO", label: "Portugiesisch (Angola)" },
  { value: "pt_BR", label: "Portugiesisch (Brasilien)" },
  { value: "pt_CH", label: "Portugiesisch (Schweiz)" },
  { value: "pt_CV", label: "Portugiesisch (Cabo Verde)" },
  { value: "pt_GQ", label: "Portugiesisch (\u00c4quatorialguinea)" },
  { value: "pt_GW", label: "Portugiesisch (Guinea-Bissau)" },
  { value: "pt_LU", label: "Portugiesisch (Luxemburg)" },
  {
    value: "pt_MO",
    label: "Portugiesisch (Sonderverwaltungsregion Macau)",
  },
  { value: "pt_MZ", label: "Portugiesisch (Mosambik)" },
  { value: "pt_PT", label: "Portugiesisch (Portugal)" },
  {
    value: "pt_ST",
    label: "Portugiesisch (S\u00e3o Tom\u00e9 und Pr\u00edncipe)",
  },
  { value: "pt_TL", label: "Portugiesisch (Timor-Leste)" },
  { value: "qu", label: "Quechua" },
  { value: "qu_BO", label: "Quechua (Bolivien)" },
  { value: "qu_EC", label: "Quechua (Ecuador)" },
  { value: "qu_PE", label: "Quechua (Peru)" },
  { value: "rm", label: "R\u00e4toromanisch" },
  { value: "rm_CH", label: "R\u00e4toromanisch (Schweiz)" },
  { value: "rn", label: "Rundi" },
  { value: "rn_BI", label: "Rundi (Burundi)" },
  { value: "ro", label: "Rum\u00e4nisch" },
  { value: "ro_MD", label: "Rum\u00e4nisch (Republik Moldau)" },
  { value: "ro_RO", label: "Rum\u00e4nisch (Rum\u00e4nien)" },
  { value: "ru", label: "Russisch" },
  { value: "ru_BY", label: "Russisch (Belarus)" },
  { value: "ru_KG", label: "Russisch (Kirgisistan)" },
  { value: "ru_KZ", label: "Russisch (Kasachstan)" },
  { value: "ru_MD", label: "Russisch (Republik Moldau)" },
  { value: "ru_RU", label: "Russisch (Russland)" },
  { value: "ru_UA", label: "Russisch (Ukraine)" },
  { value: "rw", label: "Kinyarwanda" },
  { value: "rw_RW", label: "Kinyarwanda (Ruanda)" },
  { value: "se", label: "Nordsamisch" },
  { value: "se_FI", label: "Nordsamisch (Finnland)" },
  { value: "se_NO", label: "Nordsamisch (Norwegen)" },
  { value: "se_SE", label: "Nordsamisch (Schweden)" },
  { value: "sg", label: "Sango" },
  { value: "sg_CF", label: "Sango (Zentralafrikanische Republik)" },
  { value: "sh", label: "Serbo-Kroatisch" },
  { value: "sh_BA", label: "Serbo-Kroatisch (Bosnien und Herzegowina)" },
  { value: "si", label: "Singhalesisch" },
  { value: "si_LK", label: "Singhalesisch (Sri Lanka)" },
  { value: "sk", label: "Slowakisch" },
  { value: "sk_SK", label: "Slowakisch (Slowakei)" },
  { value: "sl", label: "Slowenisch" },
  { value: "sl_SI", label: "Slowenisch (Slowenien)" },
  { value: "sn", label: "Shona" },
  { value: "sn_ZW", label: "Shona (Simbabwe)" },
  { value: "so", label: "Somali" },
  { value: "so_DJ", label: "Somali (Dschibuti)" },
  { value: "so_ET", label: "Somali (\u00c4thiopien)" },
  { value: "so_KE", label: "Somali (Kenia)" },
  { value: "so_SO", label: "Somali (Somalia)" },
  { value: "sq", label: "Albanisch" },
  { value: "sq_AL", label: "Albanisch (Albanien)" },
  { value: "sq_MK", label: "Albanisch (Mazedonien)" },
  { value: "sq_XK", label: "Albanisch (Kosovo)" },
  { value: "sr", label: "Serbisch" },
  { value: "sr_BA", label: "Serbisch (Bosnien und Herzegowina)" },
  { value: "sr_Cyrl", label: "Serbisch (Kyrillisch)" },
  {
    value: "sr_Cyrl_BA",
    label: "Serbisch (Kyrillisch, Bosnien und Herzegowina)",
  },
  { value: "sr_Cyrl_ME", label: "Serbisch (Kyrillisch, Montenegro)" },
  { value: "sr_Cyrl_RS", label: "Serbisch (Kyrillisch, Serbien)" },
  { value: "sr_Cyrl_XK", label: "Serbisch (Kyrillisch, Kosovo)" },
  { value: "sr_Latn", label: "Serbisch (Lateinisch)" },
  {
    value: "sr_Latn_BA",
    label: "Serbisch (Lateinisch, Bosnien und Herzegowina)",
  },
  { value: "sr_Latn_ME", label: "Serbisch (Lateinisch, Montenegro)" },
  { value: "sr_Latn_RS", label: "Serbisch (Lateinisch, Serbien)" },
  { value: "sr_Latn_XK", label: "Serbisch (Lateinisch, Kosovo)" },
  { value: "sr_ME", label: "Serbisch (Montenegro)" },
  { value: "sr_RS", label: "Serbisch (Serbien)" },
  { value: "sr_XK", label: "Serbisch (Kosovo)" },
  { value: "sv", label: "Schwedisch" },
  { value: "sv_AX", label: "Schwedisch (\u00c5landinseln)" },
  { value: "sv_FI", label: "Schwedisch (Finnland)" },
  { value: "sv_SE", label: "Schwedisch (Schweden)" },
  { value: "sw", label: "Suaheli" },
  { value: "sw_CD", label: "Suaheli (Kongo-Kinshasa)" },
  { value: "sw_KE", label: "Suaheli (Kenia)" },
  { value: "sw_TZ", label: "Suaheli (Tansania)" },
  { value: "sw_UG", label: "Suaheli (Uganda)" },
  { value: "ta", label: "Tamil" },
  { value: "ta_IN", label: "Tamil (Indien)" },
  { value: "ta_LK", label: "Tamil (Sri Lanka)" },
  { value: "ta_MY", label: "Tamil (Malaysia)" },
  { value: "ta_SG", label: "Tamil (Singapur)" },
  { value: "te", label: "Telugu" },
  { value: "te_IN", label: "Telugu (Indien)" },
  { value: "tg", label: "Tadschikisch" },
  { value: "tg_TJ", label: "Tadschikisch (Tadschikistan)" },
  { value: "th", label: "Thail\u00e4ndisch" },
  { value: "th_TH", label: "Thail\u00e4ndisch (Thailand)" },
  { value: "ti", label: "Tigrinya" },
  { value: "ti_ER", label: "Tigrinya (Eritrea)" },
  { value: "ti_ET", label: "Tigrinya (\u00c4thiopien)" },
  { value: "tl", label: "Tagalog" },
  { value: "tl_PH", label: "Tagalog (Philippinen)" },
  { value: "to", label: "Tongaisch" },
  { value: "to_TO", label: "Tongaisch (Tonga)" },
  { value: "tr", label: "T\u00fcrkisch" },
  { value: "tr_CY", label: "T\u00fcrkisch (Zypern)" },
  { value: "tr_TR", label: "T\u00fcrkisch (T\u00fcrkei)" },
  { value: "tt", label: "Tatarisch" },
  { value: "tt_RU", label: "Tatarisch (Russland)" },
  { value: "ug", label: "Uigurisch" },
  { value: "ug_CN", label: "Uigurisch (China)" },
  { value: "uk", label: "Ukrainisch" },
  { value: "uk_UA", label: "Ukrainisch (Ukraine)" },
  { value: "ur", label: "Urdu" },
  { value: "ur_IN", label: "Urdu (Indien)" },
  { value: "ur_PK", label: "Urdu (Pakistan)" },
  { value: "uz", label: "Usbekisch" },
  { value: "uz_AF", label: "Usbekisch (Afghanistan)" },
  { value: "uz_Arab", label: "Usbekisch (Arabisch)" },
  { value: "uz_Arab_AF", label: "Usbekisch (Arabisch, Afghanistan)" },
  { value: "uz_Cyrl", label: "Usbekisch (Kyrillisch)" },
  { value: "uz_Cyrl_UZ", label: "Usbekisch (Kyrillisch, Usbekistan)" },
  { value: "uz_Latn", label: "Usbekisch (Lateinisch)" },
  { value: "uz_Latn_UZ", label: "Usbekisch (Lateinisch, Usbekistan)" },
  { value: "uz_UZ", label: "Usbekisch (Usbekistan)" },
  { value: "vi", label: "Vietnamesisch" },
  { value: "vi_VN", label: "Vietnamesisch (Vietnam)" },
  { value: "wo", label: "Wolof" },
  { value: "wo_SN", label: "Wolof (Senegal)" },
  { value: "yi", label: "Jiddisch" },
  { value: "yo", label: "Yoruba" },
  { value: "yo_BJ", label: "Yoruba (Benin)" },
  { value: "yo_NG", label: "Yoruba (Nigeria)" },
  { value: "zh", label: "Chinesisch" },
  { value: "zh_CN", label: "Chinesisch (China)" },
  {
    value: "zh_HK",
    label: "Chinesisch (Sonderverwaltungsregion Hongkong)",
  },
  { value: "zh_Hans", label: "Chinesisch (Vereinfacht)" },
  { value: "zh_Hans_CN", label: "Chinesisch (Vereinfacht, China)" },
  {
    value: "zh_Hans_HK",
    label: "Chinesisch (Vereinfacht, Sonderverwaltungsregion Hongkong)",
  },
  {
    value: "zh_Hans_MO",
    label: "Chinesisch (Vereinfacht, Sonderverwaltungsregion Macau)",
  },
  { value: "zh_Hans_SG", label: "Chinesisch (Vereinfacht, Singapur)" },
  { value: "zh_Hant", label: "Chinesisch (Traditionell)" },
  {
    value: "zh_Hant_HK",
    label: "Chinesisch (Traditionell, Sonderverwaltungsregion Hongkong)",
  },
  {
    value: "zh_Hant_MO",
    label: "Chinesisch (Traditionell, Sonderverwaltungsregion Macau)",
  },
  { value: "zh_Hant_TW", label: "Chinesisch (Traditionell, Taiwan)" },
  { value: "zh_MO", label: "Chinesisch (Sonderverwaltungsregion Macau)" },
  { value: "zh_SG", label: "Chinesisch (Singapur)" },
  { value: "zh_TW", label: "Chinesisch (Taiwan)" },
  { value: "zu", label: "Zulu" },
  { value: "zu_ZA", label: "Zulu (S\u00fcdafrika)" },
];

