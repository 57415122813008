class Tweets {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("tweets", params);
  }

  get(tweetId) {
    return this.client.get(`tweets/${tweetId}`);
  }

  create(tweet) {
    delete tweet.id;
    return this.client.post(`tweets/new`, tweet);
  }

  edit(tweetId, newtweet) {
    return this.client.patch(`tweets/${tweetId}/edit`, newtweet);
  }

  editOrCreate(tweetId, newtweet) {
    return this.client.put(`tweets/${tweetId}/edit`, newtweet);
  }

  delete(tweetId) {
    return this.client.delete(`tweets/${tweetId}/delete`);
  }
}

export default Tweets;
