import React from "react";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { AiOutlineCaretDown, AiOutlineCaretRight, AiOutlineSearch } from "react-icons/ai";
import { BsSortDown } from "react-icons/bs";
import DataTable from "react-data-table-component";
import localConnector from "../../../../connector"

import { isMobile } from "react-device-detect";
import { BiDotsVertical } from "react-icons/bi";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class Companies extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            loading: false,
            error: null,
            companies: [],
            totalRows: 0,
            rowsPerPage: 10,
            search: "",
            start: 0,
            loadMore: false,
            showDetails: false,
            showEmails: false,
        };
    }
    handleClickOutside(event) {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.props.onClickOutside && this.props.onClickOutside();
            this.setState({ showDetails: -1, showEmails: false })
        }
    };

    componentDidMount = async () => {
        this.scrollDetection();
        document.addEventListener('click', this.handleClickOutside, true);
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };



    loadMore = async () => {
        this.setState({ loadMore: true });
        this.state.start += 10;
        let url = `limit=10&start=${this.state.start}`;

        let response = await localConnector.contacts.getCompanies(this.props.id, encodeURI(url));
        this.setState({
            companies: [
                ...this.state.companies.concat(Object.entries(response.data.companies)),
            ],
            loadMore: false,
        });
    };

    scrollDetection = () => {
        window.addEventListener("scroll", () => {
            const scrollable = document.documentElement.scrollHeight - window.innerHeight;
            const scrolled = window.scrollY

            if (Math.ceil(scrolled) === scrollable && isMobile && !this.state.loadMore && this.props.tableCompanies.length > 9) {
                this.loadMore();
            }
        })
    }

    render() {
        const { id, tableCompanies, ref, paginationComponentOptions } = this.props
        const { showEmails } = this.state

        const columns = [
            {
                name: "Unternehmen",
                selector: (row) =>
                    row.companyname &&
                        row.companyname.length > 25 ? (
                        <div class="group py-[50px]">
                            {row.companyname ? row.companyname : ""}
                            <div class="absolute top-[-10px] left--[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row.companyname
                                        ? row.companyname
                                        : ""}
                                </span>
                            </div>
                        </div>
                    ) : row.companyname ? (
                        row.companyname
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "300px",
            },
            {
                name: "Adresse",
                selector: (row) =>
                    (row.companyaddress1 &&
                        row.companyaddress1.length > 11) ||
                        (row.companyaddress2 &&
                            row.companyaddress2.length > 11) ? (
                        <div class="group py-[50px]">
                            {row.companyaddress1
                                ? row.companyaddress1
                                : row.companyaddress2
                                    ? row.companyaddress2
                                    : ""}
                            <div class="absolute top-[-10px] left-0 flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row.companyaddress1
                                        ? row.companyaddress1
                                        : row.companyaddress2
                                            ? row.companyaddress2
                                            : ""}
                                </span>
                            </div>
                        </div>
                    ) : row.companyaddress1 ? (
                        row.companyaddress1
                    ) : row.companyaddress2 ? (
                        row.companyaddress2
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "150px",
            },
            {
                name: "PLZ",
                selector: (row) =>
                    row.companyzipcode ? row.companyzipcode : "",
                sortable: true,
                width: "150px",
            },
            {
                name: "Ort",
                selector: (row) =>
                    row.companycity &&
                        row.companycity.length > 25 ? (
                        <div class="group py-[50px]">
                            {row.companycity ? row.companycity : ""}
                            <div class="absolute top-[-10px] left-0 flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row.companycity
                                        ? row.companycity
                                        : ""}
                                </span>
                            </div>
                        </div>
                    ) : row.companycity ? (
                        row.companycity
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "150px",
            },
            {
                name: "Aktionen",
                selector: (row) => (
                    <div className="flex gap-[20px]  items-center">
                    <div class="dropdown">
                      <button className="bg-[#EFF7FF] flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f]">
                        <FaEnvelope size={16} color="#004f9f" />
                        <p>Nachricht senden</p>
                      </button>
                      <div class="dropdown-content" style={{ left:"-1px", width:"calc(100% + 2px" }}>
                        {row.companyemail ? (
                          <>
                            <div className="group">
                              <div
                                onClick={() =>
                                  this.setState({ showEmails: !showEmails })
                                }
                                className="flex flex-row items-center justify-between cursor-pointer"
                              >
                                E-Mail
                                <AiOutlineCaretRight />
                              </div>
                              <div
                                onMouseLeave={() =>
                                  this.setState({ showEmails: !showEmails })
                                }
                                class={`${showEmails ? "flex" : "hidden"
                                  } flex-col absolute ${row.companyemail &&
                                    row.companyemailadresse_buchhaltung
                                    ? "top-[-50%]"
                                    : "top-[-10%]"
                                  } left-[100%] bg-[#eef7ff] p-[10px] gap-2 rounded-[10px] `}
                              >
                                {row.companyemail && (
                                  <>
                                    <a
                                      className=""
                                      href={`mailto: ${row.companyemail}`}
                                    >
                                      {row.companyemail}
                                    </a>
                                    {row.companyemailadresse_buchhaltung && (
                                      <a
                                        className=""
                                        href={`mailto: ${row.companyemailadresse_buchhaltung}`}
                                      >
                                        {row.companyemailadresse_buchhaltung}
                                      </a>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : <>No email provided</>}
                        {!row.companyemail &&
                          !row.companymobile &&
                          <br />}
                        {row.companymobile ? (
                          <a href={`sms: ${row.companymobile}`}>SMS Mobile</a>
                        ) : <>No mobile provided</>}
                      </div>
                    </div>
                    {row.companyphone ||
                      (row.companymobile && (
                        <button
                          className="bg-[#EFF7FF] absolute flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f] translate-x-[173px]"
                          to="#"
                        >
                          <FaPhoneAlt size={16} color="#004f9f" />
                          <a
                            href={`tel: ${row.companyphone
                              ? row.companyphone
                              : row.companymobile
                              }`}
                          >
                            Anrufen
                          </a>
                        </button>
                      ))}
                  </div>

                ),
                sortable: false,
                style: {
                    minWidth: "380px",
                },
            },

            {
                selector: (row) => (
                    <Link
                        className="bg-[#004f9f] hover:bg-[#004f9fba] text-white p-[20px] w-fit right-0 absolute translate-y-[-50%]"
                        to={`/unternehmenskarte/${row.id}`}
                    >
                        <p>Unternehmen anzeigen</p>
                    </Link>
                ),
                sortable: false,
                width: "fit-content",
            },
            // {
            //   selector: (row) => <Link to="/kontaktkarte">Kontakte anzeigen</Link>,
            //   sortable: false,
            //   width: "fit-content",
            //   style: {
            //     backgroundColor: "#004f9f",
            //     color: "white",
            //     marginLeft: "auto",
            //     marginTop: "10px",
            //     marginBottom: "10px",
            //     transform: "translateX(-30px)",
            //     "&:hover": {
            //       cursor: "pointer",
            //       backgroundColor: "#004f9fba",
            //     },
            //   },
            // },
        ];

        return (
            <div className="table-container z-1">
                {!isMobile ? <DataTable
                    data={tableCompanies}
                    columns={columns}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    pagination={isMobile ? false : true}
                    progressPending={this.state.loading}
                    sortIcon={
                        <BsSortDown
                            sx={{
                                color: "#004f9f",
                                opacity: 1,
                                height: "20px",
                                width: "24px",
                            }}
                        />
                    }
                    paginationServer
                    progressComponent={
                        <div className="nodatacomponent">
                            <PulseLoader color={"#004f9f"} />{" "}
                        </div>
                    }
                    noDataComponent={
                        <div className="nodatacomponent">
                            Keine Daten vorhanden...
                        </div>
                    }
                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                    paginationComponentOptions={paginationComponentOptions}
                /> :
                    <>
                        {this.state.loading ? <div className="nodatacomponent">
                            <PulseLoader color={"#004f9f"} size={10} />
                        </div> :
                            <div ref={this.ref}>{
                                tableCompanies?.map((company, i) => (
                                    company.companyname && (
                                        <div key={i} className="flex flex-row gap-4 flex-1 px-1 pt-4 pb-2 border-b border-b-[#0000001f]">
                                            <div className="flex flex-col flex-wrap flex-1 gap-[13px]">
                                                <div className="flex flex-row justify-between gap-[10px]">
                                                    <Link to={`/unternehmenskarte/${company.id}`} className="flex flex-col gap-1 flex-1">
                                                        <p className="text-[12px] text-[#004f9f]">Unternehmen</p>
                                                        <div className="flex flex-wrap items-start gap-1 text-gray-500">
                                                            <p className="text-[12px]">{company.companyname}</p>
                                                        </div>
                                                    </Link>
                                                    <div className="flex flex-col gap-1 flex-1 max-w-[30%]">
                                                        <p className="text-[12px] text-[#004f9f]">Adresse</p>
                                                        <p className="text-gray-500 text-[12px]">{company.companyaddress1
                                                            ? company.companyaddress1
                                                            : company.companyaddress2
                                                                ? company.companyaddress2
                                                                : ""} </p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row justify-between gap-[10px]">
                                                    <div className="flex flex-col gap-1 flex-1">
                                                        <p className="text-[12px] text-[#004f9f]">PLZ</p>
                                                        <div className="flex flex-wrap items-start gap-1 text-gray-500">
                                                            <p className="text-[12px]">{company.companyzipcode}</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-1 flex-1 max-w-[30%]">
                                                        <p className="text-[12px] text-[#004f9f]">Ort</p>
                                                        <p className="text-gray-500 text-[12px]">{company.companycity}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative w-fit">
                                                <div className="w-fit flex items-center">
                                                    <BiDotsVertical onClick={() => this.setState({ showDetails: this.state.showDetails === i ? -1 : i, showEmails: this.state.showDetails === i && false })} size={24} color="#004f9f" />
                                                </div>
                                                {this.state.showDetails === i && (
                                                    <div class={`dropdown-mobile right-0 w-[140px!important] border-2 border-[#004f9f] z-[99999999]`}>
                                                        {this.state.showDetails === i && (
                                                            <>
                                                                <button className="bg-[#EFF7FF] flex items-center gap-[5px] py-2 px-2.5 rounded-lg text-[#004f9f]">
                                                                    <FaEnvelope size={16} color="#004f9f" />
                                                                    <p className="text-[12px]">Nachricht senden</p>
                                                                </button>
                                                                <div class="dropdown-content-mobile">
                                                                    {company.companyemail ? (
                                                                        <>
                                                                            <div className="group">
                                                                                <div
                                                                                    onClick={() =>
                                                                                        this.setState({ showEmails: !showEmails })
                                                                                    }
                                                                                    className="flex relative flex-row items-center justify-between cursor-pointer text-[12px]"
                                                                                >
                                                                                    E-Mail
                                                                                    <AiOutlineCaretDown />
                                                                                    <div
                                                                                        onMouseLeave={() =>
                                                                                            this.setState({ showEmails: !showEmails })
                                                                                        }
                                                                                        class={`${showEmails ? "flex" : "hidden"
                                                                                            } flex-col absolute top-[120%] min-w-[150px] right-[-20px] bg-[#eef7ff] p-[10px] gap-2 `}
                                                                                    >
                                                                                        {company.companyemail && (
                                                                                            <>
                                                                                                <a
                                                                                                    className="text-[12px]"
                                                                                                    href={`mailto: ${company.companyemail}`}
                                                                                                >
                                                                                                    {company.companyemail}
                                                                                                </a>
                                                                                                {company.companyemailadresse_buchh && (
                                                                                                    <a
                                                                                                        className="text-[12px]"
                                                                                                        href={`mailto: ${company.companyemailadresse_buchh}`}
                                                                                                    >
                                                                                                        {company.companyemailadresse_buchh}
                                                                                                    </a>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                    ) : <p className="text-[10px]">No email provided</p>}

                                                                    {company.companyphone ? (
                                                                        <a className="text-[12px]" href={`sms: ${company.companyphone}`}>SMS Mobile</a>
                                                                    ) : <p className="text-[10px]">No mobile provided</p>}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        }
                    </>}
                {isMobile && this.state.loadMore && tableCompanies.length > 9 ? (
                    <div className="flex justify-center">
                        <PulseLoader color={"#004f9f"} size={10} />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}

export default Companies;

