import { RETRIEVE_USERS } from "./types";

import HttpServices from "../services/httpServices";

export const retrieveUsers = () => async (dispatch) => {
  try {
    const res = await HttpServices.getUsers();

    dispatch({
      type: RETRIEVE_USERS,
      payload: Object.entries(res.data.users),
    });
  } catch (err) {
  }
};
