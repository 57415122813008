class Activity {
  constructor(client) {
    this.client = client;
  }

  get(activityId) {
    return this.client.get(`contacts/activities/${activityId}`);
  }

  list(params = {}) {
    return this.client.list("contacts/activities", params);
  }

  create(activity) {
    delete activity.id;
    return this.client.post("contacts/activities/new", activity);
  }

  edit(activityId, activity) {
    return this.client.patch(
      `contacts/activities/${activityId}/edit`,
      activity
    );
  }

  editOrCreate(activityId, activity) {
    return this.client.put(`contacts/activities/${activityId}/edit`, activity);
  }

  delete(activityId) {
    return this.client.delete(`contacts/activities/${activityId}/delete`);
  }

  getType() {
    return this.client.get(`contacts/activities/list`);
  }

  downloadFile(id, fileId) {
    return this.client.download(`contacts/activities/download/${id}/${fileId}`);
  }

  upload(files) {
    return this.client.post("contacts/activities/upload", files);
  }
}

export default Activity;
