import React, { Component } from 'react'
import DataTable from 'react-data-table-component'
import { isMobile } from 'react-device-detect'
import Moment from "moment"
import { BsSortDown } from 'react-icons/bs'
import { PulseLoader } from 'react-spinners'
import { Link } from 'react-router-dom'

const Activities = (props) => {

    const columns = [
        {
            name: "Zeit",
            selector: (row) =>
                row.activity_meeting_time
                    ? Moment(row.activity_meeting_time).format("DD.MM.YYYY HH:mm")
                    : Moment(row.activity_date_added).format("DD.MM.YYYY HH:mm"),
            sortable: true,
            width: "160px",
        },
        {
            name: "Betreff",
            selector: (row) =>
                row.activity_reference && row.activity_reference.length > 25 ? (
                    <div class="group py-[50px]">
                        {row.activity_reference}
                        <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                {row.activity_reference}
                            </span>
                        </div>
                    </div>
                ) : (
                    row.activity_reference
                ),
            sortable: true,
            width: "200px",
        },
        {
            name: "Aktivität",
            selector: (row) =>
                row.activity !== null &&
                    row.activity &&
                    row.activity.length > 25 ? (
                    <div class="group py-[50px]">
                        {row.activity}
                        <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                {row.activity}
                            </span>
                        </div>
                    </div>
                ) : row.activity !== null ? (
                    row.activity
                ) : (
                    ""
                ),
            sortable: true,
            width: "200px",
        },
        {
            name: "Ort",
            selector: (row) =>
                row.activity_location && row.activity_location.length > 25 ? (
                    <div class="group py-[50px]">
                        {row.activity_location}
                        <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                {row.activity_location}
                            </span>
                        </div>
                    </div>
                ) : (
                    row.activity_location
                ),
            sortable: true,
            width: "200px",
        },
        {
            name: "Art",
            selector: (row) =>
                row.activity_type && row.activity_type.length > 25 ? (
                    <div class="group py-[50px]">
                        {row.activity_type}
                        <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                {row.activity_type}
                            </span>
                        </div>
                    </div>
                ) : (
                    row.activity_type
                ),
            sortable: true,
            width: "200px",
        },
        {
            name: "Fälligkeit",
            selector: (row) =>
                Moment(row.dueDateActivity).format("DD.MM.YYYY HH:mm"),
            sortable: true,
            width: "200px",
        },
        {
            selector: (row) => (
                <Link
                    className="bg-[#004f9f] hover:bg-[#004f9fba] text-white p-[20px] w-fit right-0 absolute translate-y-[-50%]"
                    to={`/aktivitatskarte/${row.id}`}
                >
                    <p>Aktivität anzeigen</p>
                </Link>
            ),
            sortable: false,
        },
    ];
    const { ref, activities, paginationComponentOptions, loading, modifiedData } = props

    return (
        <div className="z-">
            {!isMobile ? <DataTable
                columns={columns}
                data={activities}
                direction="auto"
                fixedHeaderScrollHeight="300px"
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                sortIcon={
                    <BsSortDown
                        sx={{
                            color: "#004f9f",
                            opacity: 1,
                            height: "20px",
                            width: "24px",
                        }}
                    />
                }
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={paginationComponentOptions}
                progressComponent={
                    <div className="nodatacomponent">
                        <PulseLoader color={"#004f9f"} />{" "}
                    </div>
                }
                noDataComponent={
                    <div className="nodatacomponent">
                        Keine Daten vorhanden...
                    </div>
                }
            ></DataTable> :
                <>
                    {loading || modifiedData.lastname === null ? <div className="nodatacomponent">
                        <PulseLoader color={"#004f9f"} size={10} />
                    </div> :
                        <div style={{ paddingBottom:"50px" }} ref={ref}>{
                            activities?.map((item, i) => (
                                <Link to={`/aktivitatskarte/${item.id}`} key={i} className="flex flex-row gap-4 flex-1 px-1 pt-4 pb-2 border-b border-b-[#0000001f]">
                                    <div className="flex flex-col flex-wrap flex-1 gap-[13px]">
                                        <div className="flex flex-row justify-between gap-[10px]">
                                            <div className="flex flex-col gap-1 flex-1">
                                                <p className="text-[12px] text-[#004f9f]">Zeit</p>
                                                <div className="flex flex-wrap items-start gap-1 text-gray-500 flex-1">
                                                    <p className="text-[12px]">{item.activity_meeting_time
                                                        ? Moment(item.activity_meeting_time).format("DD.MM.YYYY")
                                                        : Moment(item.activity_date_added).format("DD.MM.YYYY")}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-1 flex-1">
                                                <p className="text-[12px] text-[#004f9f]">Betreff</p>
                                                <p className="text-gray-500 text-[12px]">{item.activity_reference} </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between gap-[10px]">
                                            <div className="flex flex-col gap-1 flex-1">
                                                <p className="text-[12px] text-[#004f9f]">Aktivität</p>
                                                <p className="text-gray-500 text-[12px]">{item.activity}</p>
                                            </div>
                                            <div className="flex flex-col gap-1 flex-1">
                                                <p className="text-[12px] text-[#004f9f]">Ort</p>
                                                <p className="text-gray-500 text-[12px]">{item.location}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between gap-[10px]">
                                            <div className="flex flex-col gap-1 flex-1">
                                                <p className="text-[12px] text-[#004f9f]">Art</p>
                                                <p className="text-gray-500 text-[12px]">{item.activity_type}</p>
                                            </div>
                                            <div className="flex flex-col gap-1 flex-1">
                                                <p className="text-[12px] text-[#004f9f]">Fälligkeit</p>
                                                <p className="text-gray-500 text-[12px]">{Moment(item.dueDateActivity).format("DD.MM.YYYY")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    }
                </>}
        </div>
    )
}


export default Activities
