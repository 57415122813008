import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import PulseLoader from "react-spinners/PulseLoader";
import { AiOutlineInfoCircle } from "react-icons/ai";

const DeleteModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <button
        className="text-[#aa1124] underline flex items-center gap-[5px] hover:cursor-pointer"
        onClick={() => openModal()}
      >
        <FaTrashAlt size={16} color="#aa1124" />
        Löschen
      </button>

      <div
        id="popup-modal"
        tabindex="-1"
        class={`fixed top-0 left-0 grid place-items-center lg:pl-64 z-[1000000] bg-[#00000030] overflow-x-hidden overflow-y-auto md:inset-0 h-screen w-screen lg:w-full md:h-full lg:h-full ease-in-out duration-300 ${
          isOpen ? "" : "hidden"
        }`}
      >
        <div className="relative w-full h-full max-w-md p-4 md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            <button
              onClick={() => openModal()}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <div className="p-6 text-center ">
              <AiOutlineInfoCircle
                size={70}
                color="orange"
                className="block mx-auto my-6"
              />
              <h3 className="mb-5 text-lg font-normal text-gray-500">
                Sind Sie sicher?
              </h3>
              <button
                onClick={props.delete}
                type="button"
                className="text-white bg-[#aa1124] hover:bg-[#aa11238f] focus:ring-4 focus:outline-none font-medium text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                {props.loading ? <PulseLoader color="#fff" size={8} /> : "Ja"}
              </button>
              <button
                onClick={() => openModal()}
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
              >
                Nein, Schliessen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
