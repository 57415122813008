import React, { Component } from "react";
import DataTable from "react-data-table-component";
import PulseLoader from "react-spinners/PulseLoader";
import Moment from "moment";
import { Link } from "react-router-dom";
import { BsSortDown } from "react-icons/bs";
import { IoMdWarning } from "react-icons/io"

export const columns = [
  {
    name: "Zeit",
    selector: (row) =>
      <>
        {row[1].meetingTime
          ? Moment(row[1].meetingTime).format("DD.MM.YYYY")
          : Moment(row[1].dateAdded).format("DD.MM.YYYY")}
        <div title="The due date of this activity is within 48 hours!" className="alert-condition-show absolute top-[50%] -left-8 -translate-y-[50%]">
          <IoMdWarning size={22} color="red" />
        </div>
      </>,
    sortable: true,
    width: "120px",
  },
  {
    name: "Betreff",
    selector: (row) =>
      row[1].reference && row[1].reference.length > 25 ? (
        <div class="group py-[50px]">
          {row[1].reference}
          <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
              {row[1].reference}
            </span>
          </div>
        </div>
      ) : (
        row[1].reference
      ),
    sortable: true,
    width: "200px",
  },
  {
    name: "Name Kontakt",
    selector: (row) =>
      row[1].leads[0] && row[1].leads[0]?.lastname.length > 25 ? (
        <div class="group py-[50px]">
          {row[1].leads[0] ? row[1].leads[0]?.lastname : ""}
          <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
              {row[1].leads[0] ? row[1].leads[0]?.lastname : ""}
            </span>
          </div>
        </div>
      ) : row[1].leads[0] ? (
        row[1].leads[0]?.lastname
      ) : (
        ""
      ),
    sortable: true,
    width: "200px",
  },
  {
    name: "Vorname Kontakt",
    selector: (row) =>
      row[1].leads[0] && row[1].leads[0]?.firstname.length > 28 ? (
        <div class="group py-[50px]">
          {row[1].leads[0] ? row[1].leads[0]?.firstname : ""}
          <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
              {row[1].leads[0] ? row[1].leads[0]?.firstname : ""}
            </span>
          </div>
        </div>
      ) : row[1].leads[0] ? (
        row[1].leads[0]?.firstname
      ) : (
        ""
      ),
    sortable: true,
    width: "220px",
  },
  {
    name: "Unternehmen",
    selector: (row) =>
      row[1].leads[0] && row[1].leads[0]?.company && row[1].leads[0]?.company.length > 25 ? (
        <div class="group py-[50px]">
          {row[1].leads[0] ? row[1].leads[0]?.company : ""}
          <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
              {row[1].leads[0] ? row[1].leads[0]?.company : ""}
            </span>
          </div>
        </div>
      ) : row[1].leads[0] && row[1].leads[0]?.company ? (
        row[1].leads[0]?.company
      ) : (
        ""
      ),
    sortable: true,
    width: "200px",
  },
  {
    name: "Aktivität",
    selector: (row) =>
      row[1].activity !== null &&
        row[1].activity.name &&
        row[1].activity.name.length > 25 ? (
        <div class="group py-[50px]">
          {row[1].activity.name}
          <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
            <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
              {row[1].activity.name}
            </span>
          </div>
        </div>
      ) : row[1].activity !== null ? (
        row[1].activity.name
      ) : (
        ""
      ),
    sortable: true,
    width: "200px",
  },
  {
    name: "Aktivität",
    selector: (row) =>
      Moment(row[1].dueDateActivity).format("DD.MM.YYYY"),
    sortable: true,
    width: "200px",
  },
  {
    selector: (row) => (
      <Link
        className="bg-[#004f9f] hover:bg-[#004f9fba] text-white p-[20px] w-fit right-0 absolute translate-y-[-50%] mr-2"
        to={`/aktivitatskarte/${row[1].id}`}
      >
        <p>Aktivität anzeigen</p>
      </Link>
    ),
    sortable: false,
  },
];

export class KontaktDataTable extends Component {
  render() {
    return (
      <DataTable
        data={this.props.data}
        columns={columns}
        direction="auto"
        fixedHeaderScrollHeight="300px"
        pagination
        sortIcon={
          <BsSortDown
            sx={{ color: "#004f9f", opacity: 1, height: "20px", width: "24px" }}
          />
        }
        responsive
        subHeaderAlign="right"
        subHeaderWrap
        progressPending={this.props.loading}
        progressComponent={
          <div className="nodatacomponent">
            <PulseLoader color={"#004f9f"} />{" "}
          </div>
        }
        noDataComponent={this.props.nodata}
      />
    );
  }
}
