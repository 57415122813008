class Assets {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("assets", params);
  }

  get(assetId) {
    return this.client.get(`assets/${assetId}`);
  }

  createAssets(params = {})
  {
    this.client.post(`/assets/new`, params)
  }

  editAsset(assetId, params = {})
  {
    this.client.put(`/assets/${assetId}/edit`, params)
  }

  deleteAsset(assetId)
  {
    this.client.delete(`/assets/${assetId}/delete`)
  }
}

export default Assets;
