const qs = require("qs");

class Stats {
  constructor(client) {
    this.client = client;
  }

  getAvailableStatTables(params = {}) {
    return this.client.get(`stats`, params);
  }

  getStatsFromTable() {
    return this.client.get(`stats/${qs.stringify}`);
  }
}

export default Stats;
