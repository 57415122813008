import React from "react";

const Alert = ({ show, message }) => {
  return (
    <div
      className={`fixed z-[9999] top-0 ${
        show ? "right-0" : "right-[-10000px]"
      } transition-all duration-[0.3s] bg-[#004f9f] px-7 py-2 text-white text-xl`}
    >
      {message}
    </div>
  );
};

export default Alert;

