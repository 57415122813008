const qs = require("qs");

class PointTriggers {
  constructor(client) {
    this.client = client;
  }

  get(pointId) {
    return this.client.get(`points/triggers/${pointId}`);
  }

  list(params = {}) {
    return this.client.list("points/triggers", params);
  }

  create(point) {
    delete point.id;
    return this.client.post("points/triggers/new", point);
  }

  edit(pointId, newpoint) {
    return this.client.patch(`points/triggers/${pointId}/edit`, newpoint);
  }

  editOrCreate(pointId, newpoint) {
    return this.client.put(`points/triggers/${pointId}/edit`, newpoint);
  }

  delete(pointId) {
    return this.client.delete(`points/triggers/${pointId}/delete`);
  }

  getPointActionTypes() {
    return this.client.get(`points/triggers/types`);
  }

  deletePointTriggerEvents(id, params = {}) {
    return this.client.delete(
      `points/triggers/${id}/events/delete?${qs.stringify(params)}`
    );
  }
}

export default PointTriggers;
