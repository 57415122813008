import React, { useState, useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import Cookies from "js-cookie";
import Caymland from "caymland-js";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import httpServices from "../../services/httpServices";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loading: false,
      remember: false,
      errors: false,
      token: "",
      showPassword: false,
    };
  }

  async componentWillMount() {
    const remember = Cookies.get("remember");
    if (remember) {
      await this.autoLogin();
    }
  }

  changeUsername = (e) => {
    this.setState({ username: e.target.value });
  };

  changePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  onHandleButton = async () => {
    const token = btoa(this.state.username + ":" + this.state.password);
    if (this.state.remember) {
      Cookies.set("token", token, { path: "/", expires: 30 });
      Cookies.set("remember", this.state.remember, { path: "/", expires: 30 });
    } else {
      let expires = new Date(Date.now() + 60 * 60 * 1000);
      Cookies.set("token", token, { path: "/", expires: expires });
      Cookies.set("remember", this.state.remember, {
        path: "/",
        expires: expires,
      });
    }
    this.setState({ token: token });
  };

  autoLogin = async () => {
    this.setState({ loading: true, errors: false });

    const connector = new Caymland({
      baseUrl: process.env.REACT_APP_PROD_URL || process.env.REACT_APP_DEV_URL,
      auth: this.state.token
    });

    const response = await connector.users.getSelf();
    if (response.status === 200) {
      const res = await connector.users.list(encodeURI("limit=100"));

      localStorage.setItem("user-info", JSON.stringify(response.data));
      localStorage.setItem("usersList", JSON.stringify(Object.entries(res.data.users)))
      this.setState({ loading: false });
      window.location.href = "/";
    } else {
      this.setState({ loading: false, errors: true });
      throw new Error("error2");
    }
  };

  login = async () => {
    await this.onHandleButton();
    await this.autoLogin();
  };

  render() {
    return (
      <div className="z-[9998] px-[20px] lg:px-0 fixed top-0 bg-[#004F9F] w-screen h-screen overflow-hidden flex items-center justify-center">
        <div className="bg-[#fff] shadow border border-gray-200 rounded-lg w-[420px] py-10 px-4 sm:p-6 lg:p-8 ">
          <div className="space-y-6">
            <img
              className="block mx-auto w-[100px]"
              src={require("../../assets/images/logo.png")}
            />
            <div>
              <input
                onKeyDown={(e) => e.key === "Enter" && this.login()}
                type="text"
                name="username"
                onChange={this.changeUsername}
                className="bg-white border no-focus-visible border-[#004f9f] text-gray-900 sm:text-sm  focus:ring-blue-500 block w-full p-4"
                placeholder="Benutzername"
              />
            </div>
            <div className="flex items-center justify-between w-fullbg-[#fff] border border-[#004f9f] ">
              <input
                onKeyDown={(e) => e.key === "Enter" && this.login()}
                type={this.state.showPassword ? "text" : "password"}
                name="password"
                onChange={this.changePassword}
                placeholder="Passwort"
                className="bg-white sm:text-sm focus:border-[#004f9f9] focus:border-blue-500 no-focus-visible w-full p-4"
              />
              {this.state.showPassword ? (
                <div
                  className="translate-x-[-20px] z-[500] hover:cursor-pointer"
                  onClick={() => this.setState({ showPassword: false })}
                >
                  <AiOutlineEyeInvisible size={24} color="#555" />
                </div>
              ) : (
                <div
                  className="translate-x-[-20px] z-[500] hover:cursor-pointer"
                  onClick={() => this.setState({ showPassword: true })}
                >
                  <AiOutlineEye size={24} color="#555" />
                </div>
              )}
            </div>
            <div className="flex items-start">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    onChange={() =>
                      this.setState({ remember: !this.state.remember })
                    }
                    className="bg-[#fff] border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded acccent-[#004f9f]"
                    required=""
                  />
                </div>
                <div className="text-sm ml-3">
                  <label
                    htmlFor="remember"
                    className="font-medium text-[#b7b7b7]"
                  >
                    angemeldet bleiben
                  </label>
                </div>
              </div>
            </div>
            {this.state.errors ? (
              <div
                className="bg-red-100 border border-[#aa1124] text-[#aa1124] px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="text-[18px] font-medium">
                  Falsches Login. Bitte überprüfen Sie Ihre Eingabe.
                </strong>

                <span className="block sm:inline "></span>
              </div>
            ) : (
              ""
            )}
            <button
              onClick={() => this.login()}
              type="submit"
              className="w-100 block ml-auto text-white bg-[#004f9f] hover:bg-[#00509fc8] focus:ring-[#00509fc8] font-medium text-sm px-10 py-2.5 text-center ease-in-out duration-300 hover:shadow"
            >
              {this.state.loading ? (
                <PulseLoader size={8} color="#fff" />
              ) : (
                "Login"
              )}
            </button>
            <div className="text-sm font-medium text-center pt-10 text-gray-500 ">
              Probleme bei der Annmeldung?{" "}
              <a
                href="mailto:marketing@vonroll-infratec.world"
                className="text-blue-700 hover:underline"
              >
                Hier klicken
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
