class Categories {
  constructor(client) {
    this.client = client;
  }

  get(categoryId) {
    return this.client.get(`categories/${categoryId}`);
  }

  list(params = {}) {
    return this.client.list("categories", params);
  }

  create(category) {
    delete category.id;
    return this.client.post("categories/new", category);
  }

  edit(categoryId, newCategory) {
    return this.client.patch(`categories/${categoryId}/edit`, newCategory);
  }

  editOrCreate(categoryId, newCategory) {
    return this.client.put(`categories/${categoryId}/edit`, newCategory);
  }

  delete(categoryId) {
    return this.client.delete(`categories/${categoryId}/delete`);
  }

  getCategoryTypes(params = {}) {
    return this.client.list("categorytypes", params);
  }
}

export default Categories;

