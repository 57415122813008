class MarketingMessages {
  constructor(client) {
    this.client = client;
  }

  get(messageId) {
    return this.client.get(`messages/${messageId}`);
  }

  list(params = {}) {
    return this.client.list("messages", params);
  }

  create(message) {
    delete message.id;
    return this.client.post("messages/new", message);
  }

  edit(messageId, newMessage) {
    return this.client.patch(`messages/${messageId}/edit`, newMessage);
  }

  editOrCreate(messageId, newMessage) {
    return this.client.put(`messages/${messageId}/edit`, newMessage);
  }

  delete(messageId) {
    return this.client.delete(`messages/${messageId}/delete`);
  }
}

export default MarketingMessages;
