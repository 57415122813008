import React from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { AiOutlineCaretDown, AiOutlineCaretRight, AiOutlineSearch } from "react-icons/ai";
import { FiUserPlus } from "react-icons/fi";
import PulseLoader from "react-spinners/PulseLoader";
import { BsSortDown } from "react-icons/bs";
import { isMobile } from "react-device-detect";
import { BiDotsVertical } from 'react-icons/bi'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            error: null,
            contacts: [],
            totalRows: 0,
            rowsPerPage: 10,
            page: 1,
            search: "",
            start: 0,
            loadMore: false,
            showEmails: false,
            showDetails: false,
            sliceNumber: 10,
        };
    }

    handleClickOutside(event) {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.props.onClickOutside && this.props.onClickOutside();
            this.setState({ showDetails: -1, showEmails: false })
        }
    };

    componentDidMount = async () => {
        this.setState({ contacts: this.props.data })
        this.scrollDetection();
        document.addEventListener('click', this.handleClickOutside, true);

    };

    componentWillReceiveProps = (nextProps) => {
        this.setState({ contacts: nextProps.data })
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };

    loadMore = async () => {
        this.setState({ loadMore: true });

        this.setState({
            sliceNumber: this.state.sliceNumber + 10,
            contacts: [
                ...this.props.data.slice(0, this.state.sliceNumber),
            ],
            loadMore: false,
        });
    };

    scrollDetection = () => {
        window.addEventListener("scroll", () => {
            const scrollable = document.documentElement.scrollHeight - window.innerHeight;
            const scrolled = window.scrollY

            if (Math.ceil(scrolled) === scrollable && isMobile && !this.state.loadMore && this.state.contacts.length > 9) {
                this.loadMore();
            }
        })
    }


    render() {
        const { error, contacts, showEmails, sliceNumber } = this.state;

        if (error) {
            return <div>An error occured: {error.message}</div>;
        }

        const paginationComponentOptions = {
            rowsPerPageText: "Zeilen pro Seite",
            rangeSeparatorText: "von",
        };

        const columns = [
            {
                name: "Nachname",
                selector: (row) =>
                    row.lastname &&
                        row.lastname.length > 25 ? (
                        <div class="group py-[50px]">
                            {row.lastname ? row.lastname : ""}
                            <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row.lastname ? row.lastname : ""}
                                </span>
                            </div>
                        </div>
                    ) : row.lastname ? (
                        row.lastname
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "180px",
            },
            {
                name: "Vorname",
                selector: (row) =>
                    row.firstname &&
                        row.firstname.length > 23 ? (
                        <div class="group py-[50px]">
                            {row.firstname ? row.firstname : ""}
                            <div class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row.firstname
                                        ? row.firstname
                                        : ""}
                                </span>
                            </div>
                        </div>
                    ) : row.firstname ? (
                        row.firstname
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "200px",
            },
            {
                name: "Unternehmen",
                selector: (row) =>
                    row.company && row.company.length > 36 ? (
                        <div class="group py-[50px]">
                            {row.company ? row.company : ""}
                            <div class="absolute top-[-10px] left--[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row.company ? row.company : ""}
                                </span>
                            </div>
                        </div>
                    ) : row.company ? (
                        row.company
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "307px",
            },
            {
                name: "Aktionen",
                selector: (row) => (
                    <div className="flex gap-[20px]  items-center">
                    <div class="dropdown">
                      <button className="bg-[#EFF7FF] flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f]">
                        <FaEnvelope size={16} color="#004f9f" />
                        <p>Nachricht senden</p>
                      </button>
                      <div class="dropdown-content" style={{ left:"-1px", width:"calc(100% + 2px)" }}>
                        {row.email ? (
                          <>
                            <div className="group">
                              <div
                                onClick={() =>
                                  this.setState({ showEmails: !showEmails })
                                }
                                className="flex flex-row items-center justify-between cursor-pointer"
                              >
                                E-Mail
                                <AiOutlineCaretRight />
                              </div>
                              <div
                                onMouseLeave={() =>
                                  this.setState({ showEmails: !showEmails })
                                }
                                class={`${showEmails ? "flex" : "hidden"
                                  } flex-col absolute ${row.email &&
                                    row.emailadresse_buchhaltung
                                    ? "top-[-50%]"
                                    : "top-[-10%]"
                                  } left-[100%] bg-[#eef7ff] p-[10px] gap-2 rounded-[10px] `}
                              >
                                {row.email && (
                                  <>
                                    <a
                                      className=""
                                      href={`mailto: ${row.email}`}
                                    >
                                      {row.email}
                                    </a>
                                    {row.emailadresse_buchhaltung && (
                                      <a
                                        className=""
                                        href={`mailto: ${row.emailadresse_buchhaltung}`}
                                      >
                                        {row.emailadresse_buchhaltung}
                                      </a>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : <>No email provided</>}
                        {!row.email &&
                          !row.mobile &&
                          <br />}
                        {row.mobile ? (
                          <a href={`sms: ${row.mobile}`}>SMS Mobile</a>
                        ) : <>No mobile provided</>}
                      </div>
                    </div>
                    {row.phone ||
                      (row.mobile && (
                        <button
                          className="bg-[#EFF7FF] absolute flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f] translate-x-[173px]"
                          to="#"
                        >
                          <FaPhoneAlt size={16} color="#004f9f" />
                          <a
                            href={`tel: ${row.phone
                              ? row.phone
                              : row.mobile
                              }`}
                          >
                            Anrufen
                          </a>
                        </button>
                      ))}
                  </div>
                ),
                sortable: false,
                width: "380px",
            },

            {
                selector: (row) => (
                    <Link
                        to={`/kontaktkarte/${row.id}`}
                        className="bg-[#004f9f] hover:bg-[#004f9fba] text-white p-[20px] w-fit right-0 absolute translate-y-[-50%]"
                    >
                        <p>Kontakt anzeigen</p>
                    </Link>
                ),
                sortable: false,
            },
        ];

        return (
            <div className="table-container" style={{ zIndex: "1" }}>
                {!isMobile ? <DataTable
                    columns={columns}
                    data={contacts}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    progressPending={this.props.loading}
                    pagination={isMobile ? false : true}
                    sortIcon={
                        <BsSortDown
                            sx={{
                                color: "#004f9f",
                                opacity: 1,
                                height: "20px",
                                width: "24px",
                            }}
                        />
                    }
                    paginationServer
                    paginationComponentOptions={paginationComponentOptions}
                    progressComponent={
                        <div className="nodatacomponent">
                            <PulseLoader color={"#004f9f"} />{" "}
                        </div>
                    }
                    noDataComponent={
                        <div className="nodatacomponent">
                            Keine Daten vorhanden...
                        </div>
                    }
                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                ></DataTable> :
                    <>
                        {this.state.loading ? <div className="nodatacomponent">
                            <PulseLoader color={"#004f9f"} size={10} />
                        </div> :
                            <div ref={this.ref} style={{ paddingBottom:"50px" }}>{
                                contacts.map((contact, i) => (
                                    contact.firstname && (
                                        <div key={i} className="flex flex-row gap-4 px-1 pt-4 pb-2 border-b border-b-[#0000001f]">
                                            <Link to={`/kontaktkarte/${contact.id}`} className="flex flex-col gap-1 flex-wrap flex-1 max-w-[70%]">
                                                <p className="text-[12px] text-[#004f9f]">Name und Vorname</p>
                                                <div className="flex flex-wrap items-start gap-1 text-gray-500">
                                                    <p className="text-[12px]">{contact.lastname}</p>
                                                    <p className="text-[12px]">{contact.firstname}</p>
                                                </div>
                                            </Link>
                                            <div className="flex flex-col gap-1 flex-1">
                                                <p className="text-[12px] text-[#004f9f]">Unternehmen</p>
                                                <p className="text-gray-500 text-[12px]">{contact.company && contact.company} </p>
                                            </div>
                                            <div className="relative w-fit">
                                                <div className="w-fit flex items-center">
                                                    <BiDotsVertical onClick={() => this.setState({ showDetails: this.state.showDetails === i ? -1 : i, showEmails: this.state.showDetails === i && false })} size={24} color="#004f9f" />
                                                </div>
                                                {this.state.showDetails === i && (
                                                    <div class={`dropdown-mobile right-0 w-[140px!important] border-2 border-[#004f9f] z-[99999999]`}>
                                                        {this.state.showDetails === i && (
                                                            <>
                                                                <button className="bg-[#EFF7FF] flex items-center gap-[5px] py-2 px-2.5 rounded-lg text-[#004f9f]">
                                                                    <FaEnvelope size={16} color="#004f9f" />
                                                                    <p className="text-[12px]">Nachricht senden</p>
                                                                </button>
                                                                <div class="dropdown-content-mobile">
                                                                    {contact.email ? (
                                                                        <>
                                                                            <div className="group">
                                                                                <div
                                                                                    onClick={() =>
                                                                                        this.setState({ showEmails: !showEmails })
                                                                                    }
                                                                                    className="flex relative flex-row items-center justify-between cursor-pointer text-[12px]"
                                                                                >
                                                                                    E-Mail
                                                                                    <AiOutlineCaretDown />
                                                                                    <div
                                                                                        onMouseLeave={() =>
                                                                                            this.setState({ showEmails: !showEmails })
                                                                                        }
                                                                                        class={`${showEmails ? "flex" : "hidden"
                                                                                            } flex-col absolute top-[120%] min-w-[150px] right-[-20px] bg-[#eef7ff] p-[10px] gap-2 `}
                                                                                    >
                                                                                        {contact.email && (
                                                                                            <>
                                                                                                <a
                                                                                                    className="text-[12px]"
                                                                                                    href={`mailto: ${contact.email}`}
                                                                                                >
                                                                                                    {contact.email}
                                                                                                </a>
                                                                                                {contact.emailadresse_buchhaltung && (
                                                                                                    <a
                                                                                                        className="text-[12px]"
                                                                                                        href={`mailto: ${contact.emailadresse_buchhaltung}`}
                                                                                                    >
                                                                                                        {contact.emailadresse_buchhaltung}
                                                                                                    </a>
                                                                                                )}

                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                    ) : <p className="text-[10px]">No email provided</p>}

                                                                    {contact.mobile ? (
                                                                        <a className="text-[12px]" href={`sms: ${contact.mobile}`}>SMS Mobile</a>
                                                                    ) : <p className="text-[10px]">No mobile provided</p>}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        }

                    </>}
                {
                    isMobile && this.state.loadMore && contacts.length > 9 ? (
                        <div className="flex justify-center">
                            <PulseLoader color={"#004f9f"} size={10} />
                        </div>
                    ) : (
                        <></>
                    )
                }
            </div >
        );
    }
}

export default Contacts;

