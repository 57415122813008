class Files {
  constructor(client) {
    this.client = client;
  }

  get(object = "images") {
    this.client.get(`files/${object}`);
  }

  create(dir) {
    this.client.post(`files/${dir}/new`);
  }

  delete(dir, file) {
    this.client.delete(`files/${dir}/${file}/delete`);
  }
}

export default Files;
