import React, { useState, useEffect } from "react";
import connector from "../../connector";

import { Link } from "react-router-dom";

import { AiOutlineSearch } from "react-icons/ai";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      activities: [],
      companies: [],
      search: "",
      showSearch: false,
    };
  }

  componentDidMount = async () => {
    await this.loadAllContacts();
    await this.loadAllActivities();
    await this.loadAllCompanies();
  };

  loadAllContacts = async (page = 1) => {
    let url = `where[0][col]=date_identified&where[0][expr]=isNotNull`;
    if (this.state.search.length) {
      url += "&search=" + this.state.search;
    }
    await this.fetchContacts(url, page);
  };

  loadAllCompanies = async (page = 1) => {
    let url = ``;
    if (this.state.search.length) {
      url += "&search=" + this.state.search;
    }
    await this.fetchCompanies(url, page);
    this.setState({ own: false });
  };

  loadAllActivities = async (page = 1) => {
    let url = `limit=10&start=0`;
    if (this.state.search.length) {
      url += "&search=" + this.state.search;
    }
    await this.fetchActivities(url, page);
    this.setState({ own: false });
  };

  fetchActivities = async (url, page = 1) => {
    this.setState({ loading: true });
    let start = page === 1 ? 0 : (page - 1) * this.state.rowsPerPage;
    let query = `limit=${this.state.rowsPerPage}&start=${start}`;

    if (url) {
      query += "&" + url;
    }

    let response = await connector.activities.list(encodeURI(query));

    this.setState({
      activities:
        Object.entries(response.data.activities) ||
        Object.entries(response.data.activity),
      totalRows: response.data.total,
      loading: false,
    });
  };

  fetchCompanies = async (url, page = 1) => {
    this.setState({ loading: true });
    let start = page === 1 ? 0 : (page - 1) * this.state.rowsPerPage;
    let query = ``;

    if (url) {
      query += "&" + url;
    }

    let response = await connector.companies.list(encodeURI(query));

    this.setState({
      companies:
        Object.entries(response.data.companies) ||
        Object.entries(response.data.company),
      totalRows: response.data.total,
      loading: false,
    });
  };

  fetchContacts = async (url) => {
    this.setState({ loading: true });
    let query = ``;

    if (url) {
      query += "&" + url;
    }

    let response = await connector.contacts.list(encodeURI(query));

    this.setState({
      contacts:
        Object.entries(response.data.contacts) ||
        Object.entries(response.data.contact),
    });
  };

  searchContact = async (e) => {
    this.setState({
      showSearch: e.target.value.length < 1 ? false : true,
      data: [],
      search: e.target.value.length < 1 ? "" : e.target.value,
    });
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      await this.loadAllContacts();
      await this.loadAllActivities();
      await this.loadAllCompanies();
    }
  };

  render() {
    const { contacts, activities, companies } = this.state;
    return (
      <div>
        <div className="block mx-auto w-[300px] md:w-[400px] lg:w-[500px] h-[32px]">
          <AiOutlineSearch
            color="#004f9f"
            className="absolute translate-x-[10px] translate-y-[13px] lg:translate-y-[9px]"
            size={18}
          />
          <input
            type="text"
            name="search"
            onChange={this.searchContact}
            className="placeholder:text-[#004F9F] placeholder:text-[12px] shadow bg-[#fff] border-gray-400 text-gray-900 sm:text-sm rounded-[24px] focus:ring-blue-500 focus:border-blue-500 block w-[300px] md:w-[400px] lg:w-[500px] px-[40px] py-2 searchinput"
            placeholder="Suche Aktivitäten, Kontakte, Unternehmen"
          />
        </div>

        <ul
          className={`searchlist ${
            !this.state.showSearch ? "hidden" : ""
          } py-[10px]`}
        >
          {contacts.length > 0 ? (
            <span className="text-[16px] font-semibold text-[#004f9f] border-b-[1px] border-[#004f9f] w-full m-[10px]">
              Kontakte
            </span>
          ) : (
            ""
          )}

          {contacts.slice(0, 15).map((contact) => (
            <li key={contact[1].id}>
              <Link to={`/kontaktkarte/${contact[1].id}`}>
                {contact[1].fields ? contact[1].fields.all.firstname : ""}{" "}
                {contact[1].fields ? contact[1].fields.all.lastname : ""} -{" "}
                {contact[1].fields ? contact[1].fields.all.email : ""}
              </Link>
            </li>
          ))}
          {JSON.parse(localStorage.getItem("isAuthorized")) === true ? (
            <>
              {activities.length > 0 ? (
                <span className="text-[16px] font-semibold text-[#004f9f] border-b-[1px] border-[#004f9f] w-full m-[10px]">
                  Aktivitäten
                </span>
              ) : (
                ""
              )}
              {activities.slice(0, 15).map((activity) => (
                <li key={activity[1].id}>
                  <Link to={`/aktivitatskarte/${activity[1].id}`}>
                    {activity[1].reference}
                  </Link>
                </li>
              ))}{" "}
            </>
          ) : (
            <></>
          )}

          {companies.length > 0 ? (
            <span className="text-[16px] font-semibold text-[#004f9f] border-b-[1px] border-[#004f9f] w-full m-[10px]">
              Unternehmen
            </span>
          ) : (
            ""
          )}

          {companies.slice(0, 15).map((company) => (
            <li key={company[1].id}>
              <Link to={`/unternehmenskarte/${company[1].id}`}>
                {company[1].fields.all.companyname}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Search;
