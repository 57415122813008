import React from "react";
import {columns} from "../../../reusableComponents/DataTables/APIDataTable";
import DataTable from "react-data-table-component";
import {BsPencil, BsSortDown} from "react-icons/bs";
import {FiExternalLink} from "react-icons/fi";
import {useNavigate, useParams} from "react-router-dom";
import {AiOutlineEye} from "react-icons/ai";
import DeleteModal from "../../../reusableComponents/Modals/DeleteModal";
import {connect} from "react-redux";

import EditModal from "../../../reusableComponents/Modals/EditModal";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {Link} from "react-router-dom";
import {MdOutlineAddTask} from "react-icons/md";
import {retrieveUsers} from "../../../actions/usersAction";

import connector from "../../../connector";
import localConnector from "../../../connector";
import customConnector from "../../../connector";
import PulseLoader from "react-spinners/PulseLoader";
import Datetime from "react-datetime";
import Moment from "moment";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {FaRegPaperPlane} from "react-icons/fa";

import {countries, countriesLocale, doi} from "../../../data";
import {isMobile} from "react-device-detect";
import Alert from "../../../reusableComponents/Alert";
import Activities from "./tables/Activities";
import Companies from "./tables/Companies";

class ContactCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modifiedData: {
                image: null,
                lastname: null,
                firstname: null,
                company: null,
                owner: null,
                anrede: null,
                title: null,
                umgangsform: null,
                position: null,
                birthday: null,
                sprache: null,
                interests: null,
                doi: false,
                address1: null,
                postfach: null,
                zipcode: null,
                city: null,
                country: null,
                mobile: null,
                phone: null,
                email: null,
                emailadresse_buchhaltung: null,
                linkedin: null,
                notizen: null,
                contact_status: null,
                origin: null,
            },
            staticData: {},
            loading: false,
            cardLoading: true,
            emailLoading: false,
            edit: false,
            errors: null,
            contactDetails: [],
            contactFields: [],
            companies: [],
            tableCompanies: [],
            totalRows: 0,
            rowsPerPage: 10,
            search: "",
            own: true,
            fields: [],
            showAlert: false,
            message: "",
            activityTable: true,
            companyTable: false,
            settings: null,
            usersList: [],
        };
    }

    componentDidMount = async () => {
        await this.loadOwnActivities();
        await this.loadCompanies();
        // this.props.retrieveUsers();
        this.setState({usersList: JSON.parse(localStorage.getItem("usersList"))})
        this.setState({settings: JSON.parse(localStorage.getItem("settings")), cardLoading: true})

        const {id} = this.props.params;
        let response = await connector.contacts.get(id);
        const response3 = await connector.fields.list("contact", "limit=100");
        this.setState({
            contactDetails: response.data.contact,
            contactFields: response.data.contact?.fields.all,
            modifiedData: response.data.contact?.fields.all,
            staticData: response.data.contact?.fields.all,
            fields: Object.values(response3.data.fields),
        });
        if (response.data.contact.owner) {
            this.state.modifiedData.owner = response.data.contact.owner.id;
        }
        this.setState({cardLoading: false})
    };


    loadCompanies = async (url, page = 1) => {
        this.setState({loading: true});
        const {id} = this.props.params;

        let response = await localConnector.contacts.getCompanies(id);

        this.setState({
            companies:
                Object.entries(response.data.companies) ||
                Object.entries(response.data.company),
            totalRows: response.data.total,
            loading: false,
        });
    }

    loadOwnActivities = async (page = 1) => {
        const {id} = this.props.params;
        const userDetails = JSON.parse(localStorage.getItem("user-info"));
        await this.fetchActivities(`owner_id=${userDetails.id}&next_user_id=${userDetails.id}`);
        this.setState({own: true});
    };

    loadAllActivities = async (page = 1) => {
        await this.fetchActivities("");
        this.setState({own: false});
    };

    fetchActivities = async (url, page = 1) => {
        const {id} = this.props.params
        this.setState({loading: true});

        let response = await localConnector.contacts.getActivitiesAndCompanies(id, encodeURI(url));

        this.setState({
            activities: response.data.activities || response.data.activity,
            tableCompanies: response.data.compainies || response.data.company,
            totalRows: response.data.total,
            loading: false,
        });
    };

    handlePageChange = async (page) => {
        this.state.own
            ? await this.loadOwnActivities(page)
            : await this.loadAllActivities(page);
    };

    handleTitle = (value) => {
        this.state.modifiedData.title = value.value;
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({rowsPerPage: newPerPage});
        this.state.own
            ? await this.loadOwnActivities(page)
            : await this.loadAllActivities(page);
    };

    handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            alert(rejectedFiles[0].errors.map((x) => x.code));
        }
        if (files && files.length > 0) {
            this.state.modifiedData.image = files[0];
        }
    };

    handleInteressen = (value) => {
        this.state.modifiedData.interests = value.map((x) => x.value);
    };

    changeMode = () => {
        this.setState({
            edit: !this.state.edit,
            modifiedData: this.state.modifiedData,
        });
    };

    handleInputChange = ({target: {name, value}}) => {
        this.setState((prev) => ({
            ...prev,
            modifiedData: {
                ...prev.modifiedData,
                [name]: value,
            },
        }));
    };

    handleSelectChange = (value) => {
        this.state.modifiedData.title = value.value;
    };

    handleCountryChange = (value) => {
        if (value === null) {
            this.state.modifiedData.country = null;
        } else {
            this.state.modifiedData.country = value.value;
        }
    };

    handlePhoneChange = (value) => {
        this.state.modifiedData.phone = value;
    };

    handleMobileChange = (value) => {
        this.state.modifiedData.mobile = value;
    };

    handleSpracheChange = (value) => {
        if (value === null) {
            this.state.modifiedData.preferred_locale = null;
        } else {
            this.state.modifiedData.preferred_locale = value.value;
        }
    };

    handleAnrede = (value) => {
        if (value.value === "-") {
            this.state.modifiedData.anrede = null;
        }
        this.state.modifiedData.anrede = value.value;
        this.setState({modifiedData: this.state.modifiedData});
    };

    handleUmgangsform = (value) => {
        this.state.modifiedData.umgangsform = value.value;
    };

    handleStatusChange = (value) => {
        this.state.modifiedData.contact_status = value.value;
    };

    handleDoi = (value) => {
        this.state.modifiedData.doi = value.target.checked ? 1 : 0;
        this.setState({modifiedData: this.state.modifiedData});
    };

    handlePosition = (value) => {
        this.state.modifiedData.position = value.value;
    };

    loadOptions = async (inputValue) => {
        const results = await connector.companies.list(
            encodeURI(`search=${inputValue}`)
        );

        let arr = [{
            fields: {
                all: {
                    companyname: "Löschen..."
                }
            }
        }]
        return arr.concat(Object.values(results.data.companies))
    };

    handleCompanyChange = (value) => {
        if (value === null || value.fields.all.companyname === "Löschen...") {
            this.state.modifiedData.company = null;
        } else {
            this.state.modifiedData.company = value;
            if (value) {
                this.state.modifiedData.company = value.fields.all.companyname;
            }
        }
        this.setState({modifiedData: this.state.modifiedData});
    };

    setValues = () => {
        this.state.modifiedData.company
            ? (this.state.modifiedData.company = this.state.modifiedData.company)
            : (this.state.modifiedData.company = null);
    };

    handleSubmit = async () => {
        this.setState({loading: true});
        const id = this.props.params;
        if (this.state.modifiedData.anrede === null) {
            this.state.modifiedData.anrede = null;
        }
        this.setValues();
        let response = await connector.contacts.edit(
            id.id,
            this.state.modifiedData
        );
        if (response.status === 200) {
            this.setState({loading: false});
        } else {
            this.setState({loading: false, errors: true});
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    loadOwners = async (inputValue) => {
        const results = await connector.contacts.list(
            encodeURI(`search=${inputValue}`)
        );
        return Object.values(results.data.contacts);
    };

    handleOwnerChange = (value) => {
        if (value.label && value.label === "Löschen...") {
            this.state.modifiedData.owner = null;
        } else {
            this.state.modifiedData.owner = value.id;
        }
    };

    deleteFunc = async () => {
        this.setState({loading: true});
        const navigate = this.props.navigate;
        const {id} = this.props.params;
        await await connector.contacts.delete(id);
        this.setState({loading: false});
        navigate("/kontakte");
    };

    reload = () => {
        window.location.reload(false);
    };

    handleEmailSend = async () => {
        this.setState({emailLoading: true});
        try {
            let response = await customConnector.contacts.sendEmail(
                this.state.modifiedData.id,
                this.state.settings?.double_opt_in_template
            );
            if (response.status === 200 || response.status === 201) {
                this.setState({showAlert: true, message: "Email sent successfully."});
                setTimeout(() => {
                    this.setState({showAlert: false});
                }, 5000);
            } else {
                this.setState({
                    showAlert: true,
                    message: "There was and error sending the email.",
                });
                setTimeout(() => {
                    this.setState({showAlert: false});
                }, 5000);
            }
            this.setState({emailLoading: false});
        } catch (e) {
            this.setState({emailLoading: false});
        }
    };

    changeTable = (type) => {
        if (type === "activities") {
            this.setState({activityTable: true});
            this.setState({companyTable: false});
        } else {
            this.setState({activityTable: false});
            this.setState({companyTable: true});
        }
    };

    render() {
        const {
            error,
            edit,
            activities,
            tableCompanies,
            contactDetails,
            contactFields,
            modifiedData,
            own,
            companies,
            fields,
            emailLoading,
            showAlert,
            message,
            activityTable,
            companyTable
        } = this.state;

        const userDetails = JSON.parse(localStorage.getItem("user-info"));

        const {contactPermissions, userId} = this.props;

        let {id} = this.props.params;

        let companyName = companies?.map((x) => x[1]);

        let filteredCompany = companyName?.filter(
            (x) => x.is_primary == 1
        );

        let filteredID = filteredCompany[0]?.id

        let notFilteredInterests =
            typeof this.state.modifiedData?.interests === "string"
                ? this.state.modifiedData.interests.split(/[|]/)
                : "";

        let filteredInterests = Object.entries(notFilteredInterests).map((x) => {
            return {
                label: x[1],
                value: x[1],
            };
        });

        const {users} = this.props;
        const {usersList} = this.state
        const loadusers = usersList.map((x) => x[1]);
        let inputProps = {
            placeholder: modifiedData?.birthday
                ? Moment(modifiedData.birthday).format("MM-DD")
                : Moment().format("MM-DD"),
        };

        let interessen = Object.values(fields).filter(
            (x) => x.alias === "interests"
        );

        let position = Object.values(fields).filter((x) => x.alias === "funktion");

        let title = Object.values(fields).filter((x) => x.alias === "title");

        let contactstatus = Object.values(fields).filter(
            (x) => x.alias === "contact_status"
        );

        let umgangsform = Object.values(fields).filter(
            (x) => x.alias === "umgangsform"
        );
        let anrede = Object.values(fields).filter((x) => x.alias === "anrede");
        let funktion = Object.values(fields).filter((x) => x.alias === "funktion");

        let umgangsformFields = umgangsform.map((x) => x.properties.list);

        let titleFields = title.map((x) => x.properties.list);

        let anredeFields = anrede.map((x) => x.properties.list);

        let interessenFields = interessen.map((x) => x.properties.list);

        let contactstatusFields = contactstatus.map((x) => x.properties.list);

        let funktionFields = funktion.map((x) => x.properties.list);

        const paginationComponentOptions = {
            rowsPerPageText: "Zeilen pro Seite",
            rangeSeparatorText: "von",
        };

        let localeName = "";

        countriesLocale.map((x) =>
            x.value === this.state.modifiedData?.preferred_locale
                ? (localeName = x.label)
                : ""
        );

        if (error) {
            return <div>An error occured: {error.message}</div>;
        }

        return (
            <div
                className="main-section"
                style={{
                    left: isMobile ? "0" : "15rem",
                    width: isMobile ? "100%" : "calc(100vw - 13rem)",
                    padding: isMobile ? "0" : "0 60px 50px 60px",
                }}
            >
                <Alert show={showAlert} message={message}/>
                <div className="w-[100%] lg:pt-[0]">
                    <div
                        className={`p-[20px] lg:p-[30px] bg-transparent w-full lg:w-full block mx-auto relative ${this.state.cardLoading
                            ? "bg-[#00000029]"
                            : "bg-transparent"
                        } lg:mt-[20px] rounded-md`}
                    >
                        <div className="relative">
                            {this.state.cardLoading ? (
                                <div
                                    className="absolute w-fit left-[50%] top-[10px] lg:top-0 lg:left-[50%] lg:translate-x-[50%]">
                                    <PulseLoader size={10} color="#004f9f"/>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div
                            className={`relative ease-in-out duration-300 top-[20px] mb-14 lg:top-[30px] flex items-center lg:block ${edit ? "mt-[20px]" : "mt-[-30px]"
                            }`}
                        >
                            <a
                                href={edit ? `#` : `/kontaktkarte/${contactDetails?.owner?.id}`}
                                target={edit ? "" : "_blank"}
                                className="flex flex-col w-fit items-start lg:items-center lg:flex-row lg:gap-[10px] w-100  justify-between lg:justify-start"
                            >
                                <div className="flex items-center gap-3">
                                    <img
                                        src={require("../../../assets/images/viewKontaktinhaber.png")}
                                        className="w-[25px] lg:w-[35px] object-contain relative top-[-3px] lg:top-0"
                                    />
                                    <h2 className="text-[#004f9f] text-[14px] lg:text-[16px] font-[500] pb-[10px] lg:pb-0 w-full lg:w-[112px]">
                                        Kontaktinhaber:
                                    </h2>
                                </div>
                                {edit ? (
                                    <>
                                        {userDetails.role.id === 1 ||
                                        userDetails.role.name === "Prüfer" ? (
                                            <>
                                                <Select
                                                    isSearchable={true}
                                                    classNamePrefix="transparent-bg"
                                                    getOptionLabel={(e) =>
                                                        `${e.label ? e.label : ""} ${e.firstName ? e.firstName : ""
                                                        } ${e.lastName ? e.lastName : ""} (${e.email ? e.email : ""
                                                        })`
                                                    }
                                                    getOptionValue={(e) => e.id}
                                                    name="owner"
                                                    options={loadusers}
                                                    onChange={this.handleOwnerChange}
                                                    placeholder={
                                                        contactDetails.owner
                                                            ? contactDetails.owner.firstName +
                                                            " " +
                                                            contactDetails.owner.lastName +
                                                            " "
                                                            : "Auswählen..."
                                                    }
                                                    className="w-full lg:w-[250px]"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {(contactPermissions[0] &&
                                                    contactPermissions[0].includes("editother")) ||
                                                contactPermissions[0].includes("full") ? (
                                                    <Select
                                                        isSearchable={true}
                                                        getOptionLabel={(e) =>
                                                            `${e.label ? e.label : ""} ${e.firstName ? e.firstName : ""
                                                            } ${e.lastName ? e.lastName : ""} - ${e.email ? e.email : ""
                                                            }`
                                                        }
                                                        getOptionValue={(e) => e.id}
                                                        name="owner"
                                                        options={loadusers}
                                                        onChange={this.handleOwnerChange}
                                                        placeholder={
                                                            contactDetails.owner
                                                                ? contactDetails.owner.firstName +
                                                                " " +
                                                                contactDetails.owner.lastName +
                                                                " "
                                                                : "Auswählen..."
                                                        }
                                                        className="w-full lg:w-[200px]"
                                                    />
                                                ) : (
                                                    <>
                                                        <p className="text-[#004f9f] text-[13px] lg:text-[16px] font-[500] lg:ml-[-5px]">
                                                            {contactDetails.owner
                                                                ? contactDetails.owner.lastName +
                                                                " " +
                                                                contactDetails.owner.firstName
                                                                : "-"}
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <p className="text-[#004f9f] text-[13px] lg:text-[16px] font-[500] lg:ml-[-5px]">
                                        {contactDetails?.owner
                                            ? contactDetails.owner.lastName +
                                            " " +
                                            contactDetails.owner.firstName
                                            : "-"}
                                    </p>
                                )}
                            </a>
                        </div>

                        {edit ? (
                            <div
                                className={`translate-y-[38px] mt-[-38px] z-[900] w-fit p-[5px] hover:cursor-pointer ${edit && "absolute top-1 left-1 lg:top-5 lg:left-5"
                                }`}
                                onClick={() => this.setState({edit: false})}
                            >
                                <AiOutlineEye size={30} color="#004f9f"/>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="flex flex-wrap justify-between items-center pb-[20px]">
                            <div
                                className={`flex flex-wrap items-center w-full justify-between lg:justify-between border-b-[1px] border-[#004f9f] ${edit && "pb-2"
                                }`}
                            >
                                <div
                                    className={`flex lg:flex-nowrap gap-5  flex-1 justify-between ${edit ? "w-full gap-10" : "w-[80%] lg:gap-0"
                                    }`}
                                >
                                    <div
                                        className={`flex flex-row justify-center lg:justify-between ${edit ? "gap-7" : "w-[50%] gap-5 lg:gap-0 lg:w-[20%]"
                                        }`}
                                    >
                                        <div
                                            className={`flex flex-col ${edit ? "w-full lg:w-fit" : "w-full lg:w-[fit-content]"
                                            }`}
                                        >
                                            <p className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]">
                                                Name
                                            </p>
                                            {edit ? (
                                                <input
                                                    type="text"
                                                    required
                                                    name="lastname"
                                                    onChange={this.handleInputChange}
                                                    value={modifiedData.lastname}
                                                    className="placeholder:text-gray-500 placeholder:text-[12px] lg placeholder:text-[12px]:lg:placeholder:text-[14px] bg-transparent border-b border-[#d7d7d7] text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-none focus:outline-none block w-full lg:w-[180px] px-[8px] py-1.5"
                                                    placeholder="Name"
                                                />
                                            ) : (
                                                <p className="text-gray-500 break-all text-[13px] lg:text-[16px] font-semibold ml-[2px]">
                                                    {contactFields.lastname
                                                        ? contactFields.lastname
                                                        : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={`flex flex-col ${edit ? "w-full lg:w-fit" : "w-full lg:w-[fit-content]"
                                            }`}
                                        >
                                            <p className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]">
                                                Vorname
                                            </p>
                                            {edit ? (
                                                <input
                                                    type="text"
                                                    name="firstname"
                                                    onChange={this.handleInputChange}
                                                    value={modifiedData.firstname}
                                                    className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] bg-transparent border-b border-[#d7d7d7] text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-none focus:outline-none block w-full lg:w-[180px] px-[8px] py-1.5"
                                                    placeholder="Vorname"
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] font-semibold ml-[2px]">
                                                    {contactFields.firstname
                                                        ? contactFields.firstname
                                                        : "-"}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        className={`flex flex-col w-full  ${edit ? "lg:w-[35%]" : "w-[33%] lg:w-[30%]"
                                        }`}
                                    >
                                        <p className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]">
                                            Unternehmen
                                        </p>
                                        {edit ? (
                                            <>
                                                <AsyncSelect
                                                    classNamePrefix="transparent-bg"
                                                    cacheOptions
                                                    defaultOptions
                                                    getOptionLabel={(e) =>
                                                        e.fields ? e.fields.all.companyname : ""
                                                    }
                                                    getOptionValue={(e) =>
                                                        e.fields ? e.fields.all.companyname : ""
                                                    }
                                                    loadOptions={this.loadOptions}
                                                    onChange={this.handleCompanyChange}
                                                    placeholder={contactFields.company ?? "Auswählen..."}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {contactFields.company ? (
                                                    <a
                                                        href={`/unternehmenskarte/${filteredID}`}
                                                        target="_blank"
                                                        className="flex items-center gap-[10px] hover:underline text-gray-500 hover:text-[#004f9f]"
                                                        style={{wordBreak: "break-all"}}
                                                    >
                                                        <p
                                                            className="text-[13px] lg:text-[16px] font-semibold ml-[2px]"
                                                            style={{wordBreak: "break-all"}}
                                                        >
                                                            {contactFields.company
                                                                ? contactFields.company
                                                                : "-"}
                                                        </p>
                                                        <FiExternalLink size={16} color="#004f9f"/>
                                                    </a>
                                                ) : (
                                                    <p className="text-gray-500 text-[14px] lg:text-[16px] font-semibold">
                                                        -
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div
                                        className={`flex flex-row   ${edit
                                            ? "flex-1 justify-end w-[100%]"
                                            : "justify-between w-[20%] lg:w-[30%]"
                                        }`}
                                    >
                                        <div
                                            className={`flex flex-col ${edit ? "lg:w-full" : "w-[full] lg:w-fit"
                                            }`}
                                        >
                                            <p className="text-[#004f9f] break-all text-[12px] lg:text-[14px] font-[400]">
                                                Status
                                            </p>
                                            {edit ? (
                                                <Select
                                                    isSearchable={true}
                                                    classNamePrefix="transparent-bg"
                                                    name="contact_status"
                                                    getOptionLabel={(e) => `${e.label} `}
                                                    getOptionValue={(e) => e.value}
                                                    options={contactstatusFields[0]}
                                                    onChange={this.handleStatusChange}
                                                    placeholder={
                                                        modifiedData.contact_status
                                                            ? modifiedData.contact_status
                                                            : "Auswählen..."
                                                    }
                                                />
                                            ) : (
                                                <p className="text-gray-500 break-all text-[12px] lg:text-[16px] font-semibold ml-[3px] pb-[20px] lg:pb-0">
                                                    {modifiedData.contact_status
                                                        ? modifiedData.contact_status
                                                        : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={`flex w-100 justify-end lg:justify-end items-center gap-[35px] pb-[20px] ${edit
                                                ? "absolute top-1 right-1 lg:top-5 lg:right-5 w-fit"
                                                : "w-[20%] absolute top-2 right-2 lg:relative lg:top-0 lg:right-0"
                                            } `}
                                        >
                                            {edit ? (
                                                <>
                                                    <DeleteModal
                                                        delete={this.deleteFunc}
                                                        loading={this.state.loading}
                                                    />
                                                    <div className="mobile-tablet-none w-full">
                                                        <EditModal
                                                            reload={this.reload}
                                                            submit={this.handleSubmit}
                                                            loading={this.state.loading}
                                                            errors={this.state.errors}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {(userDetails && userDetails.role.id === 1) ||
                                                    (userDetails &&
                                                        userDetails.role.name === "Prüfer") ? (
                                                        <>
                                                            <BsPencil
                                                                onClick={() => this.changeMode()}
                                                                size={isMobile ? 20 : 35}
                                                                color="#004f9f"
                                                                className="hover:cursor-pointer"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {contactPermissions && contactPermissions[0] ? (
                                                                <>
                                                                    {contactPermissions[0].includes("editown") &&
                                                                    userDetails.id ===
                                                                    contactDetails.owner?.id ? (
                                                                        <BsPencil
                                                                            onClick={() => this.changeMode()}
                                                                            size={35}
                                                                            color="#004f9f"
                                                                            className="hover:cursor-pointer"
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            {contactPermissions[0].includes(
                                                                                "editother"
                                                                            ) ||
                                                                            contactPermissions[0].includes("full") ? (
                                                                                <BsPencil
                                                                                    onClick={() => this.changeMode()}
                                                                                    size={35}
                                                                                    color="#004f9f"
                                                                                    className="hover:cursor-pointer"
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between items-center pb-[20px]">
                            <div className="lex flex-wrap items-center w-full justify-between lg:justify-between">
                                <div className="flex flex-wrap lg:flex-nowrap justify-between flex-1 w-[100%]">
                                    <div className="grid gap-[35px] w-[49%] lg:w-[25%]">
                                        <div
                                            className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                            }`}
                                        >
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                                                Anrede
                                            </h2>
                                            {edit ? (
                                                <Select
                                                    className="w-full"
                                                    classNamePrefix="transparent-bg"
                                                    isSearchable={true}
                                                    name="anrede"
                                                    options={anredeFields[0]}
                                                    onChange={this.handleAnrede}
                                                    placeholder={
                                                        modifiedData.anrede
                                                            ? modifiedData.anrede
                                                            : "Auswählen..."
                                                    }
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                    {contactFields.anrede ? contactFields.anrede : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                            }`}
                                        >
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                                                Titel
                                            </h2>
                                            {edit ? (
                                                <Select
                                                    className="w-full"
                                                    classNamePrefix="transparent-bg"
                                                    isSearchable={true}
                                                    name="title"
                                                    options={titleFields[0]}
                                                    onChange={this.handleTitle}
                                                    placeholder={
                                                        modifiedData.title
                                                            ? modifiedData.title
                                                            : "Auswählen..."
                                                    }
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                    {contactFields.title
                                                        ? contactFields.title
                                                        : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                            }`}
                                        >
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                                                Umgangsform
                                            </h2>
                                            {edit ? (
                                                <Select
                                                    className="w-full"
                                                    classNamePrefix="transparent-bg"
                                                    isSearchable={true}
                                                    getOptionLabel={(e) => `${e.label} `}
                                                    getOptionValue={(e) => e.value}
                                                    name="umgangsform"
                                                    options={umgangsformFields[0]}
                                                    onChange={this.handleUmgangsform}
                                                    placeholder={
                                                        contactFields.umgangsform
                                                            ? contactFields.umgangsform
                                                            : "Auswählen..."
                                                    }
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                    {contactFields.umgangsform
                                                        ? contactFields.umgangsform
                                                        : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                            }`}
                                        >
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                                                Funktion
                                            </h2>
                                            {edit ? (
                                                <Select
                                                    className="w-full"
                                                    classNamePrefix="transparent-bg"
                                                    isSearchable={true}
                                                    name="position"
                                                    options={funktionFields[0]}
                                                    onChange={this.handlePosition}
                                                    placeholder={
                                                        contactFields.position
                                                            ? contactFields.position
                                                            : "Auswählen..."
                                                    }
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                    {contactFields.position
                                                        ? contactFields.position
                                                        : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                            }`}
                                        >
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full">
                                                Geburtstag
                                            </h2>
                                            {edit ? (
                                                <Datetime
                                                    inputProps={inputProps}
                                                    input={true}
                                                    disableTimePicker={true}
                                                    dateFormat="MM-DD"
                                                    timeFormat={false}
                                                    onChange={(e) =>
                                                        (modifiedData.birthday = Moment(e._d).format(
                                                            "YYYY-MM-DD"
                                                        ))
                                                    }
                                                    className="placeholder:text-gray-500 placeholder text-[12px]:lg:text-[14px] shadow bg-transparent border border-[#d7d7d7] text-gray-900 text-[12px] lg:text-[14px] rounded-[6px] focus:outline-none block w-full px-[8px] py-1.5 input-with-image focus-visible:outline-none small-date-input"
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                    {contactFields.birthday
                                                        ? Moment(contactFields.birthday).format("MM-DD")
                                                        : "-"}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="flex flex-col gap-[35px] pl-[2%] lg:pl-0 w-[48%] lg:w-[30%] relative">
                                        <div
                                            className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                            }`}
                                        >
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                                                Sprache
                                            </h2>
                                            {edit ? (
                                                <Select
                                                    className="w-full"
                                                    classNamePrefix="transparent-bg"
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    name="preferred_locale"
                                                    options={countriesLocale}
                                                    onChange={this.handleSpracheChange}
                                                    placeholder={
                                                        contactFields.preferred_locale
                                                            ? localeName
                                                            : "Auswählen..."
                                                    }
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                    {contactFields.preferred_locale ? localeName : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                            }`}
                                        >
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                                                Interessen
                                            </h2>
                                            {edit ? (
                                                <Select
                                                    classNamePrefix="transparent-bg"
                                                    isMulti
                                                    className="w-full"
                                                    isSearchable={true}
                                                    name="interests"
                                                    getOptionLabel={(e) => `${e.label} `}
                                                    getOptionValue={(e) => e.value}
                                                    defaultValue={
                                                        modifiedData.interests ? filteredInterests : ""
                                                    }
                                                    options={interessenFields[0]}
                                                    onChange={this.handleInteressen}
                                                    placeholder={"Auswählen..."}
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] break-all max-w-[180px]">
                                                    {contactFields.interests
                                                        ? contactFields.interests
                                                        : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                            }`}
                                        >
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                                                Ursprung
                                            </h2>
                                            {edit ? (
                                                <input
                                                    type="text"
                                                    name="origin"
                                                    onChange={this.handleInputChange}
                                                    value={modifiedData.origin}
                                                    className="placeholder:text-gray-500 placeholder text-[12px]:lg:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 text-[12px] sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                                                    placeholder="Ursprung"
                                                />
                                            ) : (
                                                <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                    {contactFields.origin ? contactFields.origin : "-"}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className="flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start border-b border-[#d7d7d7] absolute bottom-0 w-full">
                                            <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full">
                                                Double Opt-In
                                            </h2>
                                            {edit ? (
                                                <div className="flex items-center justify-between w-full">
                                                    <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                        {contactFields.doi === 1
                                                            ? "Bestätigt"
                                                            : "Nicht bestätigt"}
                                                    </p>
                                                    <label class="checkbox-container">
                                                        <input
                                                            type="checkbox"
                                                            checked={contactFields.doi === 1 ? true : false}
                                                            onChange={(e) => this.handleDoi(e)}
                                                        />
                                                        <span
                                                            style={{
                                                                backgroundColor:
                                                                    this.state.cardLoading
                                                                        ? "#d3d3d5"
                                                                        : "#fff",
                                                            }}
                                                            class="checkbox-checkmark"
                                                        ></span>
                                                    </label>
                                                </div>
                                            ) : (
                                                <div className="flex items-center justify-between w-full">
                                                    <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                        {contactFields.doi === 1
                                                            ? "Bestätigt"
                                                            : "Nicht bestätigt"}
                                                    </p>
                                                    <div
                                                        title="Send Confirmation Link"
                                                        className="w-fit cursor-pointer"
                                                    >
                                                        {contactFields.doi === 1 ? (
                                                            <>
                                                                <label class="checkbox-container">
                                                                    <input
                                                                        type="checkbox"
                                                                        disabled
                                                                        checked={
                                                                            contactDetails.fields?.all?.doi === 1
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.state.cardLoading
                                                                                    ? "#d3d3d5"
                                                                                    : "#fff",
                                                                        }}
                                                                        class="checkbox-checkmark"
                                                                    ></span>
                                                                </label>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {emailLoading ? (
                                                                    <PulseLoader size={6} color="#004f9f"/>
                                                                ) : (
                                                                    <FaRegPaperPlane
                                                                        onClick={this.handleEmailSend}
                                                                        size={20}
                                                                        color="#004f9f"
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`hidden lg:grid gap-[35px] ${!edit && "place-items-start"} w-[35%]`}>
                                        <div className="grid lg:pt-[20px]">
                                            {edit ? (
                                                <>
                          <textarea
                              rows="8"
                              cols="50"
                              name="notizen"
                              onChange={this.handleInputChange}
                              value={modifiedData.notizen}
                              className={`placeholder:text-[12px] lg:placeholder:text-[14px] shadow ${this.state.cardLoading
                                  ? "bg-[#d3d3d5]"
                                  : "bg-transparent"
                              } border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f] min-h-[480px]`}
                              placeholder="Bemerkung"
                          />
                                                </>
                                            ) : (
                                                <>
                                                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full">
                                                        Bemerkung
                                                    </h2>
                                                    <textarea
                                                        rows="8"
                                                        cols="50"
                                                        name="notizen"
                                                        disabled
                                                        value={modifiedData.notizen}
                                                        className={` placeholder:text-[12px] lg:placeholder:text-[14px] shadow ${this.state.cardLoading
                                                            ? "bg-[#d3d3d5]"
                                                            : "bg-transparent"
                                                        } border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f] min-h-[400px] mt-2`}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`flex flex-wrap items-start pt-0 lg:pt-[30px] pb-[30px] ${edit ? "gap-[35px]" : "gap-[35px] lg:gap-[30px]"
                            }`}
                        >
                            <div className="flex flex-wrap justify-between items-center w-full pb-[20px]">
                                <div className="lex flex-wrap items-center w-full justify-between lg:justify-between">
                                    <div className="flex justify-between flex-1 w-[100%]">
                                        <div className="grid gap-[35px] w-[49%] lg:w-[25%]">
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 pt-[20px] justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full">
                                                    Adresse
                                                </h2>
                                                {edit ? (
                                                    <input
                                                        type="text"
                                                        name="address1"
                                                        onChange={this.handleInputChange}
                                                        value={modifiedData.address1}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                                                        placeholder="Adresse"
                                                    />
                                                ) : (
                                                    <p className="text-gray-500 break-all font-[16px]">
                                                        {contactFields.address1
                                                            ? contactFields.address1
                                                            : contactFields.address2
                                                                ? contactFields.address2
                                                                : "-"}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[112px]">
                                                    Postfach
                                                </h2>
                                                {edit ? (
                                                    <input
                                                        type="text"
                                                        name="postfach"
                                                        onChange={this.handleInputChange}
                                                        value={modifiedData.postfach}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg placeholder:text-[12px]:lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                                                        placeholder="Postfach"
                                                    />
                                                ) : (
                                                    <p className="text-gray-500 break-all text-[13px] lg:text-[16px]">
                                                        {contactFields.postfach
                                                            ? contactFields.postfach
                                                            : "-"}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[500]  w-full lg:w-[112px]">
                                                    PLZ
                                                </h2>
                                                {edit ? (
                                                    <input
                                                        type="text"
                                                        name="zipcode"
                                                        onChange={this.handleInputChange}
                                                        value={modifiedData.zipcode}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                                                        placeholder="PLZ"
                                                    />
                                                ) : (
                                                    <p className="text-gray-500 break-all text-[13px] lg:text-[16px]">
                                                        {contactFields.zipcode
                                                            ? contactFields.zipcode
                                                            : "-"}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[112px]">
                                                    Ort
                                                </h2>
                                                {edit ? (
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        onChange={this.handleInputChange}
                                                        value={modifiedData.city}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                                                        placeholder="Ort"
                                                    />
                                                ) : (
                                                    <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                        {contactFields.city ? contactFields.city : "-"}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[112px]">
                                                    Land
                                                </h2>
                                                {edit ? (
                                                    <Select
                                                        classNamePrefix="transparent-bg"
                                                        className="w-full"
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        getOptionLabel={(e) => `${e.label}`}
                                                        getOptionValue={(e) => e.value}
                                                        name="country"
                                                        options={countries}
                                                        onChange={this.handleCountryChange}
                                                        placeholder={
                                                            modifiedData.country
                                                                ? modifiedData.country
                                                                : "Auswählen..."
                                                        }
                                                    />
                                                ) : (
                                                    <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                        {contactFields.country
                                                            ? countries.map((x) => contactFields.country === x.value ? x.label : "")
                                                            : "-"}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="flex flex-col gap-[35px] pl-[2%] lg:pl-0 w-[48%] lg:w-[30%] relative">
                                            <div
                                                className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                                                    Mobile
                                                </h2>
                                                {edit ? (
                                                    <PhoneInput
                                                        value={modifiedData.mobile}
                                                        placeholder="Mobile"
                                                        onChange={this.handleMobileChange}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] w-full flex gap-[10px] px-[8px] py-1.5"
                                                    />
                                                ) : (
                                                    <p className="text-gray-600 text-[13px] lg:text-[16px] break-all">
                                                        {contactFields.mobile ? contactFields.mobile : "-"}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                                                    Telefon
                                                </h2>
                                                {edit ? (
                                                    <PhoneInput
                                                        value={modifiedData.phone}
                                                        placeholder="Telefon"
                                                        onChange={this.handlePhoneChange}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 w-full flex gap-[10px] px-[8px] py-1.5"
                                                    />
                                                ) : (
                                                    <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                        {contactFields.phone
                                                            ? contactFields.phone
                                                            : contactFields.phone2
                                                                ? contactFields.phone2
                                                                : contactFields.phone3
                                                                    ? contactFields.phone3
                                                                    : "-"}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                                                    Email geschäftlich
                                                </h2>
                                                {edit ? (
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        onChange={this.handleInputChange}
                                                        value={modifiedData.email}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                                                        placeholder="Email geschäftlich"
                                                    />
                                                ) : (
                                                    <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                        {contactFields.email ? contactFields.email : "-"}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                                                    Email privat
                                                </h2>
                                                {edit ? (
                                                    <input
                                                        type="email"
                                                        name="emailadresse_buchhaltung"
                                                        onChange={this.handleInputChange}
                                                        value={modifiedData.emailadresse_buchhaltung}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                                                        placeholder="Email privat"
                                                    />
                                                ) : (
                                                    <p className="text-gray-500 text-[13px] lg:text-[16px] break-all">
                                                        {contactFields.emailadresse_buchhaltung
                                                            ? contactFields.emailadresse_buchhaltung
                                                            : "-"}
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                                                }`}
                                            >
                                                <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                                                    LinkedIn
                                                </h2>
                                                {edit ? (
                                                    <input
                                                        type="text"
                                                        name="linkedin"
                                                        onChange={this.handleInputChange}
                                                        value={modifiedData.linkedin}
                                                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                                                        placeholder="LinkedIn"
                                                    />
                                                ) : (
                                                    <>
                                                        {contactFields.linkedin ? (
                                                            <a
                                                                href={
                                                                    contactFields.linkedin
                                                                        ? contactFields.linkedin
                                                                        : "#"
                                                                }
                                                                target="_blank"
                                                                className="max-w-[200px] text-blue-400 text-[13px] lg:text-[16px] underline"
                                                            >
                                                                {contactFields.linkedin ? "Link" : ""}
                                                            </a>
                                                        ) : (
                                                            <span
                                                                className="max-w-[200px] text-gray-500 text-[13px] lg:text-[16px]">
                                -
                              </span>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="hidden lg:grid gap-[35px] w-[35%]">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid lg:hidden gap-[35px] w-full ">
                            <div className="grid lg:pt-[20px]">
                                {edit ? (
                                    <>
                    <textarea
                        rows="8"
                        cols="50"
                        name="notizen"
                        onChange={this.handleInputChange}
                        value={modifiedData.notizen}
                        className={`placeholder:text-[12px] lg:placeholder:text-[14px] shadow ${this.state.cardLoading
                            ? "bg-[#d3d3d5]"
                            : "bg-transparent"
                        } border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f]`}
                        placeholder="Bemerkung"
                    />
                                    </>
                                ) : (
                                    <>
                    <textarea
                        rows="8"
                        cols="50"
                        name="notizen"
                        onChange={this.handleInputChange}
                        value={modifiedData.notizen}
                        className={` placeholder:text-[12px] lg:placeholder:text-[14px] shadow ${this.state.cardLoading
                            ? "bg-[#d3d3d5]"
                            : "bg-transparent"
                        } border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f]`}
                        placeholder="Bemerkungen"
                    />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex w-full justify-center desktop-none pt-[30px]">
                            <EditModal
                                reload={this.reload}
                                submit={this.handleSubmit}
                                loading={this.state.loading}
                                errors={this.state.errors}
                            />
                        </div>
                    </div>
                    {JSON.parse(localStorage.getItem("isAuthorized")) === true ? (
                        <>
                            {!edit && (
                                <div className="p-0 lg:p-[20px] w-full block mx-auto mt-[50px] lg:mt-[20px] px-[15px]">
                                    <div
                                        className="flex flex-wrap lg:flex-nowrap justify-between items-center pb-[10px]">
                                        <h3 className="text-[18px] lg:text-[24px] text-[#004F9F] font-normal pb-[20px] lg:pb-0">
                                            Bevorstehende {activityTable ? "Aktivitäten" : "Unternehmen"}
                                        </h3>
                                        <div
                                            className="flex relative items-center mx-auto lg:mx-0 justify-center lg:justify-end gap-[5px] z-[100] pb-[30px] lg:scroll-pb-40">
                                            <button
                                                onClick={() => this.changeTable("activities")}
                                                to="#"
                                                className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${activityTable ? "border-[#004f9f]" : "shadow"
                                                }  px-3 py-2 `}
                                            >
                                                Aktivitäten
                                            </button>
                                            <button
                                                onClick={() => this.changeTable("companies")}
                                                to="#"
                                                className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${companyTable ? "border-[#004f9f]" : "shadow"
                                                }  px-3 py-2 `}
                                            >
                                                Unternehmen
                                            </button>
                                        </div>
                                    </div>

                                    {activityTable && (
                                        <div
                                            className="flex flex-wrap pt-[30px] lg:pt-0 lg:flex-nowrap relative items-center justify-center lg:justify-end w-[100%] gap-3 z-[100] pb-[30px] lg:scroll-pb-40">
                                            <button
                                                onClick={() => this.loadOwnActivities()}
                                                to="#"
                                                className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${own ? "border-[#004f9f]" : "shadow"
                                                }  px-3 py-2 `}
                                            >
                                                Meine Aktivitäten
                                            </button>
                                            <button
                                                onClick={() => this.loadAllActivities()}
                                                to="#"
                                                className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${!own ? "border-[#004f9f]" : "shadow"
                                                }  px-3 py-2 `}
                                            >
                                                Alle Aktivitäten
                                            </button>
                                            <Link
                                                onClick={() => (
                                                    this.props.setAddActivity({
                                                        lastname: this.state.modifiedData.lastname,
                                                        firstname: this.state.modifiedData.firstname,
                                                        email: this.state.modifiedData.email,
                                                        id: id,
                                                    }),
                                                        this.props.setAddActivityFromContacts(true)
                                                )}
                                                to="/aktivitat-erfassen"
                                                className={`text-[#fff] flex absolute lg:relative top-[-20px] lg:-top-0 items-center gap-2 border-2 bg-[#004f9f] shadow
                  }  px-3 py-2 `}
                                            >
                                                <MdOutlineAddTask size={20} color="#fff"/>
                                                <p className="text-white font-normal text-[13px] lg:text-[16px]">
                                                    Aktivitäten erfassen
                                                </p>
                                            </Link>
                                        </div>
                                    )}
                                    {activityTable ?
                                        <Activities activities={activities} loading={this.state.loading}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    modifiedData={this.state.modifiedData} ref={this.ref}/>
                                        :
                                        companyTable ?
                                            <Companies id={id} tableCompanies={tableCompanies} ref={this.ref}
                                                       paginationComponentOptions={paginationComponentOptions}/>
                                            :
                                            <></>}

                                </div>
                            )}</>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.users,
    };
};

export default connect(mapStateToProps, {
    retrieveUsers,
})((props) => (
    <ContactCard {...props} params={useParams()} navigate={useNavigate()}/>
));

