class DynamicContents {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("dynamiccontents", params);
  }

  get(dynamiccontentId) {
    return this.client.get(`dynamiccontents/${dynamiccontentId}`);
  }

  create(dynamiccontent) {
    delete dynamiccontent.id;
    return this.client.post(`dynamiccontents/new`, dynamiccontent);
  }

  edit(dynamiccontentId, newdynamiccontent) {
    return this.client.patch(`dynamiccontents/${dynamiccontentId}/edit`, newdynamiccontent);
  }

  editOrCreate(dynamiccontentId, newdynamiccontent) {
    return this.client.put(`dynamiccontents/${dynamiccontentId}/edit`, newdynamiccontent);
  }

  delete(dynamiccontentId) {
    return this.client.delete(`dynamiccontents/${dynamiccontentId}/delete`);
  }
}

export default DynamicContents;
