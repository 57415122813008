import React, { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";

const AddModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(!isOpen);
  }

  function onClick() {
    props.submit();
    setTimeout(() => {
      openModal();
    }, 2000);
  }

  function onClose() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="flex w-100 justify-end">
        <button
          onClick={() => onClick()}
          className="bg-[#004f9f] py-1 px-2 lg:py-2.5 lg:px-3.5 text-white text-[14px] lg:text-[16px]"
        >
          {props.loading ? (
            <PulseLoader color="#fff" size={8} />
          ) : (
            " Speichern und Schliessen"
          )}
        </button>
      </div>

      <div
        id="popup-modal"
        tabindex="-1"
        class={`fixed top-0 left-0 grid place-items-center lg:pl-64 z-[1000000] bg-[#00000030] overflow-x-hidden overflow-y-auto md:inset-0 h-screen w-screen lg:w-full md:h-full lg:h-full ease-in-out duration-300 ${
          isOpen ? "" : "hidden"
        }`}
      >
        <div className="relative w-full h-full max-w-md p-4 md:h-auto">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="p-6 text-center">
              {props.errors ? (
                <>
                  <BiError
                    size={70}
                    color="red"
                    className="block mx-auto my-6"
                  />
                  <h3 className="mb-5 text-lg font-normal text-gray-500 ">
                    Oops, etwas ist schief gelaufen, versuchen Sie es erneut
                  </h3>
                </>
              ) : (
                <>
                  <AiOutlineCheckCircle
                    size={70}
                    color="green"
                    className="block mx-auto my-6"
                  />
                  <h3 className="mb-5 text-lg font-normal text-gray-500 ">
                    Erfolgreich hinzugefügt
                  </h3>
                </>
              )}
              {!props.errors ? (
                <button
                  onClick={props.redirect}
                  type="button"
                  className="bg-[#004f9f] py-1 px-2 lg:py-2.5 lg:px-3.5 text-white text-[14px] lg:text-[16px]"
                >
                  Schliessen
                </button>
              ) : (
                <button
                  onClick={() => onClose()}
                  type="button"
                  className="bg-[#004f9f] py-1 px-2 lg:py-2.5 lg:px-3.5 text-white text-[14px] lg:text-[16px]"
                >
                  Schliessen
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        id="popup-modal"
        tabindex="-1"
        class={`fixed top-0 left-0 grid place-items-center pl-64 z-[1000000] bg-[#00000030] overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ease-in-out duration-300 ${
          isOpenError ? "" : "hidden"
        }`}
      >
        <div className="relative w-full h-full max-w-md p-4 md:h-auto">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="p-6 text-center">
              <BiError size={70} color="red" className="block mx-auto my-6" />
              <h3 className="mb-5 text-lg font-normal text-gray-500 ">
                Oops, etwas ist schief gelaufen, versuchen Sie es erneut
              </h3>
              <button
                onClick={() => onClose()}
                type="button"
                className="text-[#004f9f] border-2 border-[#004f9f] hover:bg-blue-100 focus:ring-4 focus:outline-none focus:ring-blue-100 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                Schliessen
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AddModal;
