const qs = require("qs");
class Widgets {
  constructor(client) {
    this.client = client;
  }

  get(widgetId) {
    return this.client.get(`widgets/${widgetId}`);
  }

  list(params = {}) {
    return this.client.list("widgets", params);
  }

  create(widgets) {
    delete widgets.id;
    return this.client.post("widgets/new", widgets);
  }

  edit(widgetId, widget) {
    return this.client.patch(`widgets/${widgetId}/edit`, widget);
  }

  editOrCreate(widgetId, widget) {
    return this.client.put(`contacts/${widgetId}/edit`, widget);
  }

  delete(widgetId) {
    return this.client.delete(`widgets/${widgetId}/delete`);
  }

  getWidgetsData() {
    return this.client.get(`widgets/data`);
  }

  getWidgetsOptions(type) {
    return this.client.get(`widgets/options/${type}`);
  }

  getWidgetByType(type, params = {}) {
    return this.client.get(`widgets/data/${type}?${qs.stringify(params)}`);
  }

  listTemplates(params = {}) {
    return this.client.list("widgets/template", params);
  }

  listTemplateData(template, params = {}) {
    return this.client.list(
      `widgets/template/data/${template}`,
      qs.stringify(params)
    );
  }

  createWidgetTemplate(template) {
    return this.client.post("widgets/template/new", template);
  }

  saveWidgetTemplate(template) {
    return this.client.put("widgets/template/edit", template);
  }
}

export default Widgets;

