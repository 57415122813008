import connector from "../connector";

class HttpServices {
  getStandardActivityType() {
    return connector.activities.getType();
  }

  getUsers() {
    return connector.users.list(encodeURI("limit=400"));
  }
}

export default new HttpServices();
