import React from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { AiOutlineCaretDown, AiOutlineCaretRight, AiOutlineSearch } from "react-icons/ai";
import { FiUserPlus } from "react-icons/fi";
import connector from "../../../connector";
import PulseLoader from "react-spinners/PulseLoader";
import { BsSortDown } from "react-icons/bs";
import { isMobile } from "react-device-detect";
import { BiDotsVertical } from 'react-icons/bi'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            loading: false,
            error: null,
            own: true,
            contacts: [],
            totalRows: 0,
            rowsPerPage: 10,
            page: 1,
            search: "",
            start: 0,
            loadMore: false,
            showEmails: false,
            showDetails: false,
        };
    }

    handleClickOutside(event) {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.props.onClickOutside && this.props.onClickOutside();
            this.setState({ showDetails: -1, showEmails: false })
        }
    };

    componentDidMount = async () => {
        await this.loadOwnContacts();
        this.scrollDetection();
        document.addEventListener('click', this.handleClickOutside, true);
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevState.rowsPerPage !== this.state.rowsPerPage) {
            this.state.own
                ? await this.loadOwnContacts(this.state.page)
                : await this.loadAllContacts(this.state.page);
        }
    };

    loadOwnContacts = async (page = 1) => {
        const userDetails = JSON.parse(localStorage.getItem("user-info"));
        let url = `where[0][col]=date_identified&where[0][expr]=isNotNull&where[1][col]=owner_id&where[1][expr]=eq&where[1][val]=${userDetails.id}`;
        if (this.state.search.length) {
            url += "&search=" + this.state.search;
        }
        await this.fetchContacts(url, page);
        this.setState({ own: true });
    };

    loadAllContacts = async (page = 1) => {
        let url = `where[0][col]=date_identified&where[0][expr]=isNotNull`;
        if (this.state.search.length) {
            url = "orderBy=last_active&orderByDir=DESC&search=" + this.state.search;
        }
        await this.fetchContacts(url, page);
        this.setState({ own: false });
    };

    searchContact = async (e) => {
        this.setState(
            {
                data: [],
                search: e.target.value.length < 1 ? "" : e.target.value,
            },
            async () => {
                this.state.own
                    ? await this.loadOwnContacts(1)
                    : await this.loadAllContacts(1);
            }
        );
    };

    loadMore = async () => {
        this.setState({ loadMore: true });
        const userDetails = JSON.parse(localStorage.getItem("user-info"));
        this.state.start += 10;
        let url = `where[0][col]=date_identified&where[0][expr]=isNotNull&limit=10&start=${this.state.start}`;
        if (this.state.own) {
            url += `&where[0][col]=date_identified&where[0][expr]=isNotNull&where[1][col]=owner_id&where[1][expr]=eq&where[1][val]=${userDetails.id}`;
        }
        let response = await connector.contacts.list(encodeURI(url));
        this.setState({
            contacts: [
                ...this.state.contacts.concat(Object.entries(response.data.contacts)),
            ],
            loadMore: false,
        });
    };

    fetchContacts = async (url, page = 1) => {
        this.setState({ loading: true });
        let start = page === 1 ? 0 : (page - 1) * this.state.rowsPerPage;
        let query = `limit=${this.state.rowsPerPage}&start=${start}`;

        if (url) {
            query += "&" + url;
        }

        let response = await connector.contacts.list(encodeURI(query));

        this.setState({
            contacts:
                Object.entries(response.data.contacts) ||
                Object.entries(response.data.contact),
            totalRows: response.data.total,
            loading: false,
        });
    };

    handlePageChange = async (page) => {
        this.setState({ page: page });
        this.state.own
            ? await this.loadOwnContacts(page)
            : await this.loadAllContacts(page);
    };

    handlePerRowsChange = async (newPerPage, page) => {
        this.setState({ rowsPerPage: newPerPage, page: page });
        this.state.own
            ? await this.loadOwnContacts(page)
            : await this.loadAllContacts(page);
    };

    scrollDetection = () => {
        window.addEventListener("scroll", () => {
            const scrollable = document.documentElement.scrollHeight - window.innerHeight;
            const scrolled = window.scrollY

            if (Math.ceil(scrolled) === scrollable && isMobile && !this.state.loadMore && this.state.contacts.length > 9) {
                this.loadMore();
            }
        })
    }


    render() {
        const { error, own, contacts, showEmails, search } = this.state;

        let csvContact = this.state.contacts.map((x) => x[1].fields.all);

        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const exportToCSV = (csvData, fileName) => {
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        };

        if (error) {
            return <div>An error occured: {error.message}</div>;
        }

        const paginationComponentOptions = {
            rowsPerPageText: "Zeilen pro Seite",
            rangeSeparatorText: "von",
        };

        const columns = [
            {
                name: "Nachname",
                selector: (row) =>
                    row[1].fields.all.lastname &&
                        row[1].fields.all.lastname.length > 25 ? (
                        <div class="group py-[50px]">
                            {row[1].fields.all.lastname ? row[1].fields.all.lastname : ""}
                            <div
                                class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span
                                    class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row[1].fields.all.lastname ? row[1].fields.all.lastname : ""}
                                </span>
                            </div>
                        </div>
                    ) : row[1].fields.all.lastname ? (
                        row[1].fields.all.lastname
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "180px",
            },
            {
                name: "Vorname",
                selector: (row) =>
                    row[1].fields.all.firstname &&
                        row[1].fields.all.firstname.length > 23 ? (
                        <div class="group py-[50px]">
                            {row[1].fields.all.firstname ? row[1].fields.all.firstname : ""}
                            <div
                                class="absolute top-[-10px] left-[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span
                                    class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row[1].fields.all.firstname
                                        ? row[1].fields.all.firstname
                                        : ""}
                                </span>
                            </div>
                        </div>
                    ) : row[1].fields.all.firstname ? (
                        row[1].fields.all.firstname
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "200px",
            },
            {
                name: "Unternehmen",
                selector: (row) =>
                    row[1].fields.all.company && row[1].fields.all.company.length > 36 ? (
                        <div class="group py-[50px]">
                            {row[1].fields.all.company ? row[1].fields.all.company : ""}
                            <div
                                class="absolute top-[-10px] left--[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                                <span
                                    class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                                    {row[1].fields.all.company ? row[1].fields.all.company : ""}
                                </span>
                            </div>
                        </div>
                    ) : row[1].fields.all.company ? (
                        row[1].fields.all.company
                    ) : (
                        ""
                    ),
                sortable: true,
                width: "307px",
            },
            {
                name: "Aktionen",
                selector: (row) => (
                    <div className="flex gap-[20px]  items-center">
                        <div class="dropdown">
                            <button
                                className="bg-[#EFF7FF] flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f]">
                                <FaEnvelope size={16} color="#004f9f" />
                                <p>Nachricht senden</p>
                            </button>
                            <div class="dropdown-content">
                                {row[1].fields.all.email ? (
                                    <>
                                        <div className="group">
                                            <div
                                                onClick={() =>
                                                    this.setState({ showEmails: !showEmails })
                                                }
                                                className="flex flex-row items-center justify-between cursor-pointer"
                                            >
                                                E-Mail
                                                <AiOutlineCaretRight />
                                            </div>
                                            <div
                                                onMouseLeave={() =>
                                                    this.setState({ showEmails: !showEmails })
                                                }
                                                class={`${showEmails ? "flex" : "hidden"
                                                    } flex-col absolute ${row[1].fields.all.email &&
                                                        row[1].fields.all.emailadresse_buchhaltung
                                                        ? "top-[-50%]"
                                                        : "top-[-10%]"
                                                    } left-[100%] bg-[#eef7ff] p-[10px] gap-2 rounded-[10px] `}
                                            >
                                                {row[1].fields.all.email && (
                                                    <>
                                                        <a
                                                            className=""
                                                            href={`mailto: ${row[1].fields.all.email}`}
                                                        >
                                                            {row[1].fields.all.email}
                                                        </a>
                                                        {row[1].fields.all.emailadresse_buchhaltung && (
                                                            <a
                                                                className=""
                                                                href={`mailto: ${row[1].fields.all.emailadresse_buchhaltung}`}
                                                            >
                                                                {row[1].fields.all.emailadresse_buchhaltung}
                                                            </a>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : <>No email provided</>}
                                {!row[1].fields.all.email &&
                                    !row[1].fields.all.mobile &&
                                    <br />}
                                {row[1].fields.all.mobile ? (
                                    <a href={`sms: ${row[1].fields.all.mobile}`}>SMS Mobile</a>
                                ) : <>No mobile provided</>}
                            </div>
                        </div>
                        {row[1].fields.all.phone ||
                            (row[1].fields.all.mobile && (
                                <button
                                    className="bg-[#EFF7FF] absolute flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f] translate-x-[173px]"
                                    to="#"
                                >
                                    <FaPhoneAlt size={16} color="#004f9f" />
                                    <a
                                        href={`tel: ${row[1].fields.all.phone
                                            ? row[1].fields.all.phone
                                            : row[1].fields.all.mobile
                                            }`}
                                    >
                                        Anrufen
                                    </a>
                                </button>
                            ))}
                    </div>
                    // <div className="flex gap-[20px] items-center">
                    //   {row[1].fields.all.email ||
                    //   row[1].fields.all.emailadresse_buchhaltung ? (
                    //     <a
                    //       href={`${
                    //         row[1].fields.all.email
                    //           ? "mailto:" + row[1].fields.all.email
                    //           : row[1].fields.all.emailadresse_buchhaltung
                    //           ? "mailto:" + row[1].fields.all.emailadresse_buchhaltung
                    //           : "#"
                    //       }`}
                    //       disabled
                    //       title="This feature will be awailable soon..."
                    //       className="bg-[#EFF7FF] flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f]"
                    //     >
                    //       <FaEnvelope size={16} color="#004f9f" />
                    //       <p>Nachricht senden</p>
                    //     </a>
                    //   ) : (
                    //     ""
                    //   )}

                    //   {row[1].fields.all.phone || row[1].fields.all.mobile ? (
                    //     <a
                    //       href={`${
                    //         row[1].fields.all.phone
                    //           ? "tel:" + row[1].fields.all.phone
                    //           : row[1].fields.all.mobile
                    //           ? "tel:" + row[1].fields.all.mobile
                    //           : "#"
                    //       }`}
                    //       className="bg-[#EFF7FF] absolute flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f] translate-x-[173px]"
                    //       to="#"
                    //     >
                    //       <FaPhoneAlt size={16} color="#004f9f" />
                    //       <p>Anrufen</p>
                    //     </a>
                    //   ) : (
                    //     ""
                    //   )}
                    // </div>
                ),
                sortable: false,
                width: "380px",
            },

            {
                selector: (row) => (
                    <Link
                        to={`/kontaktkarte/${row[1].id}`}
                        className="bg-[#004f9f] hover:bg-[#004f9fba] text-white p-[20px] w-fit right-0 absolute translate-y-[-50%]"
                    >
                        <p>Kontakt anzeigen</p>
                    </Link>
                ),
                sortable: false,
            },
        ];

        return (
            <div className="main-section" style={{ height: contacts.length > 9 ? "calc(100vh + 70px)" : "100%" }}>
                <div className="w-[100%] pt-[30px] lg:pt-0">
                    <div className="flex justify-between flex-wrap items-center">
                        <div className="grid gap-[20px] lg:flex items-center  w-[100%] lg:w-[50%] lg:gap-[30px]">
                            <h3 className="text-2xl lg:text-4xl text-[#004F9F] font-normal">
                                Kontakte
                            </h3>
                            <div className="block ml-auto lg:ml-0 w-full lg:w-[fit-content] lg:h-[32px] ">
                                <AiOutlineSearch
                                    color="#004f9f"
                                    className="absolute translate-x-[10px] translate-y-[9px]"
                                    size={18}
                                />
                                <input
                                    type="text"
                                    name="search"
                                    onChange={this.searchContact}
                                    className="placeholder:text-[#004F9F] placeholder:text-[14px] shadow bg-[#fff] border-gray-400 text-gray-900 sm:text-sm rounded-[24px] focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[220px] px-[40px] py-1 lg:py-2 searchinput"
                                    placeholder="Kontakt suchen"
                                />
                            </div>
                        </div>
                        <div
                            className="grid place-items-center lg:flex lg:items-center pt-[50px] lg:pt-0 lg:justify-end w-[100%] lg:w-[50%] lg:gap-[30px]">
                            {/* {(this.props.userId && this.props.userId.role.id === 1) ||
                                (this.props.userId &&
                                    this.props.userId.role.name === "Prüfer") ? (
                                <div
                                    className="text-[#004f9f] text-center cursor-pointer font-normal lg:text-left underline text-[14px] lg:text-[16px] pb-[15px] lg:pb-0"
                                    onClick={(e) => exportToCSV(csvContact, "Kontakte")}
                                >
                                    Liste exportieren
                                </div>
                            ) : (
                                <></>
                            )} */}
                            <Link
                                to="/kontakt-erfassen"
                                className="flex items-center gap-[10px] w-full lg:w-fit justify-center bg-[#004f9f] py-3 px-2.5 lg:py-4 lg:px-5"
                            >
                                <FiUserPlus size={20} color="#fff" />
                                <p className="text-white font-normal text-[13px] lg:text-[16px]">
                                    Kontakt erfassen
                                </p>
                            </Link>
                        </div>
                    </div>
                    <div className="mt-[30px] lg:mt-[0]">
                        <div
                            className="flex gap-[15px] lg:gap-[0] flex-wrap justify-between items-center pt-[30px] lg:pt-[44px]  ">
                            <div className="flex items-center w-[50%] gap-[30px]">
                                {/*<div*/}
                                {/*    className="block w-[fit-content] h-[32px] z-50 translate-y-[100px] lg:translate-y-[65px]">*/}
                                {/*    {contacts.length > 0 ? (*/}
                                {/*        <>*/}
                                {/*            <AiOutlineSearch*/}
                                {/*                color="#004f9f"*/}
                                {/*                className="absolute translate-x-[95px] lg:translate-x-[110px] translate-y-[9px] lg:translate-y-[12px]"*/}
                                {/*                size={18}*/}
                                {/*            />*/}
                                {/*            <input*/}
                                {/*                type="text"*/}
                                {/*                name="search"*/}
                                {/*                className="placeholder:text-[#004F9F] placeholder:text-[15px] shadow bg-[#fff] border-gray-400 text-gray-900 sm:text-sm rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-[120px] lg:w-[170px] px-[10px] lg:px-[20px] py-1 lg:py-2.5 searchinput"*/}
                                {/*                placeholder="Utzername"*/}
                                {/*            />*/}
                                {/*        </>*/}
                                {/*    ) : (*/}
                                {/*        ""*/}
                                {/*    )}*/}
                                {/*</div>*/}
                            </div>
                            <div
                                className="flex absolute left-0 lg:right-0 pt-[50px] lg:pt-[30px] lg:relative items-center justify-center lg:justify-end w-[100%] lg:w-[50%] gap-[15px] lg:gap-[5px] z-50 pb-[30px] lg:pb-0 px-[20px] lg:px-0">
                                <button
                                    onClick={() => this.loadOwnContacts()}
                                    to="#"
                                    className={`w-[50%] lg:w-fit text-[#004f9f] bg-[#fff] text-[14px] lg:text-[16px] ${own ? "border-2 border-[#004f9f]" : "shadow"
                                        }  px-3 py-2 `}
                                >
                                    Meine Kontakte
                                </button>
                                <button
                                    onClick={() => this.loadAllContacts()}
                                    to="#"
                                    className={`w-[50%] lg:w-fit text-[#004f9f] bg-[#fff] text-[14px] lg:text-[16px] ${!own ? "border-2 border-[#004f9f]" : "shadow"
                                        }  px-3 py-2 `}
                                >
                                    Alle Kontakte
                                </button>
                            </div>
                        </div>
                        <div className="table-container mt-[60px] lg:mt-[10px]">
                            {!isMobile ? <DataTable
                                columns={columns}
                                data={contacts}
                                direction="auto"
                                fixedHeaderScrollHeight="300px"
                                responsive
                                subHeaderAlign="right"
                                subHeaderWrap
                                progressPending={this.state.loading}
                                pagination={isMobile ? false : true}
                                sortIcon={
                                    <BsSortDown
                                        sx={{
                                            color: "#004f9f",
                                            opacity: 1,
                                            height: "20px",
                                            width: "24px",
                                        }}
                                    />
                                }
                                paginationServer
                                paginationTotalRows={this.state.totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                                paginationComponentOptions={paginationComponentOptions}
                                progressComponent={
                                    <div className="nodatacomponent">
                                        <PulseLoader color={"#004f9f"} />{" "}
                                    </div>
                                }
                                noDataComponent={
                                    <div className="nodatacomponent">
                                        Keine Daten vorhanden...
                                    </div>
                                }
                                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                            ></DataTable> :
                                <>
                                    {this.state.loading ? <div className="nodatacomponent">
                                        <PulseLoader color={"#004f9f"} size={10} />
                                    </div> :
                                        <div ref={this.ref}>{
                                            contacts.map((contact, i) => (
                                                contact[1].fields.all.firstname && (
                                                    <div key={i}
                                                        className="flex flex-row gap-4 px-1 pt-4 pb-2 border-b border-b-[#0000001f]">
                                                        <Link to={`/kontaktkarte/${contact[1].id}`}
                                                            className="flex flex-col gap-1 flex-wrap flex-1 max-w-[70%]">
                                                            <p className="text-[12px] text-[#004f9f]">Name und
                                                                Vorname</p>
                                                            <div
                                                                className="flex flex-wrap items-start gap-1 text-gray-500">
                                                                <p className="text-[12px]">{contact[1].fields.all.lastname}</p>
                                                                <p className="text-[12px]">{contact[1].fields.all.firstname}</p>
                                                            </div>
                                                        </Link>
                                                        <div className="flex flex-col gap-1 flex-1">
                                                            <p className="text-[12px] text-[#004f9f]">Unternehmen</p>
                                                            <p className="text-gray-500 text-[12px]">{contact[1].fields.all.company && contact[1].fields.all.company} </p>
                                                        </div>
                                                        <div className="relative w-fit">
                                                            <div className="w-fit flex items-center">
                                                                <BiDotsVertical onClick={() => this.setState({
                                                                    showDetails: this.state.showDetails === i ? -1 : i,
                                                                    showEmails: this.state.showDetails === i && false
                                                                })} size={24} color="#004f9f" />
                                                            </div>
                                                            {this.state.showDetails === i && (
                                                                <div
                                                                    class={`dropdown-mobile right-0 w-[140px!important] border-2 border-[#004f9f] z-[99999999]`}>
                                                                    {this.state.showDetails === i && (
                                                                        <>
                                                                            <button
                                                                                className="bg-[#EFF7FF] flex items-center gap-[5px] py-2 px-2.5 rounded-lg text-[#004f9f]">
                                                                                <FaEnvelope size={16} color="#004f9f" />
                                                                                <p className="text-[12px]">Nachricht
                                                                                    senden</p>
                                                                            </button>
                                                                            <div class="dropdown-content-mobile">
                                                                                {contact[1].fields.all.email ? (
                                                                                    <>
                                                                                        <div className="group">
                                                                                            <div
                                                                                                onClick={() =>
                                                                                                    this.setState({ showEmails: !showEmails })
                                                                                                }
                                                                                                className="flex relative flex-row items-center justify-between cursor-pointer text-[12px]"
                                                                                            >
                                                                                                E-Mail
                                                                                                <AiOutlineCaretDown />
                                                                                                <div
                                                                                                    onMouseLeave={() =>
                                                                                                        this.setState({ showEmails: !showEmails })
                                                                                                    }
                                                                                                    class={`${showEmails ? "flex" : "hidden"
                                                                                                        } flex-col absolute top-[120%] min-w-[150px] right-[-20px] bg-[#eef7ff] p-[10px] gap-2 `}
                                                                                                >
                                                                                                    {contact[1].fields.all.email && (
                                                                                                        <>
                                                                                                            <a
                                                                                                                className="text-[12px]"
                                                                                                                href={`mailto: ${contact[1].fields.all.email}`}
                                                                                                            >
                                                                                                                {contact[1].fields.all.email}
                                                                                                            </a>
                                                                                                            {contact[1].fields.all.emailadresse_buchhaltung && (
                                                                                                                <a
                                                                                                                    className="text-[12px]"
                                                                                                                    href={`mailto: ${contact[1].fields.all.emailadresse_buchhaltung}`}
                                                                                                                >
                                                                                                                    {contact[1].fields.all.emailadresse_buchhaltung}
                                                                                                                </a>
                                                                                                            )}

                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </>
                                                                                ) : <p className="text-[10px]">No email
                                                                                    provided</p>}

                                                                                {contact[1].fields.all.mobile ? (
                                                                                    <a className="text-[12px]"
                                                                                        href={`sms: ${contact[1].fields.all.mobile}`}>SMS
                                                                                        Mobile</a>
                                                                                ) : <p className="text-[10px]">No mobile
                                                                                    provided</p>}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    }

                                </>}
                            {
                                isMobile && this.state.loadMore && contacts.length > 9 ? (
                                    <div className="flex justify-center">
                                        <PulseLoader color={"#004f9f"} size={10} />
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contacts;

