class TextMessages {
  constructor(client) {
    this.client = client;
  }

  get(smsId) {
    return this.client.get(`smses/${smsId}`);
  }

  list(params = {}) {
    return this.client.list("smses", params);
  }

  create(smse) {
    delete smse.id;
    return this.client.post("smses/new", smse);
  }

  edit(smsId, newsms) {
    return this.client.patch(`smses/${smsId}/edit`, newsms);
  }

  editOrCreate(smsId, newsms) {
    return this.client.put(`smses/${smsId}/edit`, newsms);
  }

  delete(smsId) {
    return this.client.delete(`smses/${smsId}/delete`);
  }

  sendSmsToContact(smsId, contactId)
  {
    return this.client.get(`smses/${smsId}/contact/${contactId}/send`);
  }
}

export default TextMessages;
