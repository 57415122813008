class Roles {
  constructor(client) {
    this.client = client;
  }

  get(roleId) {
    return this.client.get(`roles/${roleId}`);
  }

  list(params = {}) {
    return this.client.list("roles", params);
  }

  create(role) {
    delete role.id;
    return this.client.post("roles/new", role);
  }

  edit(roleId, newrole) {
    return this.client.patch(`roles/${roleId}/edit`, newrole);
  }

  editOrCreate(roleId, newrole) {
    return this.client.put(`roles/${roleId}/edit`, newrole);
  }

  delete(roleId) {
    return this.client.delete(`roles/${roleId}/delete`);
  }
}

export default Roles;
