class Themes {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("themes", params);
  }

  get(themeName) {
    return this.client.get(`themes/${themeName}`);
  }

  create(theme) {
    delete theme.id;
    return this.client.post(`themes/new`, theme);
  }

  delete(themeName) {
    return this.client.delete(`themes/${themeName}/delete`);
  }
}

export default Themes;
