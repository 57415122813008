class Stages {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("stages", params);
  }

  get(stageId) {
    return this.client.get(`stages/${stageId}`);
  }

  edit(stageId, newstage) {
    return this.client.patch(`stages/${stageId}/edit`, newstage);
  }

  editOrCreate(stageId, newstage) {
    return this.client.put(`stages/${stageId}/edit`, newstage);
  }

  delete(stageId) {
    return this.client.delete(`stages/${stageId}/delete`);
  }

  addContact(stageId, contactId) {
    return this.client.post(`stages/${stageId}/contact/${contactId}/new`);
  }

  removeContact(stageId, contactId) {
    return this.client.post(`stages/${stageId}/contact/${contactId}/remove`);
  }
}

export default Stages;

