class Notifications {
  constructor(client) {
    this.client = client;
  }

  get(notificationId) {
    return this.client.get(`notifications/${notificationId}`);
  }

  list(params = {}) {
    return this.client.list("notifications", params);
  }

  create(notification) {
    delete notification.id;
    return this.client.post("notifications/new", notification);
  }

  edit(notificationId, newNotification) {
    return this.client.patch(`notifications/${notificationId}/edit`, newNotification);
  }

  editOrCreate(notificationId, newNotification) {
    return this.client.put(`notifications/${notificationId}/edit`, newNotification);
  }

  delete(notificationId) {
    return this.client.delete(`notifications/${notificationId}/delete`);
  }
}

export default Notifications;
