import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { AiOutlineCaretDown, AiOutlineCaretRight, AiOutlineSearch } from "react-icons/ai";
import { BsSortDown } from "react-icons/bs";
import DataTable from "react-data-table-component";
import connector from "../../../connector";
import { columns } from "../../../reusableComponents/DataTables/CompanyDataTable";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { isMobile } from "react-device-detect";
import { BiDotsVertical } from "react-icons/bi";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      loading: false,
      error: null,
      mycompanies: true,
      companies: [],
      totalRows: 0,
      rowsPerPage: 10,
      search: "",
      own: true,
      start: 0,
      loadMore: false,
      showDetails: false,
      showEmails: false,
    };
  }

  handleClickOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.props.onClickOutside && this.props.onClickOutside();
      this.setState({ showDetails: -1, showEmails: false })
    }
  };

  componentDidMount = async () => {
    await this.loadOwnCompanies();
    this.scrollDetection();
    document.addEventListener('click', this.handleClickOutside, true);
  };

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevState.rowsPerPage !== this.state.rowsPerPage) {
      this.state.own
        ? await this.loadOwnCompanies(this.state.page)
        : await this.loadAllCompanies(this.state.page);
    }
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  };

  loadOwnCompanies = async (page = 1) => {
    const userDetails = JSON.parse(localStorage.getItem("user-info"));
    let url = ``;
    if (this.state.search.length) {
      url += "&search=" + this.state.search;
    }
    await this.fetchCompanies(url, page);
    this.setState({ own: true });
  };

  loadAllCompanies = async (page = 1) => {
    let url = ``;
    if (this.state.search.length) {
      url += "&search=" + this.state.search;
    }
    await this.fetchCompanies(url, page);
    this.setState({ own: false });
  };

  searchCompany = async (e) => {
    this.setState(
      {
        data: [],
        search: e.target.value.length < 1 ? "" : e.target.value,
      },
      async () =>
        this.state.own
          ? await this.loadOwnCompanies(1)
          : await this.loadAllCompanies(1)
    );
  };

  fetchCompanies = async (url, page = 1) => {
    this.setState({ loading: true });
    let start = page === 1 ? 0 : (page - 1) * this.state.rowsPerPage;
    let query = `limit=${this.state.rowsPerPage}&start=${start}`;

    if (url) {
      query += "&" + url;
    }

    let response = await connector.companies.list(encodeURI(query));

    this.setState({
      companies:
        Object.entries(response.data.companies) ||
        Object.entries(response.data.company),
      totalRows: response.data.total,
      loading: false,
    });
  };

  loadMore = async () => {
    this.setState({ loadMore: true });
    this.state.start += 10;
    let url = `limit=10&start=${this.state.start}`;

    let response = await connector.companies.list(encodeURI(url));
    this.setState({
      companies: [
        ...this.state.companies.concat(Object.entries(response.data.companies)),
      ],
      loadMore: false,
    });
  };

  handlePageChange = async (page) => {
    this.state.own
      ? await this.loadOwnCompanies(page)
      : await this.loadAllCompanies(page);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({ rowsPerPage: newPerPage });
    this.state.own
      ? await this.loadOwnCompanies(page)
      : await this.loadAllCompanies(page);
  };


  scrollDetection = () => {
    window.addEventListener("scroll", () => {
      const scrollable = document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = window.scrollY

      if (Math.ceil(scrolled) === scrollable && isMobile && !this.state.loadMore && this.state.companies.length > 9) {
        this.loadMore();
      }
    })
  }

  render() {
    const { error, own, companies, showEmails } = this.state;

    const columns = [
      {
        name: "Unternehmen",
        selector: (row) =>
          row[1].fields.all.companyname &&
            row[1].fields.all.companyname.length > 25 ? (
            <div class="group py-[50px]">
              {row[1].fields.all.companyname ? row[1].fields.all.companyname : ""}
              <div class="absolute top-[-10px] left--[10px] flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                  {row[1].fields.all.companyname
                    ? row[1].fields.all.companyname
                    : ""}
                </span>
              </div>
            </div>
          ) : row[1].fields.all.companyname ? (
            row[1].fields.all.companyname
          ) : (
            ""
          ),
        sortable: true,
        width: "300px",
      },
      {
        name: "Adresse",
        selector: (row) =>
          (row[1].fields.all.companyaddress1 &&
            row[1].fields.all.companyaddress1.length > 11) ||
            (row[1].fields.all.companyaddress2 &&
              row[1].fields.all.companyaddress2.length > 11) ? (
            <div class="group py-[50px]">
              {row[1].fields.all.companyaddress1
                ? row[1].fields.all.companyaddress1
                : row[1].fields.all.companyaddress2
                  ? row[1].fields.all.companyaddress2
                  : ""}
              <div class="absolute top-[-10px] left-0 flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                  {row[1].fields.all.companyaddress1
                    ? row[1].fields.all.companyaddress1
                    : row[1].fields.all.companyaddress2
                      ? row[1].fields.all.companyaddress2
                      : ""}
                </span>
              </div>
            </div>
          ) : row[1].fields.all.companyaddress1 ? (
            row[1].fields.all.companyaddress1
          ) : row[1].fields.all.companyaddress2 ? (
            row[1].fields.all.companyaddress2
          ) : (
            ""
          ),
        sortable: true,
        width: "150px",
      },
      {
        name: "PLZ",
        selector: (row) =>
          row[1].fields.all.companyzipcode ? row[1].fields.all.companyzipcode : "",
        sortable: true,
        width: "150px",
      },
      {
        name: "Ort",
        selector: (row) =>
          row[1].fields.all.companycity &&
            row[1].fields.all.companycity.length > 25 ? (
            <div class="group py-[50px]">
              {row[1].fields.all.companycity ? row[1].fields.all.companycity : ""}
              <div class="absolute top-[-10px] left-0 flex flex-col items-center hidden mb-6 group-hover:flex h-[50px]">
                <span class="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#004f9f] shadow-lg ">
                  {row[1].fields.all.companycity
                    ? row[1].fields.all.companycity
                    : ""}
                </span>
              </div>
            </div>
          ) : row[1].fields.all.companycity ? (
            row[1].fields.all.companycity
          ) : (
            ""
          ),
        sortable: true,
        width: "150px",
      },
      {
        name: "Aktionen",
        selector: (row) => (
          <div className="flex gap-[20px]  items-center">
            <div class="dropdown">
              <button className="bg-[#EFF7FF] flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f]">
                <FaEnvelope size={16} color="#004f9f" />
                <p>Nachricht senden</p>
              </button>
              <div class="dropdown-content">
                {row[1].fields.all.companyemail ? (
                  <>
                    <div className="group">
                      <div
                        onClick={() =>
                          this.setState({ showEmails: !showEmails })
                        }
                        className="flex flex-row items-center justify-between cursor-pointer"
                      >
                        E-Mail
                        <AiOutlineCaretRight />
                      </div>
                      <div
                        onMouseLeave={() =>
                          this.setState({ showEmails: !showEmails })
                        }
                        class={`${showEmails ? "flex" : "hidden"
                          } flex-col absolute ${row[1].fields.all.companyemail &&
                            row[1].fields.all.emailadresse_buchhaltung
                            ? "top-[-50%]"
                            : "top-[-10%]"
                          } left-[100%] bg-[#eef7ff] p-[10px] gap-2 rounded-[10px] `}
                      >
                        {row[1].fields.all.companyemail && (
                          <>
                            <a
                              className=""
                              href={`mailto: ${row[1].fields.all.companyemail}`}
                            >
                              {row[1].fields.all.companyemail}
                            </a>
                            {row[1].fields.all.emailadresse_buchhaltung && (
                              <a
                                className=""
                                href={`mailto: ${row[1].fields.all.emailadresse_buchhaltung}`}
                              >
                                {row[1].fields.all.emailadresse_buchhaltung}
                              </a>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : <>No email provided</>}
                {!row[1].fields.all.companyemail &&
                  !row[1].fields.all.companyphone &&
                  <br />}
                {row[1].fields.all.companyphone ? (
                  <a href={`sms: ${row[1].fields.all.companyphone}`}>SMS Mobile</a>
                ) : <>No mobile provided</>}
              </div>
            </div>
            {row[1].fields.all.companyphone && (
              <button
                className="bg-[#EFF7FF] absolute flex items-center gap-[5px] py-2.5 px-3 rounded-lg text-[#004f9f] translate-x-[173px]"
                to="#"
              >
                <FaPhoneAlt size={16} color="#004f9f" />
                <a
                  href={`tel: ${row[1].fields.all.phone
                    ? row[1].fields.all.phone
                    : row[1].fields.all.mobile
                    }`}
                >
                  Anrufen
                </a>
              </button>
            )}
          </div>

        ),
        sortable: false,
        style: {
          minWidth: "450px",
        },
      },

      {
        selector: (row) => (
          <Link
            className="bg-[#004f9f] hover:bg-[#004f9fba] text-white p-[20px] w-fit right-0 absolute translate-y-[-50%]"
            to={`/unternehmenskarte/${row[1].id}`}
          >
            <p>Unternehmen anzeigen</p>
          </Link>
        ),
        sortable: false,
        width: "fit-content",
      },
      // {
      //   selector: (row) => <Link to="/kontaktkarte">Kontakte anzeigen</Link>,
      //   sortable: false,
      //   width: "fit-content",
      //   style: {
      //     backgroundColor: "#004f9f",
      //     color: "white",
      //     marginLeft: "auto",
      //     marginTop: "10px",
      //     marginBottom: "10px",
      //     transform: "translateX(-30px)",
      //     "&:hover": {
      //       cursor: "pointer",
      //       backgroundColor: "#004f9fba",
      //     },
      //   },
      // },
    ];

    let csvCompanies = this.state.companies.map((x) => x[1].fields.all);

    const paginationComponentOptions = {
      rowsPerPageText: "Zeilen pro Seite",
      rangeSeparatorText: "von",
    };

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <div className="main-section" style={{ height: "calc(100vh + 70px)" }}>
        <div className="w-[100%] pt-[30px] lg:pt-0">
          <div className="flex justify-between flex-wrap items-center">
            <div className="grid gap-[20px] lg:flex items-center  w-[100%] lg:w-[50%] lg:gap-[30px]">
              <h3 className="text-2xl lg:text-4xl text-[#004F9F] font-normal">
                Unternehmen
              </h3>

              <div className="block ml-auto lg:ml-0 w-full lg:w-[fit-content] lg:h-[32px]">
                <AiOutlineSearch
                  color="#004f9f"
                  className="absolute translate-x-[10px] translate-y-[9px]"
                  size={18}
                />
                <input
                  type="text"
                  name="search"
                  onChange={this.searchCompany}
                  className="placeholder:text-[#004F9F] placeholder:text-[14px] shadow bg-[#fff] border-gray-400 text-gray-900 sm:text-sm rounded-[24px] focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[220px] px-[40px] py-1 lg:py-2 searchinput"
                  placeholder="Unternehmen suchen"
                />
              </div>
            </div>
            <div className="grid place-items-center lg:flex lg:items-center pt-[50px] lg:pt-0 lg:justify-end w-[100%] lg:w-[50%] lg:gap-[30px]">
              {/* {(this.props.userId && this.props.userId.role.id === 1) ||
                (this.props.userId &&
                  this.props.userId.role.name === "Prüfer") ? (
                <div
                  className="text-[#004f9f] cursor-pointer text-center font-normal lg:text-left underline text-[14px] lg:text-[16px] pb-[15px] lg:pb-0"
                  onClick={(e) => exportToCSV(csvCompanies, "Unternehmen")}
                >
                  Liste exportieren
                </div>
              ) : (
                <></>
              )} */}
              <Link
                to="/unternehmen-erfassen"
                className="flex items-center gap-[10px] w-full lg:w-fit justify-center bg-[#004f9f] py-3 px-2.5 lg:py-4 lg:px-5"
              >
                <AddBusinessIcon sx={{ color: "#fff", fontSize: "22px" }} />
                <p className="text-white text-[13px] lg:text-[16px]">
                  Unternehmen erfassen
                </p>
              </Link>
            </div>
          </div>
          <div>
            {/* <div className="flex justify-center lg:justify-end items-center pt-[10px] lg:pt-[30px]">
              <div className="flex items-center justify-center lg:justify-end w-full lg:w-[50%] gap-[5px] translate-y-[15px] z-50">
                <button
                  onClick={() => this.loadOwnCompanies()}
                  to="#"
                  className={`text-[#004f9f] bg-[#fff] text-[14px] lg:text-[16px] border-2 ${
                    own ? "border-[#004f9f]" : "shadow"
                  }  px-3 py-2 `}
                >
                  Meine Unternehmen
                </button>
                <button
                  onClick={() => this.loadAllCompanies()}
                  to="#"
                  className={`text-[#004f9f] bg-[#fff] text-[14px] lg:text-[16px] border-2 ${
                    !own ? "border-[#004f9f]" : "shadow"
                  }  px-3 py-2 `}
                >
                  Alle Unternehmen
                </button>
              </div>
            </div> */}
            <div className=" mt-[60px] lg:mt-[100px]">
              {!isMobile ? <DataTable
                data={companies}
                columns={columns}
                direction="auto"
                fixedHeaderScrollHeight="300px"
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                pagination={isMobile ? false : true}
                progressPending={this.state.loading}
                sortIcon={
                  <BsSortDown
                    sx={{
                      color: "#004f9f",
                      opacity: 1,
                      height: "20px",
                      width: "24px",
                    }}
                  />
                }
                paginationServer
                paginationTotalRows={this.state.totalRows}
                onChangeRowsPerPage={this.handlePerRowsChange}
                onChangePage={this.handlePageChange}
                paginationComponentOptions={paginationComponentOptions}
                progressComponent={
                  <div className="nodatacomponent">
                    <PulseLoader color={"#004f9f"} />{" "}
                  </div>
                }
                noDataComponent={
                  <div className="nodatacomponent">
                    Keine Daten vorhanden...
                  </div>
                }
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
              /> :
                <>
                  {this.state.loading ? <div className="nodatacomponent">
                    <PulseLoader color={"#004f9f"} size={10} />
                  </div> :
                    <div ref={this.ref}>{
                      companies.map((company, i) => (
                        company[1].fields.all.companyname && (
                          <div key={i} className="flex flex-row gap-4 flex-1 px-1 pt-4 pb-2 border-b border-b-[#0000001f]">
                            <div className="flex flex-col flex-wrap flex-1 gap-[13px]">
                              <div className="flex flex-row justify-between gap-[10px]">
                                <Link to={`/unternehmenskarte/${company[1].id}`} className="flex flex-col gap-1 flex-1">
                                  <p className="text-[12px] text-[#004f9f]">Unternehmen</p>
                                  <div className="flex flex-wrap items-start gap-1 text-gray-500">
                                    <p className="text-[12px]">{company[1].fields.all.companyname}</p>
                                  </div>
                                </Link>
                                <div className="flex flex-col gap-1 flex-1 max-w-[30%]">
                                  <p className="text-[12px] text-[#004f9f]">Adresse</p>
                                  <p className="text-gray-500 text-[12px]">{company[1].fields.all.companyaddress1
                                    ? company[1].fields.all.companyaddress1
                                    : company[1].fields.all.companyaddress2
                                      ? company[1].fields.all.companyaddress2
                                      : ""} </p>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between gap-[10px]">
                                <div className="flex flex-col gap-1 flex-1">
                                  <p className="text-[12px] text-[#004f9f]">PLZ</p>
                                  <div className="flex flex-wrap items-start gap-1 text-gray-500">
                                    <p className="text-[12px]">{company[1].fields.all.companyzipcode}</p>
                                  </div>
                                </div>
                                <div className="flex flex-col gap-1 flex-1 max-w-[30%]">
                                  <p className="text-[12px] text-[#004f9f]">Ort</p>
                                  <p className="text-gray-500 text-[12px]">{company[1].fields.all.companycity}</p>
                                </div>
                              </div>
                            </div>
                            <div className="relative w-fit">
                              <div className="w-fit flex items-center">
                                <BiDotsVertical onClick={() => this.setState({ showDetails: this.state.showDetails === i ? -1 : i, showEmails: this.state.showDetails === i && false })} size={24} color="#004f9f" />
                              </div>
                              {this.state.showDetails === i && (
                                <div class={`dropdown-mobile right-0 w-[140px!important] border-2 border-[#004f9f] z-[99999999]`}>
                                  {this.state.showDetails === i && (
                                    <>
                                      <button className="bg-[#EFF7FF] flex items-center gap-[5px] py-2 px-2.5 rounded-lg text-[#004f9f]">
                                        <FaEnvelope size={16} color="#004f9f" />
                                        <p className="text-[12px]">Nachricht senden</p>
                                      </button>
                                      <div class="dropdown-content-mobile">
                                        {company[1].fields.all.companyemail ? (
                                          <>
                                            <div className="group">
                                              <div
                                                onClick={() =>
                                                  this.setState({ showEmails: !showEmails })
                                                }
                                                className="flex relative flex-row items-center justify-between cursor-pointer text-[12px]"
                                              >
                                                E-Mail
                                                <AiOutlineCaretDown />
                                                <div
                                                  onMouseLeave={() =>
                                                    this.setState({ showEmails: !showEmails })
                                                  }
                                                  class={`${showEmails ? "flex" : "hidden"
                                                    } flex-col absolute top-[120%] min-w-[150px] right-[-20px] bg-[#eef7ff] p-[10px] gap-2 `}
                                                >
                                                  {company[1].fields.all.companyemail && (
                                                    <>
                                                      <a
                                                        className="text-[12px]"
                                                        href={`mailto: ${company[1].fields.all.companyemail}`}
                                                      >
                                                        {company[1].fields.all.companyemail}
                                                      </a>
                                                      {company[1].fields.all.companyemailadresse_buchh && (
                                                        <a
                                                          className="text-[12px]"
                                                          href={`mailto: ${company[1].fields.all.companyemailadresse_buchh}`}
                                                        >
                                                          {company[1].fields.all.companyemailadresse_buchh}
                                                        </a>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              </div>

                                            </div>
                                          </>
                                        ) : <p className="text-[10px]">No email provided</p>}

                                        {company[1].fields.all.companyphone ? (
                                          <a className="text-[12px]" href={`sms: ${company[1].fields.all.companyphone}`}>SMS Mobile</a>
                                        ) : <p className="text-[10px]">No mobile provided</p>}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      ))}
                    </div>
                  }
                </>}
              {isMobile && this.state.loadMore && companies.length > 9 ? (
                <div className="flex justify-center">
                  <PulseLoader color={"#004f9f"} size={10} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Companies;

