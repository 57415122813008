import Client from "./connector/index";
import Cookies from "js-cookie";

const token = Cookies.get("token");

const connector = new Client({
  baseUrl: process.env.REACT_APP_PROD_URL || process.env.REACT_APP_DEV_URL,
  auth: token,
});

export default connector;

