class Reports {
  constructor(client) {
    this.client = client;
  }

  get(individualReportId) {
    return this.client.get(`reports/${individualReportId}`);
  }

  list(params = {}) {
    return this.client.list(`reports`, params);
  }
}

export default Reports;
