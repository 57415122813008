class Emails {
  constructor(client) {
    this.client = client;
  }

  get(emailId) {
    return this.client.get(`emails/${emailId}`);
  }

  list(params = {}) {
    return this.client.list("emails", params);
  }

  create(email) {
    delete email.id;
    return this.client.post("emails/new", email);
  }

  edit(emailId, newEmail) {
    return this.client.patch(`emails/${emailId}/edit`, newEmail);
  }

  editOrCreate(emailId, newEmail) {
    return this.client.put(`emails/${emailId}/edit`, newEmail);
  }

  delete(emailId) {
    return this.client.delete(`emails/${emailId}/delete`);
  }

  sendEmailToContact(emailId, contactId, content = []) {
    this.client.post(`emails/${emailId}/contact/${contactId}/send`, content);
  }

  sendEmailToSegment(emailId) {
    this.client.post(`emails/${emailId}/send`);
  }
}

export default Emails;
