class User {
  constructor (client) {
    this.client = client
  }

  get (userId) {
    return this.client.get(`users/${userId}`)
  }

  list (params = {}) {
    return this.client.list('users', params)
  }

  create (user) {
    delete user.id
    return this.client.post('users/new', user)
  }

  edit (userId, user) {
    return this.client.patch(`users/${userId}/edit`, user)
  }

  editOrCreate (userId, user) {
    return this.client.put(`users/${userId}/edit`, user)
  }

  delete (userId) {
    return this.client.delete(`users/${userId}/delete`)
  }

  getSelf () {
    return this.client.get('users/self')
  }

  permissionCheck (userId, permissions) {
    return this.client.get(`users/${userId}/permissioncheck`, permissions)
  }
}

export default User;
