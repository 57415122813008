import {
    Contact,
    Segment,
    Company,
    User,
    Activity,
    Fields,
    Categories,
    History,
    Stages,
    Tags,
    Segments,
    Assets,
    Forms,
    Widgets,
    Reports,
    Campaigns,
    Emails,
    Files,
    MarketingMessages,
    Notes,
    Notifications,
    Pages,
    PointActions,
    PointTriggers,
    Roles,
    TextMessages,
    Stats,
    Themes,
    Tweets,
    WebHooks,
    DynamicContents,
    Settings
} from "./resources";
import axios from "axios";
import {Webhook} from "@mui/icons-material";

const responseWith = (response) => ({
    status: response.status,
    data: response.data || undefined,
});

const responseErrWith = (err) => {
    if (err !== undefined) {
        return {
            status: err.response?.status || undefined,
            data: err.response?.data || undefined,
        }
    }
};

class Client {
    constructor({baseUrl, auth}) {
        this.baseUrl = baseUrl;
        this.headers = {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Basic " + auth,
        };
        this.contacts = new Contact(this);
        this.segment = new Segment(this);
        this.companies = new Company(this);
        this.users = new User(this);
        this.activities = new Activity(this);
        this.fields = new Fields(this);
        this.categories = new Categories(this);
        this.history = new History(this);
        this.stages = new Stages(this);
        this.tags = new Tags(this);
        this.segments = new Segments(this);
        this.assets = new Assets(this);
        this.forms = new Forms(this);
        this.widgets = new Widgets(this);
        this.reports = new Reports(this);
        this.Campaigns = new Campaigns(this);
        this.Emails = new Emails(this);
        this.Files = new Files(this);
        this.MarketingMessages = new MarketingMessages(this);
        this.Notes = new Notes(this);
        this.Notifications = new Notifications(this);
        this.Pages = new Pages(this);
        this.PointActions = new PointActions(this);
        this.PointTriggers = new PointTriggers(this);
        this.Roles = new Roles(this);
        this.TextMessages = new TextMessages(this);
        this.Stats = new Stats(this);
        this.Themes = new Themes(this);
        this.Tweets = new Tweets(this);
        this.WebHooks = new WebHooks(this);
        this.DynamicContents = new DynamicContents(this);
        this.Settings = new Settings(this);
        this.cancelToken = undefined;
    }

    get(resourceUri, params = {}) {
        return axios
            .get(`${this.baseUrl}/api/${resourceUri}`, {
                params: params,
                headers: this.headers,
            })
            .then(responseWith)
            .catch(responseErrWith);
    }

    list(resourceUri, params = "") {
        //Check if there are any previous pending requests
        if (typeof this.cancelToken !== typeof undefined) {
            this.cancelToken.cancel("Operation canceled due to new request.")
        }

        //Save the cancel token for the current request
        this.cancelToken = axios.CancelToken.source()

        let isSearch = false
        if (typeof params === 'string') {
            isSearch = params.includes('search')
        }
        return axios
            .get(`${this.baseUrl}/api/${resourceUri}?${params}`, {
                headers: this.headers,
                cancelToken: isSearch ? this.cancelToken.token : null,
            })
            .then(responseWith)
            .catch(responseErrWith);
    }

    post(resourceUri, payload = {}) {
        return axios
            .post(`${this.baseUrl}/api/${resourceUri}`, payload, {
                headers: this.headers,
            })
            .then(responseWith)
            .catch(responseErrWith);
    }

    put(resourceUri, payload) {
        return axios
            .put(`${this.baseUrl}/api/${resourceUri}`, payload, {
                headers: this.headers,
            })
            .then(responseWith)
            .catch(responseErrWith);
    }

    patch(resourceUri, payload) {
        return axios
            .patch(`${this.baseUrl}/api/${resourceUri}`, payload, {
                headers: this.headers,
            })
            .then(responseWith)
            .catch(responseErrWith);
    }

    delete(resourceUri) {
        return axios
            .delete(`${this.baseUrl}/api/${resourceUri}`, {
                headers: this.headers,
            })
            .then(responseWith)
            .catch(responseErrWith);
    }

    download(resourceUri) {
        return axios({
            url: `${this.baseUrl}/api/${resourceUri}`,
            method: "GET",
            responseType: "blob",
            headers: this.headers,
        })
            .then(responseWith)
            .catch(responseErrWith);
    }
}

export default Client;

