class Fields {
  constructor(client) {
    this.client = client;
  }

  get(object = "contact", objectId) {
    return this.client.get(`fields/${object}/${objectId}`);
  }

  list(object = "contact", params = {}) {
    return this.client.list(`fields/${object}`, params);
  }

  create(object = "contact", field) {
    delete field.id;
    return this.client.post(`fields/${object}/new`, field);
  }

  edit(object = "contact", objectId, field) {
    return this.client.patch(`fields/${object}/${objectId}/edit`, field);
  }

  editOrCreate(object = "contact", objectId, field) {
    return this.client.put(`fields/${object}/${objectId}/edit`, field);
  }

  delete(object = "contact", objectId) {
    return this.client.delete(`fields/${object}/${objectId}/delete`);
  }
}

export default Fields;
