import React from "react";
import { Link } from "react-router-dom";
import connector from "../../../connector";
import { columns } from "../../../reusableComponents/DataTables/APIDataTable";
import DataTable from "react-data-table-component";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineAddTask } from "react-icons/md";
import { BsSortDown } from "react-icons/bs";
import PulseLoader from "react-spinners/PulseLoader";
import Moment from "moment";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { isMobile } from "react-device-detect";
import { BiDotsVertical } from "react-icons/bi";
import { IoMdWarning } from "react-icons/io";

class Activities extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();

    this.state = {
      loading: false,
      error: null,
      myactivities: true,
      bevorstehend: true,
      activities: [],
      tempActivities: [],
      own: true,
      totalRows: 0,
      rowsPerPage: 10,
      search: "",
      expr: "lte",
      start: 0,
      loadMore: false,
    };
  }

  componentDidMount = async () => {
    await this.loadOwnActivities();
    this.scrollDetection();
  };

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    console.log(prevState.rowsPerPage, this.state.rowsPerPage)
    if (prevState.expr !== this.state.expr || prevState.rowsPerPage !== this.state.rowsPerPage) {
      this.state.own
        ? await this.loadOwnActivities(this.state.page)
        : await this.loadAllActivities(this.state.page);
    }
  };

  loadOwnActivities = async (page = 1) => {
    const userDetails = JSON.parse(localStorage.getItem("user-info"));
    const todayDate = Moment().format("YYYY-MM-DD HH:mm:00");

    let url = `where[0][col]=owner&where[0][expr]=eq&where[0][val]=${userDetails.id}&where[1][col]=dueDateActivity&where[1][expr]=${this.state.expr}&where[1][val]=${todayDate}`;

    if (this.state.search.length) {
      url += "&search=" + this.state.search;
    }

    await this.fetchActivities(url, page);
    this.setState({ own: true });
  };

  loadAllActivities = async (page = 1) => {
    const todayDate = Moment().format("YYYY-MM-DD HH:mm:00");
    let url = `&where[0][col]=dueDateActivity&where[0][expr]=${this.state.expr}&where[0][val]=${todayDate}`;
    if (this.state.search.length) {
      url += "&search=" + this.state.search;
    }

    await this.fetchActivities(url, page);
    this.setState({ own: false });
  };

  changeExpr = async (value) => {
    this.setState({ expr: value });
  };

  searchActivity = async (e) => {
    this.setState(
      {
        data: [],
        search: e.target.value.length < 1 ? "" : e.target.value,
      },
      async () => {
        this.state.own
          ? await this.loadOwnActivities(1)
          : await this.loadAllActivities(1);
      }
    );
  };


  fetchActivities = async (url, page = 1) => {
    this.setState({ loading: true });
    let start = page === 1 ? 0 : (page - 1) * this.state.rowsPerPage;
    let query = `limit=${this.state.rowsPerPage}&start=${start}&oderBy=dueDateActivity&orderByDir=DESC`;

    if (url) {
      query += "&" + url;
    }

    let response = await connector.activities.list(encodeURI(query));
    console.log(Object.values(response.data.activities));

    let activitySort = this.state.expr === "gte" ? Object.entries(response.data.activities).sort((a, b) => new Date(a[1].dueDateActivity) - new Date(b[1].dueDateActivity)) : Object.entries(response.data.activities).sort((a, b) => new Date(b[1].dueDateActivity) - new Date(a[1].dueDateActivity))

    this.setState({
      activities: activitySort,
      tempActivities: Object.entries(response.data.activities).sort((a, b) => new Date(a[1].dueDateActivity) - new Date(b[1].dueDateActivity)),
      totalRows: response.data.total,
      loading: false,
    });

    this.state.tempActivities.map((x) => (x[1].activity = x[1].activity.name));
    this.state.tempActivities.map((x) =>
      x[1].documents ? (x[1].documents = "") : ""
    );
    this.state.tempActivities.map((x) =>
      x[1].nextActivity ? (x[1].nextActivity = x[1].nextActivity.name) : ""
    );
    this.state.tempActivities.map(
      (x) => (x[1].owner = x[1].owner.lastName + " " + x[1].owner.firstName)
    );
    this.state.tempActivities.map(
      (x) =>
      (x[1].lead = x[1].lead
        ? x[1].lead.lastname + " " + x[1].lead.firstname
        : "")
    );
  };

  loadMore = async () => {
    this.setState({ loadMore: true });
    this.state.start += 10;
    let url = `limit=10&start=${this.state.start}`;

    let response = await connector.activities.list(encodeURI(url));
    this.setState({
      activities: [
        ...this.state.activities.concat(Object.entries(response.data.activities)),
      ],
      loadMore: false,
    });
  };

  scrollDetection = () => {
    window.addEventListener("scroll", () => {
      const scrollable = document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = window.scrollY

      if (Math.ceil(scrolled) === scrollable && isMobile && !this.state.loadMore && this.state.activities.length > 9) {
        this.loadMore();
      }
    })
  }

  handlePageChange = async (page) => {
    this.state.own
      ? await this.loadOwnActivities(page)
      : await this.loadAllActivities(page);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({ rowsPerPage: newPerPage });
    this.state.own
      ? await this.loadOwnActivities(page)
      : await this.loadAllActivities(page);
  };

  conditionalRowStyles = [
    {
      when: row => (Math.abs(new Date() - new Date(row[1].dueDateActivity)) / 36e5) <= 48,
      style: {
        border: "1px solid red",
        marginBottom: 10,
      },
      classNames: ["row-with-warning"],
    },
  ];

  dueDateContidion = (data) => {
    return (Math.abs(new Date() - new Date(data.dueDateActivity)) / 36e5) <= 48
  }


  render() {
    const { error, own, activities } = this.state;
    const { activityPermissions } = this.props;
    const userDetails = JSON.parse(localStorage.getItem("user-info"));

    let csvActivities = this.state.activities.map((x) => x[1]);

    const paginationComponentOptions = {
      rowsPerPageText: "Zeilen pro Seite",
      rangeSeparatorText: "von",
    };

    if (error) {
      return <div>An error occured: {error.message}</div>;
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <div className="main-section">
        <div className="w-[100%] pt-[30px] lg:pt-0">
          <div className="flex justify-between flex-wrap items-center">
            <div className="grid gap-[20px] lg:flex items-center  w-[100%] lg:w-[50%] lg:gap-[30px]">
              <h3 className="text-2xl lg:text-4xl text-[#004F9F] font-normal">
                Aktivitäten
              </h3>
              <div className="block ml-auto lg:ml-0 w-full lg:w-[fit-content] lg:h-[32px]">
                <AiOutlineSearch
                  color="#004f9f"
                  className="absolute translate-x-[10px] translate-y-[9px]"
                  size={18}
                />
                <input
                  type="text"
                  name="search"
                  onChange={this.searchActivity}
                  className="placeholder:text-[#004F9F] placeholder:text-[14px] shadow bg-[#fff] border-gray-400 text-gray-900 sm:text-sm rounded-[24px] focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[220px] px-[40px] py-1 lg:py-2 searchinput"
                  placeholder="Aktivität suchen"
                />
              </div>
            </div>
            <div className="grid place-items-center lg:flex lg:items-center pt-[50px] lg:pt-0 lg:justify-end w-[100%] lg:w-[50%] lg:gap-[30px]">
              {/* {(this.props.userId && this.props.userId.role.id === 1) ||
                (this.props.userId &&
                  this.props.userId.role.name === "Prüfer") ? (
                <div
                  className="text-[#004f9f] cursor-pointer text-center font-normal lg:text-left underline text-[14px] lg:text-[16px] pb-[15px] lg:pb-0"
                  onClick={(e) => exportToCSV(csvActivities, "Aktivitäten")}
                >
                  Liste exportieren
                </div>
              ) : (
                <></>
              )} */}

              <Link
                onClick={() => (
                  this.props.setAddActivity({}),
                  this.props.setAddActivityFromContacts(false)
                )}
                to="/aktivitat-erfassen"
                className="flex items-center gap-[10px] w-full lg:w-fit justify-center bg-[#004f9f] py-3 px-2.5 lg:py-4 lg:px-5"
              >
                <MdOutlineAddTask size={20} color="#fff" />
                <p className="text-white text-[13px] font-normal lg:text-[16px]">
                  Aktivitäten erfassen
                </p>
              </Link>
            </div>
          </div>
          <div className="mt-[30px] lg:mt-[0]">
            <div className="flex gap-[15px] lg:gap-[0] flex-wrap justify-between items-center pt-[30px] lg:pt-[44px]">
              <div className="flex justify-center lg:justify-start items-center w-[100%] lg:w-[50%] gap-[30px]">
                <button
                  onClick={() => this.changeExpr("gte")}
                  className={`text-[#004f9f] text-[14px] lg:text-[16px] ${this.state.expr === "gte" ? "underline" : ""
                    } `}
                >
                  Bevorstehend
                </button>
                <button
                  onClick={() => this.changeExpr("lte")}
                  className={`text-[#004f9f] text-[14px] lg:text-[16px] ${this.state.expr === "lte" ? "underline" : ""
                    } `}
                >
                  Abgeschlossen
                </button>
              </div>
              <div className="flex items-center justify-center lg:justify-end w-[100%] lg:w-[50%] gap-[15px] lg:gap-[5px] z-50">
                <button
                  onClick={() => this.loadOwnActivities()}
                  to="#"
                  className={`text-[#004f9f] w-[50%] lg:w-fit text-[14px] font-normal lg:text-[16px] bg-[#fff] ${own ? "border-2 border-[#004f9f]" : "shadow"
                    }  px-3 py-2 `}
                >
                  Meine Aktivitäten
                </button>
                {userDetails.role.id === 1 ||
                  userDetails.role.name === "Prüfer" ? (
                  <>
                    <button
                      onClick={() => this.loadAllActivities()}
                      to="#"
                      className={`text-[#004f9f] text-[14px] w-[50%] font-normal lg:w-fit lg:text-[16px] bg-[#fff]  ${!own ? "border-2 border-[#004f9f]" : "shadow"
                        }  px-3 py-2 `}
                    >
                      Alle Aktivitäten
                    </button>
                  </>
                ) : (
                  <>
                    {activityPermissions && activityPermissions[0] ? (
                      <>
                        {activityPermissions[0].includes("viewother") ? (
                          <button
                            onClick={() => this.loadAllActivities()}
                            to="#"
                            className={`text-[#004f9f] text-[14px] w-[50%] font-normal lg:w-fit lg:text-[16px] bg-[#fff]  ${!own ? "border-2 border-[#004f9f]" : "shadow"
                              }  px-3 py-2 `}
                          >
                            Alle Aktivitäten
                          </button>
                        ) : (
                          <button
                            disabled
                            title="Not authorized"
                            className={`text-[#fff] text-[14px] w-[50%] font-normal lg:w-fit lg:text-[16px] bg-gray-400  ${!own ? "border-2 border-[#fff]" : "shadow"
                              }  px-3 py-2 `}
                          >
                            Alle Aktivitäten
                          </button>
                        )}
                      </>
                    ) : (
                      <> </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="w-[100%] mt-[40px] lg:mt-[20px] table-overflow">
              {!isMobile ? <DataTable
                columns={columns}
                data={activities}
                fixedHeaderScrollHeight="300px"
                responsive
                direction="auto"
                subHeaderAlign="right"
                subHeaderWrap
                progressPending={this.state.loading}
                conditionalRowStyles={this.conditionalRowStyles}
                pagination={isMobile ? false : true}
                sortIcon={
                  <BsSortDown
                    sx={{
                      color: "#004f9f",
                      opacity: 1,
                      height: "20px",
                      width: "24px",
                    }}
                  />
                }
                paginationServer
                paginationTotalRows={this.state.totalRows}
                onChangeRowsPerPage={this.handlePerRowsChange}
                onChangePage={this.handlePageChange}
                paginationComponentOptions={paginationComponentOptions}
                progressComponent={
                  <div className="nodatacomponent">
                    <PulseLoader color={"#004f9f"} />{" "}
                  </div>
                }
                noDataComponent={
                  <div className="nodatacomponent">
                    Keine Daten vorhanden...
                  </div>
                }
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
              ></DataTable> :
                <>
                  {this.state.loading ? <div className="nodatacomponent">
                    <PulseLoader color={"#004f9f"} size={10} />
                  </div> :
                    <div ref={this.ref}>{
                      activities.map((item, i) => (
                        <>
                          {this.dueDateContidion(item[1]) && (
                            <div title="The due date of this activity is within 48 hours!" className="w-full flex justify-center mt-2 mb-3">
                              <IoMdWarning size={16} color="red" />
                            </div>
                          )}
                          < Link to={`/aktivitatskarte/${item[1].id}`} key={i} className={`flex flex-row gap-4 flex-1 px-1 pt-4 pb-2 border-b border-b-[#0000001f] ${this.dueDateContidion(item[1]) ? "border border-red-600 border-b-red-600" : ""}`}>
                            <div className={`flex flex-col flex-wrap flex-1 gap-[13px]`}>
                              <div className="flex flex-row justify-between gap-[10px]">
                                <div className="flex flex-col gap-1 flex-1">
                                  <p className="text-[12px] text-[#004f9f]">Zeit</p>
                                  <div className="flex flex-wrap items-start gap-1 text-gray-500 flex-1">
                                    <p className="text-[12px]">{item[1].meetingTime
                                      ? Moment(item[1].meetingTime).format("DD.MM.YYYY")
                                      : Moment(item[1].dateAdded).format("DD.MM.YYYY")}</p>
                                  </div>
                                </div>
                                <div className="flex flex-col gap-1 flex-1">
                                  <p className="text-[12px] text-[#004f9f]">Betreff</p>
                                  <p className="text-gray-500 text-[12px]">{item[1].reference} </p>
                                </div>
                                <div className="flex flex-col gap-1 flex-1">
                                  <p className="text-[12px] text-[#004f9f]">Name Kontakt</p>
                                  <div className="flex flex-wrap items-start gap-1 text-gray-500">
                                    <p className="text-[12px]">{item[1].leads[1]?.lastname}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between gap-[10px]">
                                <div className="flex flex-col gap-1 flex-1">
                                  <p className="text-[12px] text-[#004f9f]">Vorname Kontakt</p>
                                  <p className="text-gray-500 text-[12px]">{item[1].leads[1]?.firstname}</p>
                                </div>
                                <div className="flex flex-col gap-1 flex-1">
                                  <p className="text-[12px] text-[#004f9f]">Unternehmen</p>
                                  <div className="flex flex-wrap items-start gap-1 text-gray-500">
                                    <p className="text-[12px]">{item[1].leads[1]?.company}</p>
                                  </div>
                                </div>
                                <div className="flex flex-col gap-1 flex-1">
                                  <p className="text-[12px] text-[#004f9f]">Aktivität</p>
                                  <p className="text-gray-500 text-[12px]">{item[1].activity.name || item[1].activity}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>
                      ))}
                    </div>
                  }
                </>}
              {isMobile && this.state.loadMore && activities.length > 9 ? (
                <div className="flex justify-center">
                  <PulseLoader color={"#004f9f"} size={10} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default Activities;

