class Segments {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("segments", params);
  }

  get(segmentId) {
    return this.client.get(`segments/${segmentId}`);
  }

  create(segment) {
    delete segment.id;
    this.client.post(`segments/new`, segment);
  }

  edit(segmentId, newsegment) {
    return this.client.patch(`segments/${segmentId}/edit`, newsegment);
  }

  editOrCreate(segmentId, newsegment) {
    return this.client.put(`segments/${segmentId}/edit`, newsegment);
  }
  delete(segmentId) {
    return this.client.delete(`segments/${segmentId}/delete`);
  }

  getSegmentMemberships(segmentId) {
    return this.client.get(`/contacts/${segmentId}/segments`);
  }
}

export default Segments;
