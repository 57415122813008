import React from "react";
import connector from "../../../connector";
import { useNavigate } from "react-router-dom";
import AddModal from "../../../reusableComponents/Modals/AddModal";
import Select from "react-select";
import Datetime from "react-datetime";
import Moment from "moment";
import { connect } from "react-redux";
import { retrieveUsers } from "../../../actions/usersAction";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from 'react-select/async'

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { countries, countriesLocale, doi } from "../../../data";
import { isMobile } from "react-device-detect";

class AddContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modifiedData: {
        image: null,
        lastname: null,
        firstname: null,
        birthday: null,
        company: null,
        anrede: null,
        title: null,
        umgangsform: null,
        position: null,
        preferred_locale: null,
        interests: [],
        doi: false,
        address1: null,
        postfach: null,
        zipcode: null,
        city: null,
        country: null,
        mobile: null,
        phone: null,
        email: null,
        emailadresse_buchhaltung: null,
        linkedin: null,
        notizen: null,
        owner: null,
        contact_status: null,
        origin: null,
      },
      newCompany: {
        fields: {
          all: {
            companyname: "",
          },
        },
      },
      loading: false,
      errors: false,
      companies: [],
      contacts: [],
      creatableText: "Auswählen",
      fields: [],
      noLastName: false,
      tempData: [],
      removeItem: [{ label: "Löschen...", value: null }],
      usersList: [],
    };
  }

  componentDidMount = async () => {
    const user = JSON.parse(localStorage.getItem("user-info"));
    // this.props.retrieveUsers();
    this.setState({ usersList: JSON.parse(localStorage.getItem("usersList")) })

    this.state.modifiedData.owner = user;

    const results = await connector.companies.list(encodeURI(``));
    const response = await connector.fields.list("contact", "limit=100");

    this.setState({
      companies: Object.entries(results.data.companies).map((x) => x[1]),
      fields: Object.values(response.data.fields),
    });
  };

  handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      alert(rejectedFiles[0].errors.map((x) => x.code));
    }
    if (files && files.length > 0) {
      this.state.modifiedData.image = files[0];
    }
  };
  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prev) => ({
      ...prev,
      modifiedData: {
        ...prev.modifiedData,
        [name]: value,
      },
    }));
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    if (
      this.state.modifiedData.lastname === null ||
      this.state.modifiedData.lastname === ""
    ) {
      this.setState({ loading: false, errors: true, noLastName: true });
    } else {
      if (this.state.modifiedData.owner !== null) {
        this.state.modifiedData.owner = this.state.modifiedData.owner.id;
      }
      let response = await connector.contacts.create(this.state.modifiedData);

      if (response.status === 201 || response.status === 200) {
        this.setState({
          loading: false,
          errors: false,
          tempData: response.data,
        });
      } else {
        this.setState({ loading: false, errors: true });
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  redirect = () => {
    const navigate = this.props.navigate;
    navigate("/kontakte");
  };

  handleTitle = (value) => {
    if (value === null) {
      this.state.modifiedData.title = null;
    } else {
      this.state.modifiedData.title = value.value;
    }
  };

  handleAnrede = (value) => {
    if (value === null) {
      this.state.modifiedData.anrede = null;
    } else {
      this.state.modifiedData.anrede = value.value;
    }
  };

  handlePosition = (value) => {
    if (value === null) {
      this.state.modifiedData.position = null;
    } else {
      this.state.modifiedData.position = value.value;
    }
  };

  handleUmgangsform = (value) => {
    if (value === null) {
      this.state.modifiedData.umgangsform = null;
    } else {
      this.state.modifiedData.umgangsform = value.value;
    }
  };

  handleOwnerChange = (value) => {
    if (value.label && value.label === "Löschen...") {
      this.state.modifiedData.owner = null;
    } else {
      this.state.modifiedData.owner = value.id;
    }
  };

  handlePhoneChange = (value) => {
    if (value === null) {
      this.state.modifiedData.phone = null;
    } else {
      this.state.modifiedData.phone = value;
    }
  };

  handleMobileChange = (value) => {
    if (value === null) {
      this.state.modifiedData.mobile = null;
    } else {
      this.state.modifiedData.mobile = value;
    }
  };

  handleStatusChange = (value) => {
    if (value === null) {
      this.state.modifiedData.contact_status = null;
    } else {
      this.state.modifiedData.contact_status = value.value;
    }
  };

  handleCompanyChange = (value) => {
    if (value === null) {
      this.state.modifiedData.company = null
    } else {
      this.state.modifiedData.company = value.fields.all.companyname;
    }
    this.setState({ modifiedData: this.state.modifiedData });
  };

  handleCreate = async (inputValue) => {
    this.state.newCompany.companyname = inputValue;
    this.setState({ creatableText: "Laden..." });

    await connector.companies.create(this.state.newCompany).then((res) =>
      setTimeout(() => {
        this.state.modifiedData.company =
          res.data.company.fields.all.companyname;
        this.setState({ creatableText: "Auswählen" });
      }, 500)
    );

    setTimeout(() => {
      this.setState({ modifiedData: this.state.modifiedData });
    }, 500);
  };

  handleSpracheChange = (value) => {
    if (value.label && value.label === "Löschen...") {
      this.state.modifiedData.preferred_locale = null;
    } else {
      this.state.modifiedData.preferred_locale = value.value;
    }
  };

  handleInteressen = (value) => {
    if (value === null) {
      this.state.modifiedData.interests = null;
    } else {
      this.state.modifiedData.interests = value.map((x) => x.value);
    }
  };

  handleCountryChange = (value) => {
    this.state.modifiedData.country = value.value;
  };

  handleDoi = (value) => {
    if (value === null) {
      this.state.modifiedData.doi = null
    } else {
      this.state.modifiedData.doi = value.value;
    }
  };

  handleSelectChange = (value) => {
    this.state.modifiedData.owner = value.id;
  };

  loadOptions = async (inputValue) => {
    return await connector.companies
      .list(encodeURI(`search=${inputValue}`))
      .then((res) => Object.entries(res.data.companies).map((x) => x[1]));
  };

  render() {
    let inputProps = {
      placeholder: "YYYY/MM/DD",
    };
    const { error, modifiedData, fields, usersList } = this.state;
    const { users } = this.props;
    const loadusers = usersList.map((x) => x[1]).concat(this.state.removeItem);

    let interessen = Object.values(fields).filter(
      (x) => x.alias === "interests"
    );

    let position = Object.values(fields).filter((x) => x.alias === "funktion");

    let title = Object.values(fields).filter((x) => x.alias === "title");

    let umgangsform = Object.values(fields).filter(
      (x) => x.alias === "umgangsform"
    );

    let anrede = Object.values(fields).filter((x) => x.alias === "anrede");

    let contactstatus = Object.values(fields).filter(
      (x) => x.alias === "contact_status"
    );

    let umgangsformFields = umgangsform.map((x) => x.properties.list);

    let anredeFields = anrede.map((x) => x.properties.list);

    let interessenFields = interessen.map((x) => x.properties.list);

    let titleFields = title.map((x) => x.properties.list);

    let contactstatusFields = contactstatus.map((x) => x.properties.list);

    let positionFields = position.map((x) => x.properties.list);

    if (error) {
      return <div>An error occured: {error.message}</div>;
    }

    return (
      <div className="main-section" style={{
        left: !isMobile ? "15rem" : "0",
        width: !isMobile ? "calc(100vw - 12rem)" : "100%",
        padding: !isMobile ? "0 60px 50px 60px" : "0",
      }}>
        <div className="w-[100%] p-[30px]">
          <div className="flex w-100 justify-end relative mobile-tablet-none mb-3">
            <AddModal
              loading={this.state.loading}
              submit={this.handleSubmit}
              redirect={this.redirect}
              errors={this.state.errors}
            />
          </div>
          <div className="flex flex-wrap justify-between items-center pb-[20px]">
            <div
              className={`flex flex-wrap items-center w-full justify-between lg:justify-between border-b-[1px] border-[#004f9f] pb-2`}
            >
              <div
                className={`flex flex-wrap lg:flex-nowrap flex-1 justify-betweenw-full gap-7 lg:gap-10`}
              >
                <div
                  className={`flex flex-row justify-center lg:justify-between gap-7`}
                >
                  <div
                    className={`flex flex-col w-full lg:w-fit`}
                  >
                    <p className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]">
                      Name
                    </p>
                    <input
                      type="text"
                      required
                      name="lastname"
                      onChange={this.handleInputChange}
                      value={modifiedData.lastname}
                      className="placeholder:text-gray-500 placeholder:text-[12px] lg placeholder:text-[12px]:lg:placeholder:text-[14px] bg-transparent border-b border-[#d7d7d7] text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-none focus:outline-none block w-full lg:w-[180px] px-[8px] py-1.5"
                      placeholder="Name"
                    />
                  </div>
                  <div
                    className={`flex flex-col w-full lg:w-fit`}
                  >
                    <p className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]">
                      Vorname
                    </p>
                    <input
                      type="text"
                      name="firstname"
                      onChange={this.handleInputChange}
                      value={modifiedData.firstname}
                      className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px] bg-transparent border-b border-[#d7d7d7] text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-none focus:outline-none block w-full lg:w-[180px] px-[8px] py-1.5"
                      placeholder="Vorname"
                    />
                  </div>
                </div>

                <div
                  className={`flex flex-col w-[46%] lg:w-[35%]`}
                >
                  <p className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]">
                    Unternehmen
                  </p>
                  <>
                    <AsyncSelect
                      classNamePrefix="transparent-bg"
                      cacheOptions
                      isClearable
                      defaultOptions
                      getOptionLabel={(e) =>
                        e.fields ? e.fields.all.companyname : ""
                      }
                      getOptionValue={(e) =>
                        e.fields ? e.fields.all.companyname : ""
                      }
                      loadOptions={this.loadOptions}
                      onChange={this.handleCompanyChange}
                      placeholder="Auswählen..."
                    />
                  </>
                </div>
                <div
                  className={`flex flex-row flex-1 justify-end w-[100%]`}
                >
                  <div
                    className={`flex flex-col lg:w-full`}
                  >
                    <p className="text-[#004f9f] break-all text-[12px] lg:text-[14px] font-[400]">
                      Status
                    </p>
                    <Select
                      isSearchable={true}
                      classNamePrefix="transparent-bg"
                      name="contact_status"
                      getOptionLabel={(e) => `${e.label} `}
                      getOptionValue={(e) => e.value}
                      options={contactstatusFields[0]}
                      onChange={this.handleStatusChange}
                      placeholder={
                        modifiedData.contact_status
                          ? modifiedData.contact_status
                          : "Auswählen..."
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between items-center pb-[20px]">
            <div className="lex flex-wrap items-center w-full justify-between lg:justify-between">
              <div className="flex flex-wrap lg:flex-nowrap justify-between flex-1 w-[100%]">
                <div className="grid gap-[35px] w-[49%] lg:w-[25%]">
                  <div
                    className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                      Anrede
                    </h2>
                    <Select
                      className="w-full"
                      classNamePrefix="transparent-bg"
                      isSearchable={true}
                      isClearable
                      name="anrede"
                      options={anredeFields[0]}
                      onChange={this.handleAnrede}
                      placeholder="Auswählen..."
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                      Titel
                    </h2>
                    <Select
                      className="w-full"
                      classNamePrefix="transparent-bg"
                      isSearchable={true}
                      isClearable
                      options={titleFields[0]}
                      onChange={this.handleTitle}
                      placeholder="Auswählen..."
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                      Umgangsform
                    </h2>
                    <Select
                      className="w-full"
                      classNamePrefix="transparent-bg"
                      isSearchable={true}
                      isClearable
                      getOptionLabel={(e) => `${e.label} `}
                      getOptionValue={(e) => e.value}
                      name="umgangsform"
                      options={umgangsformFields[0]}
                      onChange={this.handleUmgangsform}
                      placeholder="Auswählen..."
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                      Funktion
                    </h2>
                    <Select
                      className="w-full"
                      classNamePrefix="transparent-bg"
                      isSearchable={true}
                      isClearable={true}
                      name="position"
                      options={positionFields[0]}
                      onChange={this.handlePosition}
                      placeholder="Auswählen..."
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full">
                      Geburtstag
                    </h2>
                    <Datetime
                      inputProps={inputProps}
                      input={true}
                      disableTimePicker={true}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={(e) =>
                      (modifiedData.birthday = Moment(e._d).format(
                        "YYYY-MM-DD"
                      ))
                      }
                      className="placeholder:text-gray-500 placeholder text-[12px]:lg:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border border-[#d7d7d7] text-gray-900 text-[12px] lg:text-[14px] rounded-[6px] focus:outline-none block w-full px-[8px] py-1.5 input-with-image focus-visible:outline-none small-date-input"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[35px] pl-[2%] lg:pl-0 w-[48%] lg:w-[30%] relative">
                  <div
                    className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                      Sprache
                    </h2>
                    <Select
                      className="w-full"
                      classNamePrefix="transparent-bg"
                      isSearchable={true}
                      isClearable
                      name="preferred_locale"
                      options={countriesLocale}
                      onChange={this.handleSpracheChange}
                      placeholder="Auswählen..."
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                      Interessen
                    </h2>
                    <Select
                      classNamePrefix="transparent-bg"
                      isMulti
                      className="w-full"
                      isSearchable={true}
                      isClearable
                      name="interests"
                      getOptionLabel={(e) => `${e.label} `}
                      getOptionValue={(e) => e.value}
                      options={interessenFields[0]}
                      onChange={this.handleInteressen}
                      placeholder="Auswählen..."
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                      Ursprung
                    </h2>
                    <input
                      type="text"
                      name="origin"
                      onChange={this.handleInputChange}
                      className="placeholder:text-gray-500 placeholder text-[12px]:lg:text-[14px] border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 text-[12px] sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                      placeholder="Ursprung"
                    />
                  </div>
                  <div className="flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full">
                    <h2 className="text-[#004f9f] text-[16px] font-[600] w-full lg:w-[112px]">
                      Double-Opt In
                    </h2>
                    <Select
                      className="w-full"
                      classNamePrefix="transparent-bg"
                      isSearchable={true}
                      isClearable
                      name="doi"
                      options={doi}
                      onChange={this.handleDoi}
                      placeholder="Auswählen..."
                    />
                  </div>
                </div>
                <div className="hidden lg:grid gap-[35px] w-[35%] ">
                  <div className="grid lg:pt-[20px]">
                    <>
                      <textarea
                        rows="8"
                        cols="50"
                        name="notizen"
                        onChange={this.handleInputChange}
                        value={modifiedData.notizen}
                        className={`placeholder:text-[12px] lg:placeholder:text-[14px] shadow  bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f]`}
                        placeholder="Bemerkung"
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-wrap items-start pt-0 lg:pt-[30px] pb-[30px] gap-[35px]`}
          >
            <div className="flex flex-wrap justify-between items-center w-full pb-[20px]">
              <div className="lex flex-wrap items-center w-full justify-between lg:justify-between">
                <div className="flex justify-between flex-1 w-[100%]">
                  <div className="grid gap-[35px] w-[49%] lg:w-[25%]">
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 pt-[20px] justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full">
                        Adresse
                      </h2>
                      <input
                        type="text"
                        name="address1"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                        placeholder="Adresse"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[112px]">
                        Postfach
                      </h2>
                      <input
                        type="text"
                        name="postfach"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg placeholder:text-[12px]:lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                        placeholder="Postfach"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[500]  w-full lg:w-[112px]">
                        PLZ
                      </h2>
                      <input
                        type="text"
                        name="zipcode"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                        placeholder="PLZ"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[112px]">
                        Ort
                      </h2>
                      <input
                        type="text"
                        name="city"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                        placeholder="Ort"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[112px]">
                        Land
                      </h2>
                      <Select
                        classNamePrefix="transparent-bg"
                        className="w-full"
                        isSearchable={true}
                        isClearable={true}
                        getOptionLabel={(e) => `${e.label}`}
                        getOptionValue={(e) => e.value}
                        name="country"
                        options={countries}
                        onChange={this.handleCountryChange}
                        placeholder="Auswählen..."
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-[35px] pl-[2%] lg:pl-0 w-[48%] lg:w-[30%] relative">
                    <div
                      className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                        Mobile
                      </h2>
                      <PhoneInput
                        placeholder="Mobile"
                        onChange={this.handleMobileChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] w-full flex gap-[10px] px-[8px] py-1.5"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                        Telefon
                      </h2>
                      <PhoneInput
                        placeholder="Telefon"
                        onChange={this.handlePhoneChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 w-full flex gap-[10px] px-[8px] py-1.5"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 pt-0 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                        Email geschäftlich
                      </h2>
                      <input
                        type="email"
                        name="email"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                        placeholder="Email geschäftlich"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                        Email privat
                      </h2>
                      <input
                        type="email"
                        name="emailadresse_buchhaltung"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                        placeholder="Email privat"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]  w-full lg:w-[150px]">
                        LinkedIn
                      </h2>
                      <input
                        type="text"
                        name="linkedin"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-500 placeholder:text-[12px] lg:placeholder:text-[14px]  border boder-gray-400 lg:border-0 shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5"
                        placeholder="LinkedIn"
                      />
                    </div>
                  </div>
                  <div className="hidden lg:grid gap-[35px] w-[35%]">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid lg:hidden gap-[35px] w-full ">
            <div className="grid lg:pt-[20px]">
              <>
                <textarea
                  rows="8"
                  cols="50"
                  name="notizen"
                  onChange={this.handleInputChange}
                  value={modifiedData.notizen}
                  className={`placeholder:text-[12px] lg:placeholder:text-[14px] shadow ${modifiedData.lastname === null
                    ? "bg-[#d3d3d5]"
                    : "bg-transparent"
                    } border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f]`}
                  placeholder="Bemerkung"
                />
              </>
            </div>
          </div>
          <div className="flex w-100 justify-center relative desktop-none mt-[30px]">
            <AddModal
              loading={this.state.loading}
              submit={this.handleSubmit}
              redirect={this.redirect}
              errors={this.state.errors}
            />
          </div>
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

export default connect(mapStateToProps, {
  retrieveUsers,
})((props) => <AddContact {...props} navigate={useNavigate()} />);

