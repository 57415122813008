import React, { Component } from "react";
import DataTable from "react-data-table-component";
import PulseLoader from "react-spinners/PulseLoader";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsSortDown } from "react-icons/bs";

export const columns = [
  {}
];

export class CompanyDataTable extends React.Component {
  render() {
    return (
      <DataTable
        data={this.props.data}
        columns={columns}
        direction="auto"
        fixedHeaderScrollHeight="300px"
        pagination
        sortIcon={
          <BsSortDown
            sx={{ color: "#004f9f", opacity: 1, height: "20px", width: "24px" }}
          />
        }
        responsive
        subHeaderAlign="right"
        subHeaderWrap
        progressPending={this.props.loading}
        progressComponent={
          <div className="nodatacomponent">
            <PulseLoader color={"#004f9f"} />{" "}
          </div>
        }
        noDataComponent={this.props.nodata}
      />
    );
  }
}

