class PointActions {
  constructor(client) {
    this.client = client;
  }

  get(pointId) {
    return this.client.get(`points/${pointId}`);
  }

  list(params = {}) {
    return this.client.list("points", params);
  }

  create(point) {
    delete point.id;
    return this.client.post("points/new", point);
  }

  edit(pointId, newpoint) {
    return this.client.patch(`points/${pointId}/edit`, newpoint);
  }

  editOrCreate(pointId, newpoint) {
    return this.client.put(`points/${pointId}/edit`, newpoint);
  }

  delete(pointId) {
    return this.client.delete(`points/${pointId}/delete`);
  }

  getPointActionTypes() {
    return this.client.get(`points/actions/types`);
  }
}

export default PointActions;
