import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import { BrowserRouter } from "react-router-dom";


const Root = () => {
  return (
    < Provider store={store} >
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </Provider >
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
