class History {
  constructor(client) {
    this.client = client;
  }

  get(contactId, params = {}) {
    return this.client.get(`contacts/${contactId}/events?${params}`);
  }
}

export default History;
