import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { CgClose } from "react-icons/cg";
import { BiLogOut } from "react-icons/bi";
import { MdContacts } from "react-icons/md";
import StoreIcon from "@mui/icons-material/Store";
import Cookies from "js-cookie";
import TaskIcon from "@mui/icons-material/Task";

function Sidebar(props) {
  const [showSidebar, setShowSidebar] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/aktivitaten") || location.pathname.includes("/aktivitatskarte") || location.pathname.includes("/aktivitat-erfassen")) {
      props.setActiveItem(1)
    } else if (location.pathname.includes("/kontakte") || location.pathname.includes("/kontaktkarte") || location.pathname.includes("/kontakt-erfassen")) {
      props.setActiveItem(2)
    } else if (location.pathname.includes("/unternehmen") || location.pathname.includes("/unternehmenskarte") || location.pathname.includes("/unternehmen-erfassen")) {
      props.setActiveItem(3)
    } else {
      props.setActiveItem(0)
    }
  }, [location])

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      var neededAttributes = {
        // Here you pass the same attributes that were used when the cookie was created
        // and are required when removing the cookie
      };
      Cookies.remove(cookieName, neededAttributes);
    });
    window.location.href = "/";
  }


  function homeReload() {
    props.setActiveItem(0);
    navigate("/");
  }

  return (
    <>
      <div className="desktop-none bg-[#fff] border-b-2 border-[#daeaff]">
        {showSidebar ? (
          <>
            <button
              className="flex text-4xl text-[#004f9f] items-center cursor-pointer fixed right-4 top-6 z-[10000]"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <CgClose size={35} color="#004f9f" />
            </button>
            <button
              onClick={() => homeReload()}
              className="w-[fit-content] block mx-auto py-[15px]"
            >
              <img
                src={require("../../assets/images/logo.png")}
                alt="Logo"
                className="w-[80px]"
              />
            </button>
          </>
        ) : (
          <>
            <svg
              onClick={() => setShowSidebar(!showSidebar)}
              className="absolute z-[10000] flex items-center cursor-pointer right-10 top-[36px]"
              fill="#004f9f"
              viewBox="0 0 100 80"
              width="40"
              height="40"
            >
              <rect width="100" height="10"></rect>
              <rect y="30" width="100" height="10"></rect>
              <rect y="60" width="100" height="10"></rect>
            </svg>

            <button
              onClick={() => homeReload()}
              to="/"
              className="w-[fit-content] block mx-auto py-[15px]"
            >
              <img
                src={require("../../assets/images/logo.png")}
                alt="Logo"
                className="w-[80px]"
              />
            </button>
          </>
        )}
        <aside
          className={`w-[13rem] fixed h-screen right-0 top-0 z-[9998] bg-[#fff] ease-in-out duration-300  ${showSidebar ? "translate-x-[0]" : "translate-x-[16rem]"
            }`}
          aria-label="Sidebar"
        >
          <div className="h-screen overflow-y-hidden py- bg-[#fff] border-r-2 border-[#daeaff]">
            <ul className="translate-y-[100px] h-[90%]">
              {(props.userId && props.userId.role.id === 1) ||
                (props.userId && props.userId.role.name === "Prüfer") ? (
                <li>
                  <Link
                    to="/aktivitaten"
                    className={`flex ${props.activeItem === 1
                      ? "bg-blue-100 text-[#004f9f]"
                      : "text-[#707070]"
                      } items-center py-4 px-[0.3rem] text-base font-normal  hover:bg-blue-100 text-[#707070]`}
                  >
                    <span
                      onClick={() => setShowSidebar(false)}
                      className="ml-3 pl-[10%] text-left flex items-center gap-[15px]"
                    >
                      <TaskIcon
                        sx={{ color: "#004f9f", width: "22px", heigth: "22px" }}
                      />
                      Aktivitäten
                    </span>
                  </Link>
                </li>
              ) : (
                <>
                  <>
                    {JSON.parse(localStorage.getItem("isAuthorized")) ===
                      true ? (
                      <li>
                        <Link
                          to="/aktivitaten"
                          className={`flex ${props.activeItem === 1
                            ? "bg-blue-100 text-[#004f9f]"
                            : "text-[#707070]"
                            } items-center py-4 px-[0.3rem] text-base font-normal  hover:bg-blue-100 text-[#707070]`}
                        >
                          <span
                            onClick={() => setShowSidebar(false)}
                            className="ml-3 pl-[10%] text-left flex items-center gap-[15px]"
                          >
                            <TaskIcon
                              sx={{
                                color: "#004f9f",
                                width: "22px",
                                heigth: "22px",
                              }}
                            />
                            Aktivitäten
                          </span>
                        </Link>
                      </li>
                    ) : (
                      <>
                        <li>
                          <button
                            disabled
                            title="Not authorized"
                            className={`flex 
                   items-center py-4 px-[0.35rem] text-[18px] font-normal text-[#707070]`}
                          >
                            <span className="pl-[15%] lg:pl-[40%] ml-3 text-left flex items-center gap-[15px]">
                              <TaskIcon
                                sx={{ color: "#707070" }}
                                size="small"
                              />
                              Aktivitäten
                            </span>
                          </button>
                        </li>
                      </>
                    )}
                  </>
                </>
              )}

              <li>
                <Link
                  to="/kontakte"
                  className={`flex ${props.activeItem === 2
                    ? "bg-blue-100 text-[#004f9f]"
                    : "text-[#707070]"
                    } items-center py-4 px-2 text-base font-normal  hover:bg-blue-100 text-[#707070]`}
                >
                  <span
                    onClick={() => setShowSidebar(false)}
                    className="ml-3 pl-[10%] text-left flex items-center gap-[15px]"
                  >
                    <MdContacts color="#004f9f" size={18} />
                    Kontakte
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/unternehmen"
                  className={`flex ${props.activeItem === 3
                    ? "bg-blue-100 text-[#004f9f]"
                    : "text-[#707070]"
                    } items-center py-4 px-[0.4rem] text-base font-normal hover:bg-blue-100 text-[#707070]`}
                >
                  <span
                    onClick={() => setShowSidebar(false)}
                    className="ml-3 pl-[10%] text-left flex items-center gap-[15px]"
                  >
                    <StoreIcon
                      sx={{ color: "#004f9f", width: "22px", heigth: "22px" }}
                    />
                    Unternehmen
                  </span>
                </Link>
              </li>
              <li>
                <button
                  onClick={() => logout()}
                  className="flex items-center py-4 px-2 text-base font-normal hover:bg-blue-100 text-[#707070] w-full absolute bottom-[30px]"
                >
                  <span
                    onClick={() => setShowSidebar(false)}
                    className="ml-3 pl-[10%] text-left flex items-center gap-[15px]"
                  >
                    <BiLogOut color="#004f9f" size={18} />
                    Logout
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </aside>
      </div>
      <div className="mobile-tablet-none">
        <aside
          className="w-72 fixed h-screen left-0 z-[9998] bg-[#fff] ease-in-out duration-300"
          aria-label="Sidebar"
        >
          <div className="h-screen overflow-y-auto py- bg-[#fff] border-r-2 border-[#daeaff]">
            <button
              onClick={() => homeReload()}
              to="/"
              className="w-[fit-content] block mx-auto py-10"
            >
              <img
                src={require("../../assets/images/logo.png")}
                alt="Logo"
                className="w-[80px]"
              />
            </button>
            <ul className="space-y-2 lg:pt-8">
              {(props.userId && props.userId.role.id === 1) ||
                (props.userId && props.userId.role.name === "Prüfer") ? (
                <li>
                  <Link
                    to="/aktivitaten"
                    className={`flex ${props.activeItem === 1
                      ? "bg-blue-100 text-[#004f9f]"
                      : "text-[#707070]"
                      }
                   items-center py-4 px-[0.35rem] text-[18px] font-normal  hover:bg-blue-100 hover:text-[#004f9f] `}
                  >
                    <span className="pl-[20%] ml-3 text-left flex items-center gap-[15px]">
                      <TaskIcon sx={{ color: "#004f9f" }} size="small" />
                      Aktivitäten
                    </span>
                  </Link>
                </li>
              ) : (
                <>
                  <>
                    {JSON.parse(localStorage.getItem("isAuthorized")) ===
                      true ? (
                      <li>
                        <Link
                          to="/aktivitaten"
                          className={`flex ${props.activeItem === 1
                            ? "bg-blue-100 text-[#004f9f]"
                            : "text-[#707070]"
                            }
                   items-center py-4 px-[0.35rem] text-[18px] font-normal  hover:bg-blue-100 hover:text-[#004f9f] `}
                        >
                          <span className="pl-[20%] ml-3 text-left flex items-center gap-[15px]">
                            <TaskIcon sx={{ color: "#004f9f" }} size="small" />
                            Aktivitäten
                          </span>
                        </Link>
                      </li>
                    ) : (
                      <>
                        <li>
                          <button
                            disabled
                            title="Not authorized"
                            className={`flex 
                   items-center py-4 px-[0.35rem] text-[18px] font-normal text-[#707070]`}
                          >
                            <span className="pl-[40%] ml-3 text-left flex items-center gap-[15px]">
                              <TaskIcon
                                sx={{ color: "#707070" }}
                                size="small"
                              />
                              Aktivitäten
                            </span>
                          </button>
                        </li>
                      </>
                    )}
                  </>
                </>
              )}
              <li>
                <Link
                  to="/kontakte"
                  className={`flex ${props.activeItem === 2
                    ? "bg-blue-100 text-[#004f9f]"
                    : "text-[#707070]"
                    } items-center py-4 px-2 text-[18px] font-normal hover:bg-blue-100 hover:text-[#004f9f] text-[#707070]`}
                >
                  <span className="pl-[20%] ml-3 text-left flex items-center gap-[15px]">
                    <MdContacts color="#004f9f" size={20} />
                    Kontakte
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/unternehmen"
                  className={`flex ${props.activeItem === 3
                    ? "bg-blue-100 text-[#004f9f]"
                    : "text-[#707070]"
                    } items-center py-4 px-2 text-[18px] font-normal  hover:bg-blue-100 hover:text-[#004f9f] text-[#707070]`}
                >
                  <span className="pl-[20%] ml-3 text-left flex items-center gap-[15px]">
                    <StoreIcon sx={{ color: "#004f9f", fontSize: "24px" }} />
                    Unternehmen
                  </span>
                </Link>
              </li>
              <li>
                <button
                  onClick={() => logout()}
                  className="flex items-center py-4 px-2 text-[18px] font-normal  hover:bg-blue-100 hover:text-[#004f9f] text-[#707070] w-full absolute bottom-[30px]"
                >
                  <span className="pl-[20%] ml-3 text-left flex items-center gap-[15px]">
                    <BiLogOut color="#004f9f" size={20} />
                    Logout
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </>
  );
}

export default Sidebar;
