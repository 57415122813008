class Notes {
  constructor(client) {
    this.client = client;
  }

  get(noteId) {
    return this.client.get(`notes/${noteId}`);
  }

  list(params = {}) {
    return this.client.list("notes", params);
  }

  create(note) {
    delete note.id;
    return this.client.post("notes/new", note);
  }

  edit(noteId, newNote) {
    return this.client.patch(`notes/${noteId}/edit`, newNote);
  }

  editOrCreate(noteId, newNote) {
    return this.client.put(`notes/${noteId}/edit`, newNote);
  }

  delete(noteId) {
    return this.client.delete(`notes/${noteId}/delete`);
  }
}

export default Notes;
