class Campaigns {
  constructor(client) {
    this.client = client;
  }

  get(campaignId) {
    return this.client.get(`campaigns/${campaignId}`);
  }

  list(params = {}) {
    return this.client.get(`campaigns`, params);
  }

  create(campaign) {
    delete campaign.id;
    return this.client.post(`campaigns/new`, campaign);
  }

  clone(campaignId) {
    return this.client.post(`campaigns/clone/${campaignId}`);
  }

  edit(campaignId, newCampaign) {
    return this.client.put(`campaigns/${campaignId}/edit`, newCampaign);
  }

  delete(campaignId) {
    return this.client.delete(`campaigns/${campaignId}/delete`);
  }

  addContact(campaignId, contactId) {
    return this.client.post(`campaigns/${campaignId}/contact/${contactId}/new`);
  }

  removeContact(campaignId, contactId) {
    return this.client.post(
      `campaigns/${campaignId}/contact/${contactId}/remove`
    );
  }
}

export default Campaigns;

