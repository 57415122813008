import { RETRIEVE_STANDARDTYPE, RETRIEVE_NEXTTYPE } from "../actions/types";

const initialState = [];

function typesReducer(types = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_STANDARDTYPE:
      return payload;

    case RETRIEVE_NEXTTYPE:
      return payload;

    default:
      return types;
  }
}

export default typesReducer;
