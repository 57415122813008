class Tags {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("tags", params);
  }

  get(tagId) {
    return this.client.get(`tags/${tagId}`);
  }

  create(tag) {
    delete tag.id;
    return this.client.post(`tags/new`, tag);
  }

  edit(tagId, newtag) {
    return this.client.patch(`tags/${tagId}/edit`, newtag);
  }

  editOrCreate(tagId, newtag) {
    return this.client.put(`tags/${tagId}/edit`, newtag);
  }

  delete(tagId) {
    return this.client.delete(`tags/${tagId}/delete`);
  }
}

export default Tags;
