import React, { useEffect, useState } from "react";
import Cookie from "js-cookie";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import "./App.css";
import connector from "./connector";
import localConnector from "./connector"

import Login from "./components/pages/login";
import Activities from "./components/pages/activities/activities";
import ActivityCard from "./components/pages/activities/activitycard";
import AddActivity from "./components/pages/activities/addactivity";
import Contacts from "./components/pages/contacts/contacts";
import ContactCard from "./components/pages/contacts/contactcard";
import AddContact from "./components/pages/contacts/addcontact";
import Companies from "./components/pages/companies/companies";
import CompanyCard from "./components/pages/companies/companycard";
import AddCompany from "./components/pages/companies/addcompany";
import Sidebar from "./components/sidebar";
import Homepage from "./components/pages/homepage";
import { Fragment } from "react";

function App() {
  const { pathname } = useLocation();

  const [addActivity, setAddActivity] = useState("");
  const [addActivityFromContacts, setAddActivityFromContacts] = useState(false);
  const [contactPermissions, setContactPermissions] = useState([]);
  const [companyPermissions, setCompanyPermissions] = useState([]);
  const [activityPermissions, setActivityPermissions] = useState([]);
  const [activeItem, setActiveItem] = useState(0)
  const [userId, setUserId] = useState(null);

  useEffect(async () => {
    const user = JSON.parse(localStorage.getItem("user-info"));

    const response = await connector.users.get(user.id);
    // const response2 = await connector.users.getSelf();

    let permisionsFromData = response.data.user.role.rawPermissions
      ? Object.entries(response.data.user.role.rawPermissions).map((x) => x)
      : null;

    let filteredContactPermission = permisionsFromData
      ? permisionsFromData.filter((x) => x[0] === "lead:leads")
      : null;

    let filteredActivityPermission = permisionsFromData
      ? permisionsFromData.filter((x) => x[0] === "activity:activities")
      : null;

    let filteredCompanyPermission = permisionsFromData
      ? permisionsFromData.filter((x) => x[0] === "lead:leads")
      : null;

    setContactPermissions(
      filteredContactPermission
        ? filteredContactPermission.map((x) => x[1])
        : null
    );

    setActivityPermissions(
      filteredActivityPermission
        ? filteredActivityPermission.map((x) => x[1])
        : null
    );

    setCompanyPermissions(
      filteredCompanyPermission
        ? filteredCompanyPermission.map((x) => x[1])
        : null
    );

    setUserId(user);

    if (Cookie.get("token") && Cookie.get("remember") === "false") {
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        Cookie.remove("token");
        Cookie.remove("remember");
        alert("Sitzung abgelaufen, bitte melden Sie sich erneut an");
        window.location.href = "/login";
      }, 3600000);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const retrieveSettings = async () => {
      const response = await localConnector.Settings.list();
      if (response.status === 200) {
        localStorage.setItem("settings", JSON.stringify(response.data))
      }
    }

    retrieveSettings();
  }, [])

  let user = JSON.parse(localStorage.getItem("user-info"));

  let isAuthorized =
    activityPermissions && activityPermissions[0]
      ? activityPermissions[0].includes("viewown") ||
        activityPermissions[0].includes("viewother") ||
        activityPermissions[0].includes("full")
        ? true
        : false
      : false;

  let isAuthorizedToAdd =
    activityPermissions && activityPermissions[0]
      ? activityPermissions[0].includes("create") ||
        activityPermissions[0].includes("full")
        ? true
        : false
      : false;

  if (user?.role.id === 1 || user?.role.name === "Prüfer") {
    isAuthorized = true;
    isAuthorizedToAdd = true;
  }
  localStorage.setItem("isAuthorized", isAuthorized);
  localStorage.setItem("isAuthorizedToAdd", isAuthorizedToAdd);

  return (
    <Fragment>
      <Sidebar activityPermissions={activityPermissions} userId={userId} activeItem={activeItem} setActiveItem={setActiveItem} />
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            !Cookie.get("token") ? (
              <Login />
            ) : (
              <Homepage
                activityPermissions={activityPermissions}
                userId={userId}
              />
            )
          }
        />
        <Route
          path="/aktivitaten"
          element={
            !Cookie.get("token") ||
              JSON.parse(localStorage.getItem("isAuthorized")) !== true ? (
              <Login />
            ) : (
              <Activities
                userId={userId}
                activityPermissions={activityPermissions}
              />
            )
          }
        />
        <Route
          path="/aktivitatskarte/:id"
          element={
            !Cookie.get("token") ||
              JSON.parse(localStorage.getItem("isAuthorized")) !== true ? (
              <Login />
            ) : (
              <ActivityCard
                activityPermissions={activityPermissions}
                userId={userId}
              />
            )
          }
        />

        <Route
          path="/aktivitat-erfassen"
          element={
            !Cookie.get("token") ? (
              <Login />
            ) : (
              <AddActivity
                addActivity={addActivity}
                addActivityFromContacts={addActivityFromContacts}
              />
            )
          }
        />
        <Route
          path="/kontakte"
          element={
            !Cookie.get("token") ? <Login /> : <Contacts userId={userId} />
          }
        />
        <Route
          path="/kontaktkarte/:id"
          element={
            !Cookie.get("token") ? (
              <Login />
            ) : (
              <ContactCard
                setAddActivity={setAddActivity}
                setAddActivityFromContacts={setAddActivityFromContacts}
                contactPermissions={contactPermissions}
                userId={userId}
              />
            )
          }
        />
        <Route
          path="/kontakt-erfassen"
          element={!Cookie.get("token") ? <Login /> : <AddContact />}
        />
        <Route
          path="/unternehmen"
          element={
            !Cookie.get("token") ? <Login /> : <Companies userId={userId} />
          }
        />
        <Route
          path="/unternehmenskarte/:id"
          element={
            !Cookie.get("token") ? (
              <Login />
            ) : (
              <CompanyCard
                companyPermissions={companyPermissions}
                userId={userId}
              />
            )
          }
        />
        <Route
          path="/unternehmen-erfassen"
          element={!Cookie.get("token") ? <Login /> : <AddCompany />}
        />
      </Routes>
    </Fragment>
  );
}
export default App;
