import React from "react";
import DataTable from "react-data-table-component";
import { HiOutlineExternalLink } from "react-icons/hi";
import { AiOutlineEye, AiOutlineHistory } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../reusableComponents/Modals/DeleteModal";
import EditModal from "../../../reusableComponents/Modals/EditModal";
import Select from "react-select";
import { countries, countriesLocale } from "../../../data";
import { BsPencil, BsSortDown } from "react-icons/bs";
import PulseLoader from "react-spinners/PulseLoader";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import connector from "../../../connector";
import { isMobile } from "react-device-detect";
import { FiPhoneCall } from "react-icons/fi";
import moment from "moment";
import Contacts from "./tabs/contacts";
import Activities from "./tabs/activities";
import localConnector from "../../../connector";


class CompanyCard extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      modifiedData: {
        image: null,
        companyname: null,
        companykundennummer: null,
        companycategory: null,
        companypreferred_locale: null,
        companyaddress1: null,
        companyaddress2: null,
        companypostfach: null,
        companyzipcode: null,
        companycity: null,
        companystate: null,
        companywebsite: null,
        companyphone: null,
        companyphone3: null,
        companyemail: null,
        companyemailadresse_buchh: null,
        companylinkedin: null,
        companyindustry: null,
        companybusinessrelation: null,
        companylanguage: null,
        companynotize: null,
        companystatus: null,
        companykundevon: null,
      },
      owner: null,
      loading: false,
      edit: false,
      viewHistory: false,
      errors: false,
      mycontacts: true,
      fields: [],
      auditLog: [],
      showMore: false,
      contactsTable: true,
      activitiesTable: false,
      activities: [],
      contacts: [],
      own: false,
      loadMore: false,
    };
  }

  handleClickOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.props.onClickOutside && this.props.onClickOutside();
      this.setState({ showDetails: -1, showEmails: false, showMore: false })
    }
  };

  componentDidMount = () => {
    this.retrieveCompany();
    this.retrieveCompanyLogs();
    this.fetch()
    document.addEventListener('click', this.handleClickOutside, true);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  };

  retrieveCompany = async () => {
    this.setState({ loading: true });
    const userDetails =
      sessionStorage.getItem("user-info") !== null
        ? JSON.parse(sessionStorage.getItem("user-info"))
        : JSON.parse(localStorage.getItem("user-info"));

    const userID = userDetails.id;
    const { id } = this.props.params;
    let response = await connector.companies.get(id);
    const response2 = await connector.fields.list("company", "limit=100");

    this.setState({
      modifiedData: response.data.company?.fields?.all,
      owner: response.data.company.owner,
      fields: Object.values(response2.data.fields),
      loading: false,
      mycontacts: true,
    });
  };

  loadOwn = async (page = 1) => {
    const { id } = this.props.params;
    const userDetails = JSON.parse(localStorage.getItem("user-info"));
    await this.fetch(`owner_id=${userDetails.id}&next_user_id=${userDetails.id}`);
    this.setState({ own: true })
  };

  loadAll = async (page = 1) => {
    await this.fetch("");
    this.setState({ own: false });
  };

  fetch = async (url, page = 1) => {
    const { id } = this.props.params
    this.setState({ loading: true });

    if (url === undefined) {
      url = ""
    }

    let response = await localConnector.companies.getActivitiesAndContacts(id, encodeURI(url));

    this.setState({
      activities: response.data.activities || response.data.activity,
      contacts: response.data.leads || response.data.lead,
      totalRows: response.data.total,
      loading: false,
    });
  };


  retrieveCompanyLogs = async () => {
    const { id } = this.props.params;

    const response = await connector.companies.getLogs(id);
    this.setState({ auditLog: response.data.auditlogs });
  };

  handleSpracheChange = (value) => {
    this.state.modifiedData.companypreferred_locale = value.value;
  };

  handleKundeVon = (value) => {
    this.state.modifiedData.companykundevon = value;
  };

  handleKategorie = (value) => {
    this.state.modifiedData.companycategory = value.value;
  };

  handleStatusChange = (value) => {
    this.state.modifiedData.companystatus = value.value;
  };

  handleBranche = (value) => {
    this.state.modifiedData.companyindustry = value.value;
  };

  handleCountryChange = (value) => {
    this.state.modifiedData.companycountry = value.value;
  };

  handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      alert(rejectedFiles[0].errors.map((x) => x.code));
    }
    if (files && files.length > 0) {
      this.state.modifiedData.image = files[0];
    }
  };


  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prev) => ({
      ...prev,
      modifiedData: {
        ...prev.modifiedData,
        [name]: value,
      },
    }));
  };

  handlePhoneChange = (value) => {
    this.state.modifiedData.companyphone = value;
  };

  handleBusinessRelation = (value) => {
    this.state.modifiedData.companybusinessrelation = value.value;
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const id = this.props.params;
    if (
      this.state.modifiedData.companykundevon &&
      this.state.modifiedData.companykundevon.length
    ) {
      this.state.modifiedData.companykundevon =
        this.state.modifiedData.companykundevon.map((x) => x.value);
    }
    let response = await connector.companies.edit(
      id.id,
      this.state.modifiedData
    );
    if (response.status === 200 || response.status === 201) {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, errors: true });
    }

    this.setState({ loading: false });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  deleteFunc = async () => {
    this.setState({ loading: true });
    const navigate = this.props.navigate;
    const { id } = this.props.params;
    await await connector.companies.delete(id);
    this.setState({ loading: false });
    navigate("/unternehmen");
  };

  changeMode = () => {
    this.setState({ edit: !this.state.edit });
  };

  changeViewHistory = () => {
    this.setState({ viewHistory: !this.state.viewHistory });
  };

  changeData = () => {
    this.setState({ mycontacts: !this.state.mycontacts });
    this.state.mycontacts ? this.loadAll() : this.loadOwn()
  };

  changeTable = (type) => {
    if (type === "contacts") {
      this.setState({ contactsTable: true });
      this.setState({ activitiesTable: false });
    } else {
      this.setState({ contactsTable: false });
      this.setState({ activitiesTable: true });
    }
  };

  ExpandedComponent = ({ data }) => {
    return (
      <div className="history-table no-min-height">
        <div className="history-table-header flex items-center">
          <div className="px-2 py-3 w-[30%]">Feld/Objekt</div>
          <div className="px-2 py-3 flex-1">Neuer Wert</div>
          <div className="px-2 py-3 flex-1">Alter Wert</div>
        </div>
        <div
          style={{
            overflow: "hidden",
            height: "100%",
          }}
        >
          {Object.entries(data.details.fields).map((field) => (
            <div className="history-table-content">
              <div className="flex items-center">
                <div className="p-2 w-[30%]">{field[0]}</div>
                <div className="flex-1 p-2">
                  <div className="flex-1 p-2">{field[1][1]}</div>
                </div>
                <div className="flex-1 p-2">{field[1][0]}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  reload = () => {
    window.location.reload(false);
  };

  render() {
    const { companyPermissions, userId } = this.props;

    const userDetails =
      sessionStorage.getItem("user-info") !== null
        ? JSON.parse(sessionStorage.getItem("user-info"))
        : JSON.parse(localStorage.getItem("user-info"));

    let localeName = "";

    let notFilteredKundeVon =
      typeof this.state.modifiedData.companykundevon === "string"
        ? this.state.modifiedData.companykundevon.split(/[|]/)
        : "";

    let filteredKundeVon = Object.entries(notFilteredKundeVon).map((x) => {
      return {
        label: x[1],
        value: x[1],
      };
    });

    countriesLocale.map((x) =>
      x.value === this.state.modifiedData.companypreferred_locale
        ? (localeName = x.label)
        : ""
    );

    const userID = userDetails.id;
    const {
      error,
      edit,
      mycontacts,
      ownContacts,
      allContacts,
      modifiedData,
      fields,
      viewHistory,
      auditLog,
      showMore,
      contactsTable,
      activitiesTable,
      activities,
    } = this.state;
    const paginationComponentOptions = {
      rowsPerPageText: "Zeilen pro Seite",
      rangeSeparatorText: "von",
    };

    let kundeVon = Object.values(fields).filter(
      (x) => x.alias === "companykundevon"
    );
    let branche = Object.values(fields).filter(
      (x) => x.alias === "companyindustry"
    );
    let companystatus = Object.values(fields).filter(
      (x) => x.alias === "company_status"
    );
    let business = Object.values(fields).filter(
      (x) => x.alias === "companybusinessrelation"
    );
    let kategorie = Object.values(fields).filter(
      (x) => x.alias === "company_category"
    );

    let kundeVonFields = kundeVon.map((x) => x.properties.list);
    let brancheFields = branche.map((x) => x.properties.list);
    let businessFields = business.map((x) => x.properties.list);
    let kategorieFields = kategorie.map((x) => x.properties.list);
    let companystatusFields = companystatus.map((x) => x.properties.list);

    if (error) {
      return <div>An error occured: {error.message}</div>;
    }

    const auditColumns = [
      {
        name: "Benutzer/Quelle Name",
        selector: (row) => row.userName,
        sortable: true,
        flex: 1,
      },
      {
        name: "Fall Art",
        selector: (row) =>
          row.action === "update" ? "Unternehmen aktualisiert" : "Unternehmen erfasst",
        sortable: true,
        flex: 1,
      },
      {
        name: "Fall Zeitstempel",
        selector: (row) => moment(row.dateAdded).format("DD.MM.YY HH:mm"),
        sortable: true,
      },
    ];

    return (
      <div
        className="main-section"
        style={{
          left: isMobile ? "0" : "15rem",
          width: isMobile ? "100%" : "calc(100vw - 13rem)",
          padding: isMobile ? "0" : "0 60px 50px 60px",
        }}
      >
        <div className="w-[100%] lg:pt-[20px]">
          <div
            className={`p-[20px] lg:p-[0 30px 30px 30px] bg-transparent w-full lg:w-full block mx-auto relative ${modifiedData.companyname === null
              ? "bg-[#00000029]"
              : "bg-transparent"
              } rounded-md`}
          >
            {modifiedData.companyname === null ? (
              <div className="absolute w-fit left-[50%] top-[10px]">
                <PulseLoader size={10} color="#004f9f" />
              </div>
            ) : (
              <></>
            )}
            {edit ? (
              <div
                className="translate-y-[38px] mt-[-38px] z-[900] w-fit p-[5px] hover:cursor-pointer"
                onClick={() => this.setState({ edit: false })}
              >
                <AiOutlineEye size={30} color="#004f9f" />
              </div>
            ) : (
              <></>
            )}
            <div
              className={`flex w-full justify-end ${viewHistory ? "lg:justify-start" : "lg:justify-end"}  items-center gap-[35px] ${edit ? "pb-[20px]" : "pb-0"
                }`}
            >
              {edit ? (
                <>
                  <DeleteModal
                    delete={this.deleteFunc}
                    loading={this.state.loading}
                  />
                  <div className="mobile-tablet-none">
                    <EditModal
                      reload={this.reload}
                      submit={this.handleSubmit}
                      loading={this.state.loading}
                      errors={this.state.errors}
                    />
                  </div>
                </>
              ) : viewHistory ? (
                <div
                  className="z-[900] w-fit p-[5px] hover:cursor-pointer mt-[-30px] mb-[20px]"
                  onClick={() => this.setState({ viewHistory: false })}
                >
                  <AiOutlineEye size={30} color="#004f9f" />
                </div>
              ) : (
                <></>
              )}
            </div>
            {viewHistory ? (
              <div
                className={`z-[1] border relative bg-white-table ${modifiedData.companyname === null || !auditLog.length
                  ? "rounded-[23px]"
                  : ""
                  }`}
              >
                <DataTable
                  columns={auditColumns}
                  data={auditLog}
                  direction="auto"
                  fixedHeaderScrollHeight="300px"
                  responsive
                  expandableRows
                  expandableRowsComponent={this.ExpandedComponent}
                  subHeaderAlign="right"
                  subHeaderWrap
                  progressPending={this.state.loading}
                  pagination
                  sortIcon={
                    <BsSortDown
                      sx={{
                        color: "#004f9f",
                        opacity: 1,
                        height: "20px",
                        width: "24px",
                      }}
                    />
                  }
                  paginationServer
                  paginationTotalRows={this.state.totalRows}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationComponentOptions={paginationComponentOptions}
                  progressComponent={
                    <div
                      className="nodatacomponent"
                      style={{
                        background:
                          modifiedData.companyname === null ? "#d3d3d5" : "fff",
                      }}
                    >
                      <PulseLoader color={"#004f9f"} />{" "}
                    </div>
                  }
                  noDataComponent={
                    <div className="nodatacomponent">
                      Keine Daten vorhanden...1
                    </div>
                  }
                  paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                ></DataTable>
              </div>
            ) : (
              <>
                {" "}
                <div className="flex flex-wrap justify-between items-center pb-[20px]">
                  <div
                    className={`flex flex-wrap items-center w-full justify-between lg:justify-between border-b-[1px] border-[#004f9f] ${edit && "pb-2"
                      }`}
                  >
                    <div
                      className={`flex lg:flex-nowrap gap-5 lg:gap-0 flex-1 justify-between ${edit ? "w-full gap-5" : "w-[80%]"
                        }`}
                    >
                      <div
                        className={`flex flex-col w-full  ${edit ? "lg:w-[35%]" : "w-[33%] lg:w-[60%]"
                          }`}
                      >
                        <p className="text-[#004f9f] break-all text-[14px] font-[400]">
                          Unternehmen
                        </p>
                        {edit ? (
                          <input
                            type="text"
                            name="companyname"
                            onChange={this.handleInputChange}
                            value={modifiedData.companyname}
                            className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full lg:w-[200px] px-[8px] py-1.5 searchinput"
                            placeholder="Unternehmen"
                          />
                        ) : (
                          <p className="text-gray-500 break-all text-[16px] pb-[20px] lg:pb-0">
                            {modifiedData.companyname
                              ? modifiedData.companyname
                              : "-"}
                          </p>
                        )}
                      </div>
                      <div
                        className={`flex flex-row   ${edit
                          ? "flex-1 justify-end w-[100%]"
                          : "justify-between w-[20%] lg:w-[40%]"
                          }`}
                      >
                        <div
                          className={`flex flex-col ${edit ? "lg:w-full" : "w-[full] lg:w-full"
                            }`}
                        >
                          <p className="text-[#004f9f] break-all text-[14px] font-[400]">
                            Status
                          </p>
                          {edit ? (
                            <Select
                              className="w-full lg:w-[250px]"
                              classNamePrefix="transparent-bg"
                              isSearchable={true}
                              name="companystatus"
                              getOptionLabel={(e) => `${e.label} `}
                              getOptionValue={(e) => e.value}
                              options={companystatusFields[0]}
                              onChange={this.handleStatusChange}
                              placeholder={
                                modifiedData.companystatus
                                  ? modifiedData.companystatus
                                  : "Auswählen..."
                              }
                            />
                          ) : (
                            <p className="text-gray-500 break-all text-[16px] pb-[20px] lg:pb-0">
                              {modifiedData.companystatus
                                ? modifiedData.companystatus
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex w-100 justify-end lg:justify-end items-center gap-[35px] pb-[20px] ${edit
                            ? "absolute top-1 right-1 lg:top-5 lg:right-5 w-fit"
                            : "w-[20%] absolute top-2 right-2 lg:relative lg:top-0 lg:right-0"
                            } `}
                        >
                          {edit ? (
                            <></>
                          ) : (
                            <>
                              {(userDetails && userDetails.role.id === 1) ||
                                (userDetails &&
                                  userDetails.role.name === "Prüfer") ? (
                                <div className="flex items-center gap-2">
                                  <BsPencil
                                    onClick={() => this.changeMode()}
                                    size={isMobile ? 20 : 35}
                                    color="#004f9f"
                                    className="hover:cursor-pointer"
                                  />
                                  <AiOutlineHistory
                                    onClick={() => this.changeViewHistory()}
                                    size={isMobile ? 20 : 33}
                                    color="#004f9f"
                                    className="hover:cursor-pointer"
                                  />
                                </div>
                              ) : (
                                <>
                                  {companyPermissions &&
                                    companyPermissions[0] ? (
                                    <>
                                      {companyPermissions[0].includes(
                                        "editown"
                                      ) &&
                                        (userDetails.id === userDetails.id) ===
                                        this.state.owner?.id ? (
                                        <BsPencil
                                          onClick={() => this.changeMode()}
                                          size={35}
                                          color="#004f9f"
                                          className="hover:cursor-pointer"
                                        />
                                      ) : (
                                        <>
                                          {companyPermissions[0].includes(
                                            "editother"
                                          ) ||
                                            companyPermissions[0].includes(
                                              "full"
                                            ) ? (
                                            <BsPencil
                                              onClick={() => this.changeMode()}
                                              size={35}
                                              color="#004f9f"
                                              className="hover:cursor-pointer"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-between items-center pb-[20px]">
                  <div className="lex flex-wrap items-center w-full justify-between lg:justify-between">
                    <div className="flex flex-wrap lg:flex-nowrap justify-between flex-1 w-[100%]">
                      <div className="flex flex-col gap-[35px] w-[49%] lg:w-[25%]">
                        <div
                          className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400] w-full">
                            Kundennummer
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companykundennummer"
                              onChange={this.handleInputChange}
                              value={modifiedData.companykundennummer}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px]  focus:outline-none block w-full px-[8px] py-1.5 searchinput"
                              placeholder="Kundennummer"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all w-full">
                              {modifiedData.companykundennummer
                                ? modifiedData.companykundennummer
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2
                            className={`text-[#004f9f] text-[14px] font-[400] w-full`}
                          >
                            Kategorie
                          </h2>
                          {edit ? (
                            <Select
                              isSearchable={true}
                              classNamePrefix="transparent-bg"
                              getOptionLabel={(e) => `${e.label}`}
                              getOptionValue={(e) => e.value}
                              name="companycategorie"
                              options={kategorieFields[0]}
                              onChange={this.handleKategorie}
                              placeholder={
                                modifiedData.companycategory
                                  ? modifiedData.companycategory
                                  : "Auswählen..."
                              }
                              className="w-full"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all w-full">
                              {modifiedData.companycategory
                                ? modifiedData.companycategory
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2
                            className={`text-[#004f9f] text-[14px] font-[400] w-full`}
                          >
                            Kunde von
                          </h2>
                          {edit ? (
                            <Select
                              isMulti
                              classNamePrefix="transparent-bg"
                              getOptionLabel={(e) => `${e.label}`}
                              getOptionValue={(e) => e.value}
                              isSearchable={true}
                              defaultValue={
                                modifiedData.companykundevon
                                  ? filteredKundeVon
                                  : ""
                              }
                              name="companykundevon"
                              options={kundeVonFields[0]}
                              onChange={this.handleKundeVon}
                              placeholder="Auswählen..."
                              className="w-full"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all w-full">
                              {modifiedData.companykundevon
                                ? modifiedData.companykundevon
                                : "-"}
                            </p>
                          )}
                        </div>
                      </div>
                      {edit ? (
                        <></>
                      ) : (
                        <div className="flex flex-col gap-[27px] w-[48%] lg:w-[30%] relative">
                          <div
                            className={`flex flex-col lg:gap-[10px] w-100 pt-3 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                              }`}
                          >
                            <h2
                              className={`text-[#004f9f] text-[14px] font-[400] w-full opacity-0`}
                            >
                              empty
                            </h2>
                            {edit ? (
                              <></>
                            ) : (
                              <div
                                className="flex items-center justify-between relative cursor-pointer"
                                onClick={() =>
                                  this.setState({ showMore: !showMore })
                                }
                              >
                                {modifiedData?.companyemail?.length > 1 &&
                                  modifiedData?.companyemailadresse_buchh
                                    ?.length > 1 ? (
                                  <>
                                    <p className="text-[#004f9f] text-[14px]  lg:text-[18px] font-semibold break-all w-full">
                                      Nachricht senden
                                    </p>
                                    <img
                                      alt=""
                                      src={require("../../../assets/images/emailsend.png")}
                                      className="w-9 lg:w-12 h-7 lg:h-8"
                                    />
                                  </>
                                ) : (
                                  <a
                                    className="flex items-center justify-between w-full"
                                    href={`mailto: ${modifiedData?.companyemail?.length > 1
                                      ? modifiedData?.companyemail
                                      : modifiedData?.companyemailadresse_buchh
                                      }`}
                                  >
                                    <p className="text-[#004f9f] text-[14px]  lg:text-[18px] font-semibold break-all w-full">
                                      Nachricht senden
                                    </p>
                                    <img
                                      alt=""
                                      src={require("../../../assets/images/emailsend.png")}
                                      className="w-9 lg:w-12 h-7 lg:h-8 cursor-pointer"
                                    />
                                  </a>
                                )}

                                {modifiedData?.companyemail?.length > 1 &&
                                  modifiedData?.companyemailadresse_buchh
                                    ?.length > 1 &&
                                  showMore ? (
                                  <div className="absolute flex flex-col gap-3 bg-transparent rounded-md shadow border border-[#004f9f] top-full left-0 w-full p-2 bg-[#ffffff] z-[9999]">
                                    <a
                                      href={`mailto:${modifiedData.companyemail}`}
                                    >
                                      {modifiedData.companyemail}
                                    </a>
                                    <a
                                      href={`mailto:${modifiedData.companyemailadresse_buchh}`}
                                    >
                                      {modifiedData.companyemailadresse_buchh}
                                    </a>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )}
                          </div>
                          <div
                            className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                              }`}
                          >
                            <h2
                              className={`text-[#004f9f] text-[14px] font-[400] w-full opacity-0`}
                            >
                              empty
                            </h2>
                            {edit ? (
                              <></>
                            ) : (
                              <a
                                href={`tel:${modifiedData.companyphone}`}
                                className="flex items-center justify-between"
                              >
                                <p className="text-[#004f9f] text-[14px] lg:text-[18px] font-semibold break-all w-full">
                                  Anrufen
                                </p>
                                <FiPhoneCall
                                  color="#004f9f"
                                  size={isMobile ? 26 : 35}
                                  className="relative left-[-10px]"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      )}

                      <div
                        className={`grid gap-[35px]  ${edit
                          ? " mt-[30px] lg:mt-0 lg:absolute h-[300px]"
                          : "mt-[30px] lg:mt-0 relative w-full lg:w-[35%] h-auto"
                          }`}
                        style={{
                          left: edit && !isMobile ? "calc(30% + 15px)" : "0",
                        }}
                      >
                        <div className="grid lg:pt-[20px]">
                          {edit ? (
                            <>
                              <textarea
                                rows="8"
                                cols="50"
                                name="companynotize"
                                onChange={this.handleInputChange}
                                value={modifiedData.companynotize}
                                className={`placeholder:text-[12px] lg:placeholder:text-[14px] shadow ${modifiedData.companyname === null
                                  ? "bg-[#d3d3d5]"
                                  : "bg-transparent"
                                  } border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f]`}
                                placeholder="Bemerkung"
                              />
                            </>
                          ) : (
                            <>
                              <textarea
                                rows="8"
                                cols="50"
                                name="companynotize"
                                onChange={this.handleInputChange}
                                value={modifiedData.companynotize}
                                className={` placeholder:text-[12px] lg:placeholder:text-[14px] shadow ${modifiedData.companyname === null
                                  ? "bg-[#d3d3d5]"
                                  : "bg-transparent"
                                  } border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f] lg:absolute top-[30px] h-[400px]`}
                                placeholder="Bemerkungen"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-between items-center pb-[20px] mt-0 lg:mt-10">
                  <div className="lex flex-wrap items-center w-full justify-between lg:justify-between">
                    <div className="flex flex-wrap lg:flex-nowrap justify-between flex-1 w-[100%]">
                      <div className="flex flex-col gap-[35px] w-[49%] lg:w-[25%]">
                        <div
                          className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Adresse
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companyaddress1"
                              onChange={this.handleInputChange}
                              value={modifiedData.companyaddress1}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-1.5 searchinput"
                              placeholder="Adresse"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companyaddress1
                                ? modifiedData.companyaddress1
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Postfach
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companypostfach"
                              onChange={this.handleInputChange}
                              value={modifiedData.companypostfach}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-1.5 searchinput"
                              placeholder="Postfach"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companypostfach
                                ? modifiedData.companypostfach
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Postleitzahl
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companyzipcode"
                              onChange={this.handleInputChange}
                              value={modifiedData.companyzipcode}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-1.5 searchinput"
                              placeholder="PLZ"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companyzipcode
                                ? modifiedData.companyzipcode
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Ort
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companycity"
                              onChange={this.handleInputChange}
                              value={modifiedData.companycity}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[180px] px-[8px] py-1.5 searchinput"
                              placeholder="Ort"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companycity
                                ? modifiedData.companycity
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Nationalität
                          </h2>
                          {edit ? (
                            <Select
                              className="w-full"
                              classNamePrefix="transparent-bg"
                              isSearchable={true}
                              getOptionLabel={(e) => `${e.label}`}
                              getOptionValue={(e) => e.value}
                              name="country"
                              options={countries}
                              onChange={this.handleCountryChange}
                              placeholder={
                                modifiedData.companycountry
                                  ? modifiedData.companycountry
                                  : "Auswählen..."
                              }
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companycountry
                                ? modifiedData.companycountry
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Branche
                          </h2>
                          {edit ? (
                            <Select
                              className="w-full"
                              classNamePrefix="transparent-bg"
                              isSearchable={true}
                              getOptionLabel={(e) => `${e.label}`}
                              getOptionValue={(e) => e.value}
                              name="country"
                              options={countries}
                              onChange={this.handleCountryChange}
                              placeholder={
                                modifiedData.companyindustry
                                  ? modifiedData.companyindustry
                                  : "Auswählen..."
                              }
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companyindustry
                                ? modifiedData.companyindustry
                                : "-"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-[35px] w-[48%] lg:w-[30%]">
                        <div
                          className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Geschäftsbeziehung
                          </h2>
                          {edit ? (
                            <Select
                              className="w-full"
                              classNamePrefix="transparent-bg"
                              isSearchable={true}
                              getOptionLabel={(e) => `${e.label}`}
                              getOptionValue={(e) => e.value}
                              name="companybusinessrelation"
                              options={businessFields[0]}
                              onChange={this.handleBusinessRelation}
                              placeholder={
                                modifiedData.companybusinessrelation
                                  ? modifiedData.companybusinessrelation
                                  : "Auswählen..."
                              }
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companybusinessrelation
                                ? modifiedData.companybusinessrelation
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Website
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companywebsite"
                              onChange={this.handleInputChange}
                              value={modifiedData.companywebsite}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-1.5 searchinput"
                              placeholder="Website"
                            />
                          ) : (
                            <a
                              href={modifiedData.companywebsite || "#"}
                              target="_blank"
                              className="text-gray-500 text-[16px] flex items-center gap-2"
                            >
                              <p>
                                {modifiedData.companywebsite
                                  ? modifiedData.companywebsite
                                  : "-"}
                              </p>
                              {modifiedData.companywebsite && (
                                <HiOutlineExternalLink
                                  size={20}
                                  color="#004f9f"
                                />
                              )}
                            </a>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            Telefon
                          </h2>
                          {edit ? (
                            <PhoneInput
                              placeholder="Telefon"
                              value={modifiedData.companyphone}
                              onChange={this.handlePhoneChange}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none w-full flex gap-[10px] px-[8px] py-1.5 searchinput no-focus-visible"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companyphone
                                ? modifiedData.companyphone
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            E-Mailprivat
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companyemail"
                              onChange={this.handleInputChange}
                              value={modifiedData.companyemail}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-1.5 searchinput"
                              placeholder="E-Mailprivat"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companyemail
                                ? modifiedData.companyemail
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                            E-Mailgeschäftlich
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companyemailadresse_buchh"
                              onChange={this.handleInputChange}
                              value={modifiedData.companyemailadresse_buchh}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full  px-[8px] py-1.5 searchinput"
                              placeholder="E-Mailgeschäftlich"
                            />
                          ) : (
                            <p className="text-gray-500 text-[16px] break-all">
                              {modifiedData.companyemailadresse_buchh
                                ? modifiedData.companyemailadresse_buchh
                                : "-"}
                            </p>
                          )}
                        </div>
                        <div
                          className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[14px] font-[500]  w-full lg:w-[143.75px]">
                            LinkedIn
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="companylinkedin"
                              onChange={this.handleInputChange}
                              value={modifiedData.companylinkedin}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-1.5 searchinput"
                              placeholder="LinkedIn"
                            />
                          ) : (
                            <a
                              href={modifiedData.companylinkedin || "#"}
                              target="_blank"
                              className="text-gray-500 text-[16px] flex items-center gap-2 w-fit"
                            >
                              <p className="whitespace-nowrap max-w-[80%] overflow-hidden">
                                {modifiedData.companylinkedin
                                  ? modifiedData.companylinkedin
                                  : "-"}
                              </p>
                              {modifiedData.companylinkedin && (
                                <p className="ml-[-5px]">...</p>
                              )}
                              {modifiedData.companylinkedin && (
                                <HiOutlineExternalLink
                                  size={20}
                                  color="#004f9f"
                                />
                              )}
                            </a>
                          )}
                        </div>
                      </div>
                      {!edit ? (
                        <div className="flex flex-col gap-4 w-full lg:w-[35%] relative top-[50px] lg:top-[150px]">
                          <div className="flex items-center justify-between">
                            <h2 className="text-[14px] text-[#004f9f]">
                              Neuere Geschichte
                            </h2>
                            <p onClick={() => {
                              this.setState({ viewHistory: true });
                              window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                              });
                            }} className="text-[#004f9f] underline cursor-pointer text-xs">Alles sehen</p>
                          </div>
                          <div className="card-body activity-scroll">
                            {auditLog.slice(0, 3).map((x, i) => (
                              <div className="activity" key={i}>
                                <div className="flex mb-[30px]">
                                  <div
                                    className={`bg-[#004f9f] rounded-full text-[#fff] w-7 h-7 relative top-0 flex-shrink-0 flex items-center justify-center p-1 ${i !== 2 && "line-after"}`}
                                    style={{ marginRight: "15px" }}
                                  >
                                    <AiOutlineHistory size={20} />
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="mb-0 pb-0 text-xs">
                                      {x.action === "update" ? "Unternehmen aktualisiert" : "Unternehmen erfasst"}
                                    </p>
                                    <small className="text-xs">
                                      {moment(x.dateAdded).format("DD.MM.YY HH:mm")}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : <div className="flex flex-col gap-4 w-[35%] relative top-[150px]"></div>}

                    </div>
                  </div>
                </div>
                <div
                  className={`flex flex-wrap items-start ${edit ? "lg:gap-[20px]" : "lg:gap-[30px]"
                    } `}
                ></div>
              </>
            )}
          </div>

          {!edit && (
            <div className="p-0 lg:p-[20px] w-full block mx-auto mt-[50px] lg:mt-[20px] px-[15px]">
              <div className="flex flex-wrap lg:flex-nowrap items-center justify-between w-full">
                <h3 className="text-[18px] flex-0 w-full lg:flex-1 lg:text-[24px] text-[#004F9F] font-normal pb-[20px] lg:pb-0">
                  {contactsTable ? "Kontakte " : activitiesTable ? "Aktivitäten " : ""}
                  {modifiedData.companyname ? (
                    modifiedData.companyname
                  ) : (
                    <PulseLoader color={"#004f9f"} size={5} />
                  )}
                </h3>
                <div className="flex relative items-center mx-auto lg:mx-0 justify-center lg:justify-end gap-[5px] z-[100] pb-[30px] lg:scroll-pb-40">
                  <button
                    onClick={() => this.changeTable("contacts")}
                    to="#"
                    className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${contactsTable ? "border-[#004f9f]" : "shadow"
                      }  px-3 py-2 `}
                  >
                    Kontakte
                  </button>
                  <button
                    onClick={() => this.changeTable("activities")}
                    to="#"
                    className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${activitiesTable ? "border-[#004f9f]" : "shadow"
                      }  px-3 py-2 `}
                  >
                    Aktivitäten
                  </button>
                </div>
              </div>
              <div className="flex relative items-center justify-center lg:justify-end w-[100%] gap-[5px] z-[100] pb-[30px] lg:scroll-pb-40">
                <button
                  onClick={() => this.changeData()}
                  to="#"
                  className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${mycontacts ? "border-[#004f9f]" : "shadow"
                    }  px-3 py-2 `}
                >
                  Meine {contactsTable ? "Kontakte" : "Aktivitäten"}
                </button>
                <button
                  onClick={() => this.changeData()}
                  to="#"
                  className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${!mycontacts ? "border-[#004f9f]" : "shadow"
                    }  px-3 py-2 `}
                >
                  Alle {contactsTable ? "Kontakte" : "Aktivitäten"}
                </button>
              </div>

              <div className="z-[1] flex justify-center w-full" ref={this.ref}>
                {this.state.loading ? <PulseLoader size={10} color="#004f9f" /> : <> {contactsTable ? (
                  <Contacts
                    loading={this.state.loading}
                    contacts={this.state.contacts}
                  />
                ) : (
                  <Activities
                    activities={this.state.activities}
                    loading={this.state.loading}
                  />
                )}</>}

              </div>
            </div>
          )}
        </div>
      </div >
    );
  }
}

export default (props) => (
  <CompanyCard {...props} params={useParams()} navigate={useNavigate()} />
);

