class Settings {
    constructor(client) {
      this.client = client;
    }

    list(params = {}) {
      return this.client.get("contacts/activities/settings", params);
    }
  }

  export default Settings;


