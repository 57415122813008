import React from "react";
import { useNavigate } from "react-router-dom";
import connector from "../../../connector";
import AddModal from "../../../reusableComponents/Modals/AddModal";
import Dropzone from "react-dropzone";
import { countries, countriesLocale, status, kategorie } from "../../../data";
import Select from "react-select";
import { connect } from "react-redux";
import { retrieveUsers } from "../../../actions/usersAction";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isMobile } from "react-device-detect";

class AddCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modifiedData: {
        image: null,
        companyname: null,
        companykundennummer: null,
        companycategory: null,
        companypreferred_locale: null,
        companyaddress1: null,
        companyaddress2: null,
        companypostfach: null,
        companyzipcode: null,
        companycity: null,
        companystate: null,
        companywebsite: null,
        companyphone: null,
        companyphone3: null,
        companyemail: null,
        companyemailadresse_buchh: null,
        companylinkedin: null,
        companyindustry: null,
        companybusinessrelation: null,
        companylanguage: null,
        companynotize: null,
        owner: null,
        companystatus: null,
        companykundevon: null,
      },
      loading: false,
      errors: false,
      fields: [],
      noName: false,
      tempData: [],
      removeItem: [{ label: "Löschen...", value: null }],
      usersList: [],
    };
  }

  componentDidMount = async () => {
    // this.props.retrieveUsers();
    this.setState({ usersList: JSON.parse(localStorage.getItem("usersList")) })
    const response = await connector.fields.list("company", "limit=100");

    this.setState({ fields: Object.values(response.data.fields) });
  };

  handleInputChange = ({ target: { name, value } }) => {
    this.setState((prev) => ({
      ...prev,
      modifiedData: {
        ...prev.modifiedData,
        [name]: value,
      },
    }));
  };

  handleSelectChange = (value) => {
    this.state.modifiedData.owner = value.id;
  };

  handleKategorie = (value) => {
    if (value === null) {
      this.state.modifiedData.companycategory = null
    } {
      this.state.modifiedData.companycategory = value.value;
    }
  };

  handleKundeVon = (value) => {
    if (value === null) {
      this.state.modifiedData.companykundevon = null
    } else {
      this.state.modifiedData.companykundevon = Object.entries(value).map(
        (x) => x[1].value
      );
    }
  };

  handleBusinessRelation = (value) => {
    if (value === null) {
      this.state.modifiedData.companybusinessrelation = null
    } else {
      this.state.modifiedData.companybusinessrelation = value.value;
    }
  };

  handleOnDrop = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      alert(rejectedFiles[0].errors.map((x) => x.code));
    }
    if (files && files.length > 0) {
      this.state.modifiedData.image = files[0];
    }
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    if (
      this.state.modifiedData.companyname === null ||
      this.state.modifiedData.companyname === ""
    ) {
      this.setState({ loading: false, errors: true, noName: true });
    } else {
      let response = await connector.companies.create(this.state.modifiedData);
      if (response.status === 201 || response.status === 200) {
        this.setState({
          loading: false,
          errors: false,
          tempData: response.data,
        });
      } else {
        this.setState({ loading: false, errors: true });
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  handleCountryChange = (value) => {
    if (value == null) {
      this.state.modifiedData.companycountry = null;

    } else {
      this.state.modifiedData.companycountry = value.value;
    }
  };

  handlePhoneChange = (value) => {
    if (value == null) {
      this.state.modifiedData.companyphone = null;

    } else {
      this.state.modifiedData.companyphone = value;
    }
  };

  handleBranche = (value) => {
    if (value == null) {
      this.state.modifiedData.companyindustry = null;

    } else {
      this.state.modifiedData.companyindustry = value.value;
    }
  };

  handleSpracheChange = (value) => {
    if (value.label && value.label === "Löschen...") {
      this.state.modifiedData.companypreferred_locale = null;
    } else {
      this.state.modifiedData.companypreferred_locale = value.value;
    }
  };

  handleStatusChange = (value) => {
    if (value === null) {
      this.state.modifiedData.companystatus = null
    } else {
      this.state.modifiedData.companystatus = value.value;
    }
  };

  redirect = () => {
    const navigate = this.props.navigate;
    navigate("/unternehmen");
  };

  render() {
    const { error, fields, usersList } = this.state;
    const { users } = this.props;
    const loadusers = usersList.map((x) => x[1]);

    let kundeVon = Object.values(fields).filter(
      (x) => x.alias === "companykundevon"
    );
    let branche = Object.values(fields).filter(
      (x) => x.alias === "companyindustry"
    );
    let companystatus = Object.values(fields).filter(
      (x) => x.alias === "company_status"
    );

    let business = Object.values(fields).filter(
      (x) => x.alias === "companybusinessrelation"
    );
    let kategorie = Object.values(fields).filter(
      (x) => x.alias === "company_category"
    );

    let kundeVonFields = kundeVon.map((x) => x.properties.list);
    let brancheFields = branche.map((x) => x.properties.list);
    let businessFields = business.map((x) => x.properties.list);
    let kategorieFields = kategorie.map((x) => x.properties.list);
    let companystatusFields = companystatus.map((x) => x.properties.list);

    if (error) {
      return <div>An error occured: {error.message}</div>;
    }

    return (
      <div className="main-section" style={{
        left: !isMobile ? "15rem" : "0",
        width: !isMobile ? "calc(100vw - 12rem)" : "100%",
        padding: !isMobile ? "0 60px 50px 60px" : "0",
      }}>
        <div className="w-[100%] p-[15px] lg:p-[30px]">
          <div className="flex w-100 justify-end relative mobile-tablet-none mb-3">
            <AddModal
              loading={this.state.loading}
              submit={this.handleSubmit}
              redirect={this.redirect}
              errors={this.state.errors}
            />
          </div>

          {/* new */}
          <div className="flex flex-wrap justify-between items-center pb-[20px]">
            <div
              className={`flex flex-wrap items-center w-full justify-between lg:justify-between border-b-[1px] border-[#004f9f] pb-2`}
            >
              <div
                className={`flex lg:flex-nowrap gap-5 lg:gap-0 justify-between flex-1 lg:flex-0 w-full lg:w-[68%]`}
              >
                <div
                  className={`flex flex-col flex-1 lg:w-[35%]`}
                >
                  <p className="text-[#004f9f] break-all text-[14px] font-[400]">
                    Unternehmen
                  </p>
                  <input
                    type="text"
                    name="companyname"
                    onChange={this.handleInputChange}
                    className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full lg:w-[200px] px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                    placeholder="Unternehmen"
                  />
                </div>
                <div
                  className={`flex flex-col lg:flex-row flex-1 lg:justify-end w-[100%]`}
                >
                  <div
                    className={`flex flex-col lg:w-full`}
                  >
                    <p className="text-[#004f9f] break-all text-[14px] font-[400]">
                      Status
                    </p>
                    <Select
                      className="w-full lg:w-[250px]"
                      classNamePrefix="transparent-bg"
                      isSearchable={true}
                      isClearable
                      name="companystatus"
                      getOptionLabel={(e) => `${e.label} `}
                      getOptionValue={(e) => e.value}
                      options={companystatusFields[0]}
                      onChange={this.handleStatusChange}
                      placeholder="Auswählen..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between items-center pb-[20px]">
            <div className="lex flex-wrap items-center w-full justify-between lg:justify-between">
              <div className="flex flex-wrap gap-[10px] lg:gap-[35px] w-[100%]">
                <div className="flex flex-col gap-[35px] flex-1 lg:w-[25%]">
                  <div
                    className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[14px] font-[400] w-full">
                      Kundennummer
                    </h2>
                    <input
                      type="text"
                      name="companykundennummer"
                      onChange={this.handleInputChange}
                      className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px]  focus:outline-none block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                      placeholder="Kundennummer"
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                  >
                    <h2
                      className={`text-[#004f9f] text-[14px] font-[400] w-full`}
                    >
                      Kategorie
                    </h2>
                    <Select
                      isSearchable={true}
                      isClearable
                      classNamePrefix="transparent-bg"
                      name="companycategorie"
                      options={kategorieFields[0]}
                      onChange={this.handleKategorie}
                      placeholder="Auswählen..."
                      className="w-full"
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                  >
                    <h2
                      className={`text-[#004f9f] text-[14px] font-[400] w-full`}
                    >
                      Kunde von
                    </h2>
                    <Select
                      isMulti
                      classNamePrefix="transparent-bg"
                      isClearable
                      getOptionLabel={(e) => `${e.label}`}
                      getOptionValue={(e) => e.value}
                      name="companykundevon"
                      options={kundeVonFields[0]}
                      onChange={this.handleKundeVon}
                      placeholder="Auswählen..."
                      className="w-full"
                    />

                  </div>
                </div>
                <div className="flex flex-col gap-[35px] flex-1 lg:w-[25%]">
                  <div
                    className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start w-full"`}
                  >
                    <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                      Adresse
                    </h2>
                    <input
                      type="text"
                      name="companyaddress1"
                      onChange={this.handleInputChange}
                      className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                      placeholder="Adresse"
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                      Postfach
                    </h2>
                    <input
                      type="text"
                      name="companypostfach"
                      onChange={this.handleInputChange}
                      className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                      placeholder="Postfach"
                    />
                  </div>
                  <div
                    className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                  >
                    <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                      Postleitzahl
                    </h2>
                    <input
                      type="text"
                      name="companyzipcode"
                      onChange={this.handleInputChange}
                      className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                      placeholder="PLZ"
                    />
                  </div>
                </div>
                <div
                  className={`grid gap-[35px] flex-auto lg:flex-1 mt-[30px] lg:mt-0`}
                >
                  <div className="grid lg:pt-[20px]">
                    <>
                      <textarea
                        rows="8"
                        cols="50"
                        name="companynotize"
                        onChange={this.handleInputChange}
                        className={`placeholder:text-[12px] lg:placeholder:text-[14px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[12px] md:text-[14px] focus:ring-blue-500 focus:border-blue-500 block w-full p-4 placeholder:text-[#004f9f]`}
                        placeholder="Bemerkung"
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between items-center pb-[20px] mt-0 lg:mt-10">
            <div className="lex flex-wrap items-center w-full justify-between lg:justify-between">
              <div className="flex flex-wrap lg:flex-nowrap justify-between flex-1 w-[100%]">
                <div className="flex flex-wrap gap-[10px] lg:gap-[35px] w-full mt-[15px] lg:mt-0">
                  <div className="flex flex-col gap-[35px] flex-0 lg:flex-1 w-[48%] lg:w-[25%]">
                    <div
                      className={`flex flex-col lg:gap-[10px] justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[400] w-full">
                        Ort
                      </h2>
                      <input
                        type="text"
                        name="companycity"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                        placeholder="Ort"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                        Nationalität
                      </h2>
                      <Select
                        className="w-full"
                        classNamePrefix="transparent-bg"
                        isSearchable={true}
                        isClearable
                        getOptionLabel={(e) => `${e.label}`}
                        getOptionValue={(e) => e.value}
                        name="country"
                        options={countries}
                        onChange={this.handleCountryChange}
                        placeholder="Auswählen..."
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                        Branche
                      </h2>
                      <Select
                        className="w-full"
                        classNamePrefix="transparent-bg"
                        isSearchable={true}
                        isClearable
                        getOptionLabel={(e) => `${e.label}`}
                        getOptionValue={(e) => e.value}
                        name="companyindustry"
                        options={brancheFields[0]}
                        onChange={this.handleBranche}
                        placeholder="Auswählen..."
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-[35px] flex-0 lg:flex-1 w-[48%] lg:w-[25%]">
                    <div
                      className={`flex flex-col lg:gap-[10px] justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                        Geschäftsbeziehung
                      </h2>
                      <Select
                        className="w-full"
                        classNamePrefix="transparent-bg"
                        isSearchable={true}
                        isClearable
                        getOptionLabel={(e) => `${e.label}`}
                        getOptionValue={(e) => e.value}
                        name="companybusinessrelation"
                        options={businessFields[0]}
                        onChange={this.handleBusinessRelation}
                        placeholder="Auswählen..."
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                        Website
                      </h2>
                      <input
                        type="text"
                        name="companywebsite"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                        placeholder="Website"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                        Telefon
                      </h2>
                      <PhoneInput
                        placeholder="Telefon"
                        onChange={this.handlePhoneChange}
                        className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none w-full flex gap-[10px] px-[8px] py-[6.5px] lg:py-[9.3px] searchinput no-focus-visible"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row flex-wrap lg:flex-col gap-[10px] lg:gap-[35px] flex-0 lg:flex-1 w-full lg:w-[25%] mt-[30px] lg:mt-0">
                    <div
                      className={`flex flex-col lg:gap-[10px] flex-1 lg:flex-0 justify-between lg:justify-start lg:w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                        E-Mailprivat
                      </h2>
                      <input
                        type="text"
                        name="companyemail"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                        placeholder="E-Mailprivat"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] flex-1 lg:flex-0 justify-between lg:justify-start lg:w-full`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[400]  w-full">
                        E-Mailgeschäftlich
                      </h2>
                      <input
                        type="text"
                        name="companyemailadresse_buchh"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                        placeholder="E-Mailgeschäftlich"
                      />
                    </div>
                    <div
                      className={`flex flex-col lg:gap-[10px] w-100 justify-between lg:justify-start w-full mt-[20px] lg:mt-0`}
                    >
                      <h2 className="text-[#004f9f] text-[14px] font-[500]  w-full lg:w-[143.75px]">
                        LinkedIn
                      </h2>
                      <input
                        type="text"
                        name="companylinkedin"
                        onChange={this.handleInputChange}
                        className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none block w-full px-[8px] py-[6.5px] lg:py-[9.3px] searchinput"
                        placeholder="LinkedIn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center w-100 justify-center desktop-none pt-[30px]">
            <AddModal
              loading={this.state.loading}
              submit={this.handleSubmit}
              redirect={this.redirect}
              errors={this.state.errors}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

export default connect(mapStateToProps, {
  retrieveUsers,
})((props) => <AddCompany {...props} navigate={useNavigate()} />);
