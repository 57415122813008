import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Search from "../../reusableComponents/Search";

import { MdOutlineAddTask, MdContacts } from "react-icons/md";
import { FiUserPlus } from "react-icons/fi";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import StoreIcon from "@mui/icons-material/Store";
import TaskIcon from "@mui/icons-material/Task";

function Homepage(props) {
  const [showSidebar, setShowSidebar] = useState(false);

  const userInfo =
    sessionStorage.getItem("user-info") !== null
      ? JSON.parse(sessionStorage.getItem("user-info"))
      : JSON.parse(localStorage.getItem("user-info"));

  return (
    <div className="main-section2">
      <div className="pt-[70px] md:pt-[30px]">
        <h3 className="text-center pb-[20px] text-2xl md:text-3xl lg:text-4xl text-[#004F9F] font-normal">
          Hallo {userInfo.firstName}
        </h3>
        {/* <div className="block mx-auto w-[300px] md:w-[400px] lg:w-[500px] h-[32px]">
          <AiOutlineSearch
            color="#004f9f"
            className="absolute translate-x-[10px] translate-y-[13px] lg:translate-y-[9px]"
            size={18}
          />
          <input
            type="text"
            name="search"
            className="placeholder:text-[#004F9F] placeholder:text-[12px] shadow bg-[#fff] border-gray-400 text-gray-900 sm:text-sm rounded-[24px] focus:ring-blue-500 focus:border-blue-500 block w-[300px] md:w-[400px] lg:w-[500px] px-[40px] py-2 "
            placeholder="Suche Aktivitäten, Kontakte, Unternehmen"
          />
        </div> */}
        <Search />
      </div>
      <div className="pt-[50px] block font-semibold mx-auto md:w-[800px]">
        <div className="flex flex-wrap items-center justify-center gap-[10px] md:gap-[10px] lg:gap-[30px]">
          {(props.userId && props.userId.role.id === 1) ||
            (props.userId && props.userId.role.name === "Prüfer") ? (
            <Link
              onClick={() => (
                this.props.setAddActivity({}),
                this.props.setAddActivityFromContacts(false)
              )}
              to="/aktivitat-erfassen"
              className="w-[46%] md:w-[245px] "
            >
              <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-[#004f9f] place-items-center py-[2rem] px-[1rem] md:py-[5rem] hover:bg-[#00509fc8]">
                <MdOutlineAddTask size={34} color="#fff" />
                <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                  Aktivitäten erfassen
                </p>
              </div>
            </Link>
          ) : (
            <>
              <>
                {JSON.parse(localStorage.getItem("isAuthorizedToAdd")) ===
                  true ? (
                  <Link
                    onClick={() => (
                      this.props.setAddActivity({}),
                      this.props.setAddActivityFromContacts(false)
                    )}
                    to="/aktivitat-erfassen"
                    className="w-[46%] md:w-[245px] "
                  >
                    <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-[#004f9f] place-items-center py-[2rem] px-[1rem] md:py-[5rem] hover:bg-[#00509fc8]">
                      <MdOutlineAddTask size={34} color="#fff" />
                      <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                        Aktivitäten erfassen
                      </p>
                    </div>
                  </Link>
                ) : (
                  <>
                    <button
                      disabled
                      className="w-[46%] md:w-[245px]"
                      title="Not authorized"
                    >
                      <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-gray-400 place-items-center py-[2rem] px-[1rem] md:py-[5rem]">
                        <MdOutlineAddTask size={34} color="#fff" />
                        <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                          Aktivitäten erfassen
                        </p>
                      </div>
                    </button>
                  </>
                )}
              </>
            </>
          )}

          <Link
            to="/kontakt-erfassen"
            className="w-[46%] md:w-[245px] "
          >
            <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-[#004f9f] place-items-center py-[2rem] px-[1rem] md:py-[5rem] hover:bg-[#00509fc8]">
              <FiUserPlus size={34} color="#fff" />
              <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                Kontakt erfassen
              </p>
            </div>
          </Link>
          <Link
            to="/unternehmen-erfassen"
            className="w-[46%] md:w-[245px] "
          >
            <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-[#004f9f] place-items-center py-[2rem] px-[1rem] md:py-[5rem] hover:bg-[#00509fc8]">
              <AddBusinessIcon sx={{ color: "#fff", fontSize: "34px" }} />
              <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                Unternehmen erfassen
              </p>
            </div>
          </Link>
          {JSON.parse(localStorage.getItem("isAuthorized")) === true ? (
            <Link
              to="/aktivitaten
          "
              className="w-[46%] md:w-[245px] "
            >
              <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-[#004f9f] place-items-center py-[2rem] px-[1rem] md:py-[5rem] hover:bg-[#00509fc8]">
                <TaskIcon fontSize="large" sx={{ color: "#fff" }} />
                <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                  Aktivität anzeigen
                </p>
              </div>
            </Link>
          ) : (
            <>
              <button
                disabled
                className="w-[46%] md:w-[245px]"
                title="Not authorized"
              >
                <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-gray-400 place-items-center py-[2rem] px-[1rem] md:py-[5rem]">
                  <TaskIcon fontSize="large" sx={{ color: "#fff" }} />
                  <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                    Aktivität anzeigen
                  </p>
                </div>
              </button>
            </>
          )}

          <Link
            to="/kontakte"
            className="w-[46%] md:w-[245px] "
          >
            <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-[#004f9f] place-items-center py-[2rem]  md:py-[5rem] hover:bg-[#00509fc8]">
              <MdContacts size={34} color="#fff" />
              <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                Kontakte anzeigen
              </p>
            </div>
          </Link>
          <Link
            to="/unternehmen"
            className="w-[46%] md:w-[245px] "
          >
            <div className="grid gap-[5px] min-h-[170px] md:min-h-[245px] bg-[#004f9f] place-items-center py-[2rem] px-[1rem] md:py-[5rem] hover:bg-[#00509fc8]">
              <StoreIcon fontSize="large" sx={{ color: "#fff" }} />
              <p className="text-[14px] text-center md:text-[16px] text-white font-medium">
                Unternehmen anzeigen
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Homepage;

