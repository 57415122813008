class WebHooks {
  constructor(client) {
    this.client = client;
  }

  list(params = {}) {
    return this.client.list("hooks", params);
  }

  get(hookId) {
    return this.client.get(`hooks/${hookId}`);
  }

  create(hook) {
    delete hook.id;
    return this.client.post(`hooks/new`, hook);
  }

  edit(hookId, newhook) {
    return this.client.patch(`hooks/${hookId}/edit`, newhook);
  }

  editOrCreate(hookId, newhook) {
    return this.client.put(`hooks/${hookId}/edit`, newhook);
  }

  delete(hookId) {
    return this.client.delete(`hooks/${hookId}/delete`);
  }

  listAvailableWebHooks() {
    return this.client.get("hooks/triggers");
  }
}

export default WebHooks;
