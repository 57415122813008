class Company {
  constructor(client) {
    this.client = client;
  }

  get(companyId) {
    return this.client.get(`companies/${companyId}`);
  }

  list(data = {}) {
    return this.client.list("companies", data);
  }

  create(company) {
    delete company.id;
    return this.client.post("companies/new", company);
  }

  getActivitiesAndContacts(companyId, params = {}) {
    return this.client.list(`contacts/activities/${companyId}/company`, params)
  }

  edit(companyId, company) {
    return this.client.patch(`companies/${companyId}/edit`, company);
  }

  editOrCreate(companyId, company) {
    return this.client.put(`companies/${companyId}/edit`, company);
  }

  delete(companyId) {
    return this.client.delete(`companies/${companyId}/delete`);
  }

  addContact(companyId, contactId) {
    return this.client.post(`companies/${companyId}/contact/${contactId}/new`);
  }

  removeContact(companyId, contactId) {
    return this.client.post(
      `companies/${companyId}/contact/${contactId}/remove`
    );
  }

  getContacts(companyId) {
    return this.client.get(`companies/${companyId}/leads`);
  }

  getActivities(companyId) {
    return this.client.get(`companies/${companyId}/activities`);
  }

  getLogs(companyId) {
    return this.client.get(`companies/${companyId}/auditlogs`);
  }
}

export default Company;

