class Pages {
  constructor(client) {
    this.client = client;
  }

  get(pageId) {
    return this.client.get(`pages/${pageId}`);
  }

  list(params = {}) {
    return this.client.list("pages", params);
  }

  create(page) {
    delete page.id;
    return this.client.post("pages/new", page);
  }

  edit(pageId, newPage) {
    return this.client.patch(
      `pages/${pageId}/edit`,
      newPage
    );
  }

  editOrCreate(pageId, newPage) {
    return this.client.put(
      `pages/${pageId}/edit`,
      newPage
    );
  }

  delete(pageId) {
    return this.client.delete(`pages/${pageId}/delete`);
  }
}

export default Pages;
