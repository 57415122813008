import { RETRIEVE_STANDARDTYPE, RETRIEVE_NEXTTYPE } from "./types";

import HttpServices from "../services/httpServices";

export const retrieveStandardType = () => async (dispatch) => {
  try {
    const res = await HttpServices.getStandardActivityType();

    dispatch({
      type: RETRIEVE_STANDARDTYPE,
      payload: Object.entries(res.data.activities),
    });
  } catch (err) {
  }
};
