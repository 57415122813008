import React from "react";
import Dropzone from "react-dropzone";
import { useNavigate, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import { FileIcon, defaultStyles } from "react-file-icon";
import {
  FaUserCircle,
  FaEnvelope,
  FaPhoneAlt,
  FaEdit,
  FaTimes,
} from "react-icons/fa";
import DeleteModal from "../../../reusableComponents/Modals/DeleteModal";
import EditModal from "../../../reusableComponents/Modals/EditModal";
import connector from "../../../connector";
import Datetime from "react-datetime";
import Moment from "moment";
import { AiOutlineCaretRight, AiOutlineEye } from "react-icons/ai";
import Select from "react-select";
import { retrieveStandardType } from "../../../actions/typesActions";
import { PulseLoader } from "react-spinners";
import { type } from "../../../data";
import axios from "axios";
import fileDownload from "js-file-download";
import { isMobile } from "react-device-detect";
import { BsPencil } from "react-icons/bs";

import { retrieveUsers } from "../../../actions/usersAction";
import Contacts from "./tables/Contacts";
import Companies from "./tables/Companies";

class ActivityCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daysToCheck: 21 * 24 * 60 * 60 * 1000,
      modifiedData: {
        note: null,
        activity: null,
        location: null,
        reference: null,
        type: null,
        nextActivity: null,
        owner: null,
        leads: [],
        companies: [],
        nextUser: null,
        dueDateActivity: null,
        meetingTime: null,
        documents: [],
      },
      loading: false,
      edit: false,
      errors: false,
      contacts: [],
      tempData: [],
      noRequired: false,
      removeItem: [{ label: "Löschen...", value: null }],
      tempowner: null,
      showEmails: false,
      contactTable: true,
      companyTable: false,
      usersList: [],
    };
  }

  componentDidMount = async () => {
    const user = JSON.parse(localStorage.getItem("user-info"));
    const { id } = this.props.params;
    // this.props.retrieveUsers();
    this.setState({ usersList: JSON.parse(localStorage.getItem("usersList")) })

    this.props.retrieveStandardType();

    let response2 = await connector.contacts.list(
      encodeURI(`where[0][col]=date_identified&where[0][expr]=isNotNull`)
    );
    let response = await connector.activities.get(id);
    this.setState({ tempowner: response.data.activity.owner });
    this.setState({
      modifiedData: {
        note: response.data.activity.note,
        activity: response.data.activity.activity,
        location: response.data.activity.location,
        reference: response.data.activity.reference,
        type: response.data.activity.type,
        nextActivity: response.data.activity.nextActivity,
        leads: response.data.activity.leads,
        companies: response.data.activity.companies,
        nextUser: response.data.activity.nextUser,
        dueDateActivity: response.data.activity.dueDateActivity,
        meetingTime: response.data.activity.meetingTime,
        documents: response.data.activity.documents,
      },
      contacts: Object.entries(response2.data.contacts).map((x) => x[1]),
    });
    this.state.modifiedData.owner = user.id;
    if (this.state.modifiedData.leads !== null) {
      this.state.modifiedData.leads = response.data.activity.leads;
    }
  };

  changeMode = () => {
    this.setState({ edit: !this.state.edit });
  };

  handleAssignedTo = (e) => {
    if (e === null) {
      this.state.modifiedData.nextUser = null;
    } else {
      this.state.modifiedData.nextUser = e;
    }

    this.setState({ modifiedData: this.state.modifiedData });
  };

  handleOnDrop = async (files, rejectedFiles) => {
    if (this.state.modifiedData.documents === null) {
      this.state.modifiedData.documents = [];
    }
    if (rejectedFiles && rejectedFiles.length > 0) {
      alert(rejectedFiles[0].errors.map((x) => x.code));
    }
    if (files && files.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("documents[]", files[i]);
      }
      await connector.activities
        .upload(formData)
        .then((response) => {
          this.state.modifiedData.documents =
            this.state.modifiedData.documents.concat(response.data);
          this.setState({ modifiedData: this.state.modifiedData });
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
  };

  handleSelectChange = (value) => {
    if (value === null) {
      this.state.modifiedData.leads = [];
    } else {
      this.state.modifiedData.leads = value;
    }

    this.setState({ modifiedData: this.state.modifiedData });
  };

  handleTypeChange = (value) => {
    this.state.modifiedData.type = value.value;
  };

  handleNextActivity = (value) => {
    this.state.modifiedData.nextActivity = value;
  };

  handleActivity = (value) => {
    this.state.modifiedData.activity = value;
  };

  loadOptions = async (inputValue) => {
    return await connector.contacts
      .list(
        encodeURI(
          `&where[0][col]=date_identified&where[0][expr]=isNotNull&where[1][col]=email&where[1][expr]=isNotNull&search=${inputValue}`
        )
      )
      .then((res) =>
        Object.entries(res.data.contacts)
          .map((x) => x[1])
          .concat(this.state.removeItem)
      );
  };


  changeTable = (type) => {
    if (type === "contacts") {
      this.setState({ contactTable: true });
      this.setState({ companyTable: false });
    } else {
      this.setState({ contactTable: false });
      this.setState({ companyTable: true });
    }
  };

  handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user-info"));
    this.setState({ loading: true });
    if (this.state.modifiedData.activity !== null) {
      this.state.modifiedData.activity = this.state.modifiedData.activity.id;
    }
    if (this.state.modifiedData.nextActivity !== null) {
      this.state.modifiedData.nextActivity =
        this.state.modifiedData.nextActivity.id;
    }

    const id = this.props.params;

    if (
      this.state.modifiedData.reference === null ||
      this.state.modifiedData.reference === "" ||
      this.state.modifiedData.activity === null ||
      this.state.modifiedData.activity === ""
    ) {
      this.setState({
        loading: false,
        errors: true,
        noRequired: true,
      });
    } else if (
      this.state.modifiedData.dueDateActivity <
      this.state.modifiedData.meetingTime ||
      this.state.modifiedData.meetingTime < Moment(
        new Date(Date.now() - this.state.daysToCheck)
      ).format("YYYY-MM-DD HH:mm")
    ) {
      this.setState({ loading: false, errors: true });
    } else {
      if (this.state.modifiedData.leads.length > 0) {
        this.state.modifiedData.leads = this.state.modifiedData.leads.map(
          (x) => x.id
        );

      } else {
        this.state.modifiedData.leads = this.state.modifiedData.leads.id;
      }

      if (this.state.activity) {
        this.state.activity = this.state.activity.id
      }

      if (this.state.nextActivity) {
        this.state.nextActivity = this.state.nextActivity.id
      }

      if (this.state.modifiedData.nextUser) {
        this.state.modifiedData.nextUser = this.state.modifiedData.nextUser.id;
      } else {
        this.state.modifiedData.nextUser = null
      }

      let response = await connector.activities.edit(
        id.id,
        this.state.modifiedData
      );

      if (response.status === 200 || response.status === 201) {
        this.setState({
          loading: false,
          errors: false,
          tempData: response.data,
        });
      } else {
        this.setState({ loading: false, errors: true });
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  removeDoc = (doc) => {
    this.state.modifiedData.documents.splice(doc, 1);

    this.setState({ modifiedData: this.state.modifiedData });
  };

  objectToFormData = (obj, rootName, ignoreList) => {
    var formData = new FormData();

    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || "";
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + "[" + i + "]");
          }
        } else if (typeof data === "object" && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === "") {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + "." + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== "undefined") {
            formData.append(root, data);
          }
        }
      }
    }

    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    }

    appendFormData(obj, rootName);
    return formData;
  };

  deleteFunc = async () => {
    this.setState({ loading: true });
    const navigate = this.props.navigate;
    const { id } = this.props.params;
    await await connector.activities.delete(id);
    this.setState({ loading: false });
    navigate("/aktivitaten");
  };

  handleInputChange = async ({ target: { name, value } }) => {
    await this.setState((prev) => ({
      ...prev,
      modifiedData: {
        ...prev.modifiedData,
        [name]: value,
      },
    }));
  };

  downloadFile = async (file) => {
    const { id } = this.props.params;
    await connector.activities.downloadFile(id, file.id).then((res) => {
      fileDownload(res.data, file.name);
    });
  };

  reload = () => {
    window.location.reload(false);
  };

  render() {
    const user = JSON.parse(localStorage.getItem("user-info"));
    const { types, activityPermissions, userId, users } = this.props;
    const { showEmails, contactTable, companyTable, usersList } = this.state;
    const loadtypes = types.map((x) => x[1]);

    const { id } = this.props.params

    const filteredUsers = usersList.map((x) => x[1]);

    const { error, edit, modifiedData, contacts, existingDocument } =
      this.state;

    let inputPropsdue = {
      placeholder: modifiedData.dueDateActivity
        ? Moment(modifiedData.dueDateActivity).format("YYYY-MM-DD HH:mm")
        : "Auswählen...",
    };

    let inputPropsmeeting = {
      placeholder: modifiedData.meetingTime
        ? Moment(modifiedData.meetingTime).format("YYYY-MM-DD HH:mm")
        : "Auswählen...",
    };

    if (error) {
      return <div>An error occured: {error.message}</div>;
    }
    return (
      <div
        className="main-section"
        style={{
          left: !isMobile ? "15rem" : "0",
          width: !isMobile ? "calc(100vw - 12rem)" : "100%",
          padding: !isMobile ? "0 60px 50px 60px" : "0",
        }}
      >
        <div className="w-[100%] lg:pt-[20px]">
          <div className="flex justify-start items-center"></div>
          <div className="w-full min-h-[87vh] flex flex-col">
            <div
              className={`p-[20px] lg:p-[0 30px 30px 30px] bg-transparent w-full lg:w-full block mx-auto relative ${modifiedData.reference === null
                ? "bg-[#00000029]"
                : "bg-transparent"
                } rounded-md`}
            >
              {modifiedData.reference === null ? (
                <div className="absolute w-fit left-[50%] top-[10px] lg:top-[10px] lg:left-[50%] z-[999] lg:translate-x-[50%]">
                  <PulseLoader size={10} color="#004f9f" />
                </div>
              ) : (
                <></>
              )}

              {edit ? (
                <div
                  className="translate-y-[38px] mt-[-38px] z-[900] w-fit p-[5px] hover:cursor-pointer"
                  onClick={() => this.setState({ edit: false })}
                >
                  <AiOutlineEye size={30} color="#004f9f" />
                </div>
              ) : (
                <></>
              )}

              <div
                className={`flex w-100 justify-end lg:justify-end items-center gap-[35px] ${edit ? "pb-[60px]" : "pb-[0]"
                  }`}
              >
                {edit ? (
                  <>
                    <DeleteModal
                      delete={this.deleteFunc}
                      loading={this.state.loading}
                    />
                    <div className="mobile-tablet-none">
                      <EditModal
                        reload={this.reload}
                        submit={this.handleSubmit}
                        loading={this.state.loading}
                        errors={this.state.errors}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-wrap justify-between items-center pb-[20px]">
                <div
                  className={`flex flex-wrap items-center w-full justify-between lg:justify-between border-b-[1px] border-[#004f9f] ${edit && "pb-2"
                    }`}
                >
                  <div
                    className={`flex lg:flex-nowrap gap-5  flex-1 justify-between ${edit ? "w-full gap-10" : "w-[100%] lg:gap-0"
                      }`}
                  >
                    <div
                      className={`flex flex-row justify-center lg:justify-between ${edit
                        ? "gap-7 w-[60%]"
                        : "w-[100%] gap-5 lg:gap-0 lg:w-[70%]"
                        }`}
                    >
                      <div
                        className={`flex flex-col ${edit
                          ? "w-full lg:w-[85%]"
                          : "w-full lg:w-[fit-content]"
                          }`}
                      >
                        <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]">
                          Zeit
                        </h2>
                        {edit ? (
                          <>
                            <Datetime
                              inputProps={inputPropsmeeting}
                              input={true}
                              dateFormat="YYYY-MM-DD"
                              timeFormat="HH:mm"
                              onChange={(e) => {
                                (modifiedData.meetingTime = Moment(e._d).format(
                                  "YYYY-MM-DD HH:mm"
                                )); this.setState({ modifiedData: this.state.modifiedData })
                              }
                              }
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:outline-none block w-full px-[8px] py-1.5 input-with-image"
                            />
                            {this.state.modifiedData.meetingTime < Moment(
                              new Date(Date.now() - this.state.daysToCheck)
                            ).format("YYYY-MM-DD HH:mm") ? (
                              <p className="text-[#aa1124] text-[12px] max-w-[250px] break-all">
                                Sie können Aktivitäten nur bis zu drei Wochen in der
                                Vergangenheit speichern
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <p className="text-[12px] lg:text-[16px] text-gray-500">
                            {modifiedData.meetingTime
                              ? Moment(modifiedData.meetingTime).format(
                                "YYYY-MM-DD HH:mm"
                              )
                              : "-"}
                          </p>
                        )}
                      </div>
                      <div
                        className={`flex flex-col ${edit
                          ? "w-full lg:w-[85%]"
                          : "w-full lg:w-[fit-content]"
                          }`}
                      >
                        <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] mb-[2px]">
                          Betreff
                        </h2>
                        {edit ? (
                          <input
                            type="text"
                            name="reference"
                            onChange={this.handleInputChange}
                            value={modifiedData.reference}
                            className={`placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent ${this.state.noRequired
                              ? "border-2 border-red-400"
                              : "border-0"
                              }  text-gray-900 sm:text-[13px] rounded-[6px] focus:outline-none focus:outline-none block w-full px-[8px] py-1.5 searchinput`}
                            placeholder="Betreff"
                          />
                        ) : (
                          <p className="text-[12px] lg:text-[16px] text-gray-500">
                            {modifiedData.reference
                              ? modifiedData.reference
                              : "-"}
                          </p>
                        )}
                      </div>
                      <div
                        className={`flex flex-col ${edit
                          ? "w-full lg:w-full"
                          : "w-full lg:w-[fit-content]"
                          }`}
                      >
                        <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400]">
                          Aktivität
                        </h2>
                        {edit ? (
                          <Select
                            isSearchable={true}
                            classNamePrefix="transparent-bg"
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            name="activity"
                            options={loadtypes}
                            onChange={this.handleActivity}
                            className={`w-full ${this.state.noRequired
                              ? "border-2 border-red-400"
                              : "border-0"
                              }
                    }`}
                            placeholder={
                              this.state.modifiedData.activity
                                ? this.state.modifiedData.activity.name
                                : "Auswählen..."
                            }
                          />
                        ) : (
                          <p className="text-[14px] text-gray-500">
                            {modifiedData.activity
                              ? modifiedData.activity.name
                              : "-"}
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      className={`flex flex-row   ${edit
                        ? "flex-1 justify-end"
                        : "justify-end w-[10%] lg:w-[30%]"
                        }`}
                    >
                      <div
                        className={`flex w-100 justify-end lg:justify-end items-center gap-[35px] pb-[20px] ${edit
                          ? "absolute top-1 right-1 lg:top-5 lg:right-5 w-fit"
                          : "w-[20%] absolute top-2 right-2 lg:relative lg:top-0 lg:right-0"
                          } `}
                      >
                        {edit ? (
                          <></>
                        ) : (
                          <>
                            {(userId && userId.role.id === 1) ||
                              (userId && userId.role.name === "Prüfer") ? (
                              <>
                                <BsPencil
                                  onClick={() => this.changeMode()}
                                  size={isMobile ? 20 : 35}
                                  color="#004f9f"
                                  className="hover:cursor-pointer"
                                />
                              </>
                            ) : (
                              <>
                                {activityPermissions &&
                                  activityPermissions[0] ? (
                                  <>
                                    {(activityPermissions[0].includes(
                                      "editown"
                                    ) &&
                                      userId &&
                                      userId.role.id === 1) ||
                                      (userId &&
                                        userId.role.name === "Prüfer") ? (
                                      <BsPencil
                                        onClick={() => this.changeMode()}
                                        size={35}
                                        color="#004f9f"
                                        className="hover:cursor-pointer"
                                      />
                                    ) : (
                                      <>
                                        {activityPermissions[0].includes(
                                          "editother"
                                        ) ||
                                          activityPermissions[0].includes(
                                            "full"
                                          ) ? (
                                          <BsPencil
                                            onClick={() => this.changeMode()}
                                            size={35}
                                            color="#004f9f"
                                            className="hover:cursor-pointer"
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-between items-start pb-[20px]">
                <div className="flex flex-wrap items-center w-full lg:w-[47%] justify-between lg:justify-between">
                  <div className="flex flex-wrap lg:flex-wrap justify-between flex-1 gap-[50px] w-[100%]">
                    <div className="w-full lg:w-full flex items-center justify-between flex-auto lg:flex-auto gap-[50px]">
                      <div className="grid gap-[35px] flex-1 lg:w-[25%]">
                        <div
                          className={`flex flex-col lg:gap-[10px] pt-[20px] justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                            Ort
                          </h2>
                          {edit ? (
                            <input
                              type="text"
                              name="location"
                              onChange={this.handleInputChange}
                              value={modifiedData.location}
                              className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:outline-none block w-full px-[8px] py-1.5 searchinput"
                              placeholder="Ort"
                            />
                          ) : (
                            <p className="text-[12px] lg:text-[16px] text-gray-500">
                              {modifiedData.location
                                ? modifiedData.location
                                : "-"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-[35px] pl-[2%] lg:pl-0 flex-1 lg:w-[30%] relative">
                        <div
                          className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                            Art
                          </h2>
                          {edit ? (
                            <Select
                              className="w-full"
                              classNamePrefix="transparent-bg"
                              isSearchable={true}
                              getOptionLabel={(e) => `${e.label}`}
                              getOptionValue={(e) => e.value}
                              name="type"
                              options={type}
                              onChange={this.handleTypeChange}
                              placeholder={
                                this.state.modifiedData.type
                                  ? this.state.modifiedData.type
                                  : "Auswählen..."
                              }
                            />
                          ) : (
                            <p className="text-[12px] lg:text-[16px] text-gray-500">
                              {modifiedData.type ? modifiedData.type : "-"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-full flex-wrap flex items-center justify-between flex-auto lg:flex-auto gap-[50px]">
                      <div className="flex flex-col gap-[35px] lg:pl-0 flex-1 lg:w-[30%] relative">
                        <div
                          className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                            Folgeaktivität
                          </h2>
                          {edit ? (
                            <Select
                              isSearchable={true}
                              classNamePrefix="transparent-bg"
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e.id}
                              name="nextActivity"
                              options={loadtypes}
                              onChange={this.handleNextActivity}
                              className="w-full"
                              placeholder={
                                this.state.modifiedData.nextActivity
                                  ? this.state.modifiedData.nextActivity.name
                                  : "Auswählen..."
                              }
                            />
                          ) : (
                            <p className="text-[12px] lg:text-[16px] text-gray-500">
                              {modifiedData.nextActivity
                                ? modifiedData.nextActivity.name
                                : "-"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col gap-[35px] pl-[2%] lg:pl-0 flex-1 lg:w-[30%] relative">
                        <div
                          className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7]"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                            Fälligkeit Folgeaktivität
                          </h2>
                          {edit ? (
                            <>
                              <Datetime
                                inputProps={inputPropsdue}
                                input={true}
                                dateFormat="YYYY-MM-DD"
                                timeFormat="HH:mm"
                                onChange={(e) =>
                                (modifiedData.dueDateActivity = Moment(
                                  e._d
                                ).format("YYYY-MM-DD HH:mm"))
                                }
                                className="placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:outline-none block w-full px-[8px] py-1.5 input-with-image"
                              />
                              {this.state.modifiedData.dueDateActivity <
                                this.state.modifiedData.meetingTime ? (
                                <p className="text-[#aa1124] text-[12px] max-w-[250px] break-all">
                                  Sie können nicht einstellen
                                  Folgeaktivitäts-Datum vor dem Aktivitätsdatum
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <p className="text-[12px] lg:text-[16px] text-gray-500">
                              {modifiedData.dueDateActivity
                                ? Moment(modifiedData.dueDateActivity).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                                : "-"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={`flex ${edit ? "flex-col lg:flex-row" : "flex-row"} items-start gap-[50px] pl-0 flex-auto w-full relative`}>
                        <div
                          className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start ${edit ? "flex-1 min-w-[217px]" : "border-b border-[#d7d7d7] w-full"
                            }`}
                        >
                          <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                            Assigned To
                          </h2>
                          {edit ? (
                            <>
                              <Select
                                isSearchable={true}
                                isClearable
                                classNamePrefix="transparent-bg"
                                getOptionLabel={(e) =>
                                  `${e.label ? e.label : ""} ${e.firstName ? e.firstName : ""
                                  } ${e.lastName ? e.lastName : ""} (${e.email ? e.email : ""
                                  })`
                                }
                                getOptionValue={(e) => e.id}
                                name="activity"
                                options={filteredUsers}
                                onChange={this.handleAssignedTo}
                                defaultValue={modifiedData.nextUser}
                                className={`w-full border-0`}
                                placeholder="Auswählen..."
                              />
                            </>
                          ) : (
                            <p className="text-[12px] lg:text-[16px] text-gray-500">
                              {modifiedData.nextUser
                                ? modifiedData.nextUser.firstName +
                                " " +
                                modifiedData.nextUser.lastName
                                : "-"}
                            </p>
                          )}
                        </div>
                        {edit && (
                          <div
                            className={`flex flex-col lg:gap-[10px] pt-5 w-100 justify-between lg:justify-start ${edit ? "flex-1 max-w-[45%]" : "border-b border-[#d7d7d7]"
                              }`}
                          >
                            <h2 className="text-[#004f9f] text-[14px] font-[400]">
                              Kontakt
                            </h2>
                            {edit ? (
                              <>
                                <AsyncSelect
                                  classNamePrefix="transparent-bg"
                                  isSearchable={true}
                                  isClearable
                                  isMulti
                                  getOptionLabel={(e) =>
                                    `${e.firstname
                                      ? e.firstname
                                      : e.fields?.all.firstname
                                        ? e.fields?.all.firstname
                                        : ""
                                    } ${e.lastname
                                      ? e.lastname
                                      : e.fields?.all.lastname
                                        ? e.fields?.all.lastname
                                        : ""
                                    }${e.email
                                      ? " (" + e.email + ")"
                                      : e.fields?.all.email
                                        ? " (" + e.fields?.all?.email + ")"
                                        : ""
                                    }`
                                  }
                                  getOptionValue={(e) => e.id}
                                  name="nextActivity"
                                  defaultValue={modifiedData.leads}
                                  loadOptions={this.loadOptions}
                                  onChange={this.handleSelectChange}
                                  placeholder="Auswählen..."
                                  noOptionsMessage={(e) => "No contacts found, keep searching..."}
                                />
                                <div className="flex w-full justify-center desktop-none pt-[30px]">
                                  <EditModal
                                    reload={this.reload}
                                    submit={this.handleSubmit}
                                    loading={this.state.loading}
                                    errors={this.state.errors}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                              </>
                            )}
                          </div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`flex flex-col flex-wrap ${edit
                    ? "gap-[35px] lg:gap-[50px]"
                    : "gap-[35px] lg:gap-[50px]"
                    } w-full lg:w-[47%] mt-[30px] lg:mt-0`}
                >
                  <div
                    className={`flex flex-col lg:gap-[10px] pt-5 flex-1 justify-between lg:justify-start ${edit ? "w-full" : "border-b border-[#d7d7d7] pb-1"
                      }`}
                  >
                    <h2 className="text-[#004f9f] text-[12px] lg:text-[14px] font-[400] w-full">
                      Bemerkung
                    </h2>
                    {edit ? (
                      <textarea
                        rows="8"
                        cols="60"
                        name="note"
                        onChange={this.handleInputChange}
                        value={modifiedData.note}
                        className=" placeholder:text-gray-400 placeholder:text-[13px] shadow bg-transparent border-gray-400 text-gray-900 sm:text-[13px] rounded-[6px] focus:ring-blue-500 focus:border-blue-500 block w-full px-[8px] py-1.5 searchinput"
                        placeholder="Bemerkung"
                      />
                    ) : (
                      <p className="text-[12px] lg:text-[16px] text-gray-400 w-full">
                        {modifiedData.note ? modifiedData.note : "-"}
                      </p>
                    )}
                  </div>

                  <div className="flex justify-start gap-[50px] w-full ">
                    <div className="w-full">
                      <h2 className="text-[#004f9f] text-[14px] font-[400] pb-[30px]">
                        Dokumente
                      </h2>
                      {edit ? (
                        <>
                          <Dropzone
                            maxSize={10000000}
                            multiple={true}
                            onDrop={this.handleOnDrop}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="flex flex-col">
                                <div
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input {...getInputProps()} />
                                  <p className="text-[14px]">
                                    Ziehen Sie Dateien hierher oder klicken Sie
                                    zum Auswählen
                                  </p>
                                  <p className="text-[12px] mt-[10px]">
                                    max 10MB
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          {modifiedData.documents &&
                            modifiedData.documents.length > 0 ? (
                            <>
                              {this.state.modifiedData.documents.map((x, i) => (
                                <div key={i} className="pb-[10px]">
                                  <p className="leading-6 flex items-center gap-[10px] ">
                                    {x.name}
                                    <button onClick={() => this.removeDoc(i)}>
                                      <FaTimes size={14} />
                                    </button>
                                  </p>
                                </div>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <div className="flex flex-wrap max-w-[440px] justify-start gap-[35px] fileicon items-start">
                          {this.state.modifiedData.documents.map((document) => (
                            <div
                              className="grid place-items-center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.downloadFile(document);
                              }}
                            >
                              <FileIcon
                                extension={document.extension}
                                {...defaultStyles[document.extension]}
                              />
                              <p className="text-[#004f9f] break-all text-[12px] max-w-[130px] text-center">
                                {document.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <>
              {!edit && (
                <div className="p-0 lg:p-[20px] w-full block mx-auto mt-[50px] lg:mt-[20px] px-[15px]">
                  <div className="flex flex-wrap lg:flex-nowrap justify-between items-center pb-[10px]">
                    <h3 className="text-[18px] lg:text-[24px] text-[#004F9F] font-normal pb-[20px] lg:pb-0 w-full lg:w-fit">
                      Entsprechende {contactTable ? "Kontakte" : "Unternehmen"}
                    </h3>
                    <div className="flex relative items-center mx-auto lg:mx-0 justify-center lg:justify-end gap-[5px] z-[100] pb-[30px] lg:scroll-pb-40">
                      <button
                        onClick={() => this.changeTable("contacts")}
                        to="#"
                        className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${contactTable ? "border-[#004f9f]" : "shadow"
                          }  px-3 py-2 `}
                      >
                        Kontakte
                      </button>
                      <button
                        onClick={() => this.changeTable("companies")}
                        to="#"
                        className={`text-[#004f9f] text-[12px] lg:text-[14px] bg-transparent border-2 ${companyTable ? "border-[#004f9f]" : "shadow"
                          }  px-3 py-2 `}
                      >
                        Unternehmen
                      </button>
                    </div>
                  </div>
                  {contactTable ?
                    <Contacts data={modifiedData.leads} loading={modifiedData.reference === null} id={id} />
                    :
                    companyTable ?
                      <Companies companies={modifiedData.companies} id={id} />
                      :
                      <></>}

                </div>
              )}</>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    types: state.types,
    users: state.users,
  };
};

export default connect(mapStateToProps, {
  retrieveStandardType,
  retrieveUsers,
})((props) => (
  <ActivityCard {...props} navigate={useNavigate()} params={useParams()} />
));

