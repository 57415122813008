class Segment {
  constructor(client) {
    this.client = client;
  }

  addContact(segmentId, contactId) {
    return this.client.post(`segments/${segmentId}/contact/${contactId}/new`);
  }

  addContacts(segmentId) {
    return this.client.post(`segments/${segmentId}/contacts/new`);
  }

  removeContact(segmentId, contactId) {
    return this.client.post(
      `segments/${segmentId}/contact/${contactId}/remove`
    );
  }
}
export default Segment;

